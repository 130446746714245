import React, { useState, useContext, useEffect } from 'react'
import { CourseTopicContext } from '../context/CourseTopicContext'
import { CourseContext } from '../context/CourseContext'
import {
  ITextFieldProps,
  ICourseTopicTask,
  ICourseTopicResponse,
  IAdminCourseTopicTaskRes,
  IAdminCourseTopicTaskReq,
  ICourseTopicTaskRes
} from '../../../../interface'
import { useFormValidation } from '../../../../dialogs/form-dialog/FormDialogValidation'
import apiService from '../../../../services/api-services'
import FormDialog from '../../../../dialogs/form-dialog/FormDialog'
import DeleteDialog from '../../../../dialogs/delete-dialog/delete-dialog'
import * as MUI from '../../../../MUI'
import { flatMap } from 'rxjs'

interface CourseTopicTaskData extends ICourseTopicTask {
  [key: string]: any
}

interface AdminCourseProps {
  // method: string
  method: 'add task' | 'edit task' | 'delete task'
  data?: CourseTopicTaskData
  children?: React.ReactChild,
  taskIsOpen: boolean,
  setTaskIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface CourseTopicTaskFormData
  extends Pick<ICourseTopicTask, 'task_code'|'estimate_hours' | 'title' | 'description' | 'task_type'> {
  [key: string]: any
}

const AdminCourseTopicTask = (props: AdminCourseProps) => {
  const { method, data, children } = props
  const { error, setError, formIsEmpty } = useFormValidation()

  const initFormValue: CourseTopicTaskFormData = {
    estimate_hours: 0,
    task_code:0,
    title: '',
    description: '',
    task_type: 'bug fix'
  }

  const [courseTopicTaskFormData, setCourseTopicTaskFormData] =
    useState<CourseTopicTaskFormData>(initFormValue)
  const [taskIsOpen, setTaskIsOpen] = useState(false)
  const { apiTopic, topicId, tasks, setApiTopic, setIsTopicLoading, setTasks } =
    useContext(CourseTopicContext)
  const { setNotify } = useContext(CourseContext)

  useEffect(() => {
    if (method === 'edit task' && !!data) {
      for (let k in data) {
        if (courseTopicTaskFormData.hasOwnProperty(k)) {
          courseTopicTaskFormData[k] = data[k]
        }
      }
    }
  }, [taskIsOpen])

  const handleTaskOpen = () => {
    // e.stopPropagation()
    console.log('handleOpen' + method)
    switch (method) {
      case 'add task':
        setTaskIsOpen(true)
        break
      case 'edit task':
        setTaskIsOpen(true)
        break
      case 'delete task':
        setTaskIsOpen(true)
        break
      default:
        throw Error('Invalid method')
    }
    // setTaskIsOpen(true)
  }

  const handleTaskClose = () => {
    console.log('handleClose' + method)
    method === 'add task' && setCourseTopicTaskFormData(initFormValue)
    setTaskIsOpen(false)
    setError({})
  }

  const handleTaskSubmit = () => {
    const { estimate_hours } = courseTopicTaskFormData
    if (!formIsEmpty(courseTopicTaskFormData) && estimate_hours! > 0) {
      if (method === 'add task' && data) {
        setIsTopicLoading(true)
        apiService
          .addAdminCourseTopicTask(data.id, courseTopicTaskFormData)
          .then((res) => {
            if (res) {
              setNotify({
                isOpen: true,
                message: 'Add task successfully',
                type: 'success'
              })
              apiTopic?.forEach((item: ICourseTopicResponse) => {
                if (item.id === data.id) {
                  item.course_topic_tasks.push(res)
                }
              })
              setApiTopic(apiTopic)
              setIsTopicLoading(false)
            }
          })
          .catch((err) => {
            if (err) {
              setNotify({
                isOpen: true,
                message: 'Fail to add task',
                type: 'error'
              })
              setIsTopicLoading(false)
            }
          })

        //wait api add course_topic to renew page
      }
      if (method === 'edit task' && data) {
        setIsTopicLoading(true)
        apiService
          .editAdminCourseTopicTask(data.id, courseTopicTaskFormData)
          .then((res) => {
            if (res) {
              setNotify({
                isOpen: true,
                message: 'Task update successfully',
                type: 'success'
              })
            }
            if (apiTopic?.length) {
              if (tasks?.length) {
                const newData = tasks.map((item: ICourseTopicTaskRes) => {
                  if (item.id === data.id) {
                    return {
                      ...item,
                      task_code:courseTopicTaskFormData.task_code,
                      title: courseTopicTaskFormData.title,
                      description: courseTopicTaskFormData.description,
                      task_type: courseTopicTaskFormData.task_type,
                      estimate_hours: courseTopicTaskFormData.estimate_hours
                    }
                  }
                  return item
                })
                setTasks(newData)
              }
            }
            setIsTopicLoading(false)
          })
          .catch((err) => {
            if (err) {
              setNotify({
                isOpen: true,
                message: 'Fail to update task',
                type: 'error'
              })
              setIsTopicLoading(false)
            }
          })
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'Not valid input',
        type: 'error'
      })
    }
  }

  const handleDeleteTask = () => {
    setIsTopicLoading(true)
    try {
      apiService
        .deleteAdminCourseTopicTask(topicId, data!.id)
        .then((res) => {
          if (res) {
            if (apiTopic?.length) {
              const filteredApiTopicTask = apiTopic[0].course_topic_tasks.filter((task) => {
                if (task.id !== data!.id) {
                  return true
                }
                return false
              })

              const newApiTopic = apiTopic.map((topic) => {
                if (topic.id === topicId) {
                  topic.course_topic_tasks = filteredApiTopicTask
                }
                return topic
              })
              setApiTopic(newApiTopic)
              setIsTopicLoading(false)
              setNotify({
                isOpen: true,
                message: `${data?.title} deleted successfully`,
                type: 'success'
              })
            }
          }
        })
        .catch((err) => {
          setNotify({
            isOpen: true,
            message: `${data?.title} failed to delete`,
            type: 'error'
          })
        })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Dialog content
   */
  const childrenInput: ITextFieldProps[] = [
    {
      autoFocus: true,
      margin: 'dense',
      id: 'title',
      label: 'title',
      type: 'title',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: string) => {
        courseTopicTaskFormData.title = e
        !courseTopicTaskFormData.title.length
          ? setError({ ...error, title: true })
          : setError({ ...error, title: false })
      },
      className: '',
      value: courseTopicTaskFormData.title,
      required: true,
      error: error.title
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'task_code',
      label: 'Task Code',
      type: 'number',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: number) => {
        courseTopicTaskFormData.task_code = e
        courseTopicTaskFormData.task_code.valueOf() <= 0
          ? setError({ ...error, task_code: true })
          : setError({ ...error, task_code: false })
      },
      className: '',
      value: courseTopicTaskFormData.task_code,
      required: true,
      error: error.task_code
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'description',
      label: 'description',
      type: 'description',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: string) => {
        courseTopicTaskFormData.description = e
        courseTopicTaskFormData.description.length === 0
          ? setError({ ...error, describe: true })
          : setError({ ...error, describe: false })
      },
      className: '',
      value: courseTopicTaskFormData.description,
      required: false,
      error: error.describe
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'estimate_hours',
      label: 'Estimate Hours',
      type: 'number',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: number) => {
        courseTopicTaskFormData.estimate_hours = e
        courseTopicTaskFormData.estimate_hours.valueOf() <= 0
          ? setError({ ...error, estimate_hours: true })
          : setError({ ...error, estimate_hours: false })
      },
      className: '',
      value: courseTopicTaskFormData.estimate_hours,
      required: false,
      error: error.estimate_hours
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'task_type',
      label: 'Task Type',
      type: 'task_type',
      fullWidth: true,
      select: true,
      variant: 'outlined',
      onChange: (e: 'bug fix' | 'new feature' | 'analysis' | 'testing' | 'refactor' | 'normal' | 'milestone') =>
        (courseTopicTaskFormData.task_type = e),
      className: '',
      value: courseTopicTaskFormData.task_type ? courseTopicTaskFormData.task_type : '',
      // value: "bug fix",
      required: false
    }
  ]

  if (method === 'add task' || method === 'edit task') {
    return (
      <div style={{width:'100%'}}>
        <MUI.IconButton
        sx={{ width: '100%', justifyContent:'flex-start', borderRadius: 0 }} 
        onClick={() => handleTaskOpen()} 
        disableRipple
        >
          {method === 'add task' && <MUI.AddBoxIcon fontSize='small' />}
          {method === 'edit task' && <MUI.EditIcon fontSize='small' />}
          <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
        </MUI.IconButton>

        <FormDialog
          isOpen={taskIsOpen}
          handleClose={handleTaskClose}
          children={childrenInput}
          handleSubmit={handleTaskSubmit}
          method={method}
        />
      </div>
    )
  } else if (method === 'delete task') {
    return (
      <>
        <MUI.IconButton
          color='default'
          sx={{ width: '100%', justifyContent:'flex-start', borderRadius: 0 }}
          onClick={() => handleTaskOpen()}
          disableRipple
        >
          <MUI.DeleteIcon fontSize='small' />
          <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
        </MUI.IconButton>

        <DeleteDialog
          open={taskIsOpen}
          closeDialog={handleTaskClose}
          deleteProperty={data?.title}
          handleDelete={handleDeleteTask}
        />
      </>
    )
  } else {
    return <></>
  }
}

export default AdminCourseTopicTask
