import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import apiService from '../../services/api-services'
import ResourcesControls from './ResourcesControls'
import ResourcesList from './ResourcesList'
import ResourceDialog from '../../dialogs/resource-dialog/ResourceDialog'
import { ResourcesResponse, Tag } from '../../interface'

const Resources = () => {
  const [data, setData] = useState<ResourcesResponse[]>([])
  const [tagList, setTagList] = useState<Tag[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isCardEdit, setCardEdit] = useState(false)
  const [dataEdit, setDataEdit] = useState<any>(null)
  const [isNewCard, setIsNewCard] = useState<boolean>(false)
  const [isNewToggle, setIsNewToggle] = useState<boolean>(false)

  useEffect(() => {
    apiService.getConfig().then((res) => setTagList(res.tags))
  }, [])

  useEffect(() => {
    apiService.getAllResources().then((res) => {
      setData(res)
    })
  }, [])

  const toggleDialog = () => setDialogOpen(!dialogOpen)
  const toggleNew = () => setIsNewToggle(!isNewToggle)

  return (
    <>
      <ResourcesControls
        data={data}
        setData={setData}
        tagList={tagList}
        setSearchParams={setSearchParams}
        setDataEdit={setDataEdit}
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        setIsNewCard={setIsNewCard}
        toggleNew={toggleNew}
        setCardEdit={setCardEdit}
        isCardEdit={isCardEdit}
      />

      <ResourcesList
        data={data}
        setData={setData}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isCardEdit={isCardEdit}
        toggleDialog={toggleDialog}
        setDataEdit={setDataEdit}
      />

      <ResourceDialog
        dialogOpen={dialogOpen}
        toggleDialog={toggleDialog}
        tagList={tagList}
        setData={setData}
        dataEdit={dataEdit}
        setDataEdit={setDataEdit}
        setDialogOpen={setDialogOpen}
        isNewCard={isNewCard}
        toggleNew={toggleNew}
        setIsNewCard={setIsNewCard}
      />
    </>
  )
}

export default Resources
