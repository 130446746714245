import { useState } from 'react'
import SearchByTags from './SearchByTags'
import * as MUI from '../../MUI'
import IconButton from '../../components/button/IconButton'
import ResourceOrderFilter from './ResourceOrderFilter'
import { Grid } from '@material-ui/core'

type filterProps = {
  data?: any
  setData?: any
  tagList: any
  setSearchParams: Function
  needSort?: boolean
}

const Filter = ({ data, setData, tagList, setSearchParams, needSort }: filterProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [orderBy, setOrderBy] = useState<string>('created_at')
  const [order, setOrder] = useState<string>('asc')

  const sortData = (orderBy: string, order: string) => {
    let sortedData = [...data.sort((a: any, b: any) => a[orderBy].localeCompare(b[orderBy]))]
    order !== 'asc' && sortedData.reverse()
    setData([...sortedData])
  }

  const clearFilter = () => {
    setSearchParams()
    setSelectedTags([])
    setOrderBy('created_at')
    setOrder('asc')
    sortData('created_at', 'asc')
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <SearchByTags
            tagList={tagList}
            setSearchParams={setSearchParams}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </Grid>
        <Grid item xs={2} container direction='row' justifyContent='center' alignItems='center'>
          {needSort && (
            <ResourceOrderFilter
              data={data}
              setData={setData}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              order={order}
              setOrder={setOrder}
              sortData={sortData}
            />
          )}
        </Grid>
        <Grid item xs={2} container direction='row' justifyContent='flex-start' alignItems='center'>
          <IconButton onClick={clearFilter} icon={<MUI.RestartAltIcon />} color='inherit' />
        </Grid>
      </Grid>
    </>
  )
}

export default Filter
