import * as MUI from '../../MUI'

const NewSpinner = () => {
  return (
    <div>
      <MUI.CircularProgress />
    </div>
  )
}

export default NewSpinner