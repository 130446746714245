import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { CourseContext } from './course-components/context/CourseContext'
import { DashboardContext } from '../dashboard-page/dashboard-components/context/DashboardContext'
import { AppContext } from '../../context/AppContext'
import { collapseObservable } from '../../observables/collapseAll'
import { ICourseResponse, User, Notify, IAssignCourses, IAssignCoursesRes, IRemoveLearnerCourse, ICourseTopic } from '../../interface'
import apiService from '../../services/api-services'
import Course from './course-components/course-list/Course'
import AdminCourse from './course-components/components/AdminCourse'
import UserRoleAuth from '../../components/userrole-authentication/UserRoleAuth'
import Notification from '../../components/notification/notification'
import CommonSkeleton from '../../components/skeleton/CommonSkeleton'
import BackTop from '../../components/back-to-top/BackTop'
import { delayToDo } from './course-components/components/Delayed'
import * as MUI from '../../MUI'
import './Courses.css'
import {getStorage, userKey} from '../../constants/auth'
import Delayed from './course-components/components/Delayed'
import useSearchService from "../../services/useSearchService"
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import IconButton from '../../components/button/IconButton'
import Tooltip from '@mui/material/Tooltip';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import PermMedia from '@mui/icons-material/PermMedia';
import Dns from '@mui/icons-material/Dns';
import Public from '@mui/icons-material/Public';
import SearchResultWindow from '../../components/search-bar/SearchResultWindow'
import SearchIcon from '@mui/icons-material/Search';
import SearchBar from '../../components/search-bar/SearchBar'
import RealtimeNotification from '../../components/realtime-notification/realtime-notification'

// type LearnerId = Pick<User, 'id'>

type CoursesPageProps = {
  learnerId?: number
  learner?: User
  DashBoardTabValue:number
  // learnerId?: LearnerId
  // setSearchParams?: Function
  // onClickBtn?: () => void //need delete
}

interface initCoursesProps extends ICourseResponse {
  checked: boolean
}

export interface CourseAndTopicID {
  courseId: number 
  topicId: number 
}

export interface learnerCoursesStatusProps {
  course_id: number
  course_completion: boolean
}

let TaskTabValueTable: { taskID: any; value: any }[] = [];
export let TaskTabValue: any;

const CoursesPage = (props: CoursesPageProps) => {
  const { learnerId, learner, DashBoardTabValue} = props
  const [apiCoursesData, setApiCoursesData] = useState<ICourseResponse[]>([])
  const [apiLearnerCourses, setApiLearnerCourses] = useState<ICourseResponse[]>([])
  const [learnerCoursesArray, setLearnerCoursesArray] = useState<ICourseResponse[]>([])
  const [filteredLearnerCourses, setFilteredLearnerCourses] = useState<ICourseResponse[]>([])
  const [initCourses, setInitCourses] = useState<initCoursesProps[]>([])
  const [assignCoursesRawData, setAssignCoursesRawData] = useState<IAssignCourses>({
    userId: 0,
    courseIds: []
  })
  const [removeLearnerCourse, setRemoveLearnerCourse] = useState<IRemoveLearnerCourse>()
  const [isCourseLoading, setIsCourseLoading] = useState(false)
  const [displayCoursesState, setDisplayCoursesState] = useState(false)
  const [displayTopicsState, setDisplayTopicsState] = useState(false)
  const [openState, setOpenState] = useState(false)
  const [notify, setNotify] = useState<Notify>({
    isOpen: false,
    message: '',
    type: 'success'
  })

  const [submitCourseTopicId, setSubmitCourseTopicId] = useState<CourseAndTopicID>({
    courseId: 0,
    topicId: 0
  })
  const [searchCourseTopicId, setSearchCourseTopicId] = useState<CourseAndTopicID>({
    courseId: 0,
    topicId: 0
  })

  const [learnerCoursesStatus, setLearnerCoursesStatus] = useState<Array<learnerCoursesStatusProps>>([])

  const [searchOpen, setSearchOpen] = useState(false)
  const handleSearchDrawerToggle = () => {
    setSearchOpen((prevState) => !prevState)
  }

  const { search, pathname } = useLocation()
  const query = new URLSearchParams(search)
  const CourseId = Number (query.get('CourseId'))
  const { assignCourseState, submit, setSubmit } = useContext(DashboardContext)
  const { userRole } = useContext(AppContext)
  
  const value = {
    learnerId,
    apiCoursesData,
    setApiCoursesData,
    setIsCourseLoading,
    notify,
    setNotify,
    displayTopicsState,
    pathname,
    submitCourseTopicId,
    setSubmitCourseTopicId,
    searchCourseTopicId,
    setSearchCourseTopicId
  }
  const collapseState = collapseObservable()
  const displayAllCourses = true
  let dataLength = apiCoursesData.length - 1

  const toggleDisplayCourses = () => setDisplayCoursesState(!displayCoursesState)
  const toggleDisplayTopics = () => setDisplayTopicsState(!displayTopicsState)
  const collapseAll = () => collapseState.collapseAll(true)

  const fetchApiAllCourses = () => {
    if(userRole() === 'Others') {
        apiService.getLearnerAllCourses().then((res) => {
          setApiCoursesData(res)
          setIsCourseLoading(false)
        })
      } else {
        apiService.getAllCourses().then((res) => {
          setApiCoursesData(res)
          setIsCourseLoading(false)
        })
      }
  }

  const fetchApiLearnerCourses = (id: number) => {
    apiService.getLearnerCourses(id).then((res) => {
      setApiCoursesData(res)
      setApiLearnerCourses(res)
      setIsCourseLoading(false)
    })
  }

  useEffect(() => {
    if(apiLearnerCourses.length) {
      setLearnerCoursesStatus(
        apiLearnerCourses.map(value => {
          return {
            course_id: value.id,
            course_completion: false
          }
        })
      )
    } else if(apiCoursesData.length) {
      setLearnerCoursesStatus(
        apiCoursesData.map((value) => {
          return {
            course_id: value.id,
            course_completion: false
          }
        })
      )
    }
  }, [apiLearnerCourses, apiCoursesData])

  useEffect(() => {
    if(assignCourseState) {
      setIsCourseLoading(true)
      fetchApiAllCourses()
    }
  }, [assignCourseState])

  useEffect(() => {
    setIsCourseLoading(true)
    if (learnerId !== undefined) {
      fetchApiLearnerCourses(learnerId)
      setAssignCoursesRawData({
        ...assignCoursesRawData,
        userId: learnerId
      })
    } else {
      fetchApiAllCourses()
    }
  }, [learnerId])

  useEffect(() => {
    setLearnerCoursesArray([])
    const newCourses = apiCoursesData.map((element) => {
      return { 
        ...element, 
        checked: !!apiLearnerCourses.find((course) => course.id === element.id) 
      }
    })
    setInitCourses(newCourses)
  }, [apiCoursesData])

  useEffect(() => {
    if(submit){
      setIsCourseLoading(true)
      const newLearnerCoursesArray = [...apiLearnerCourses, ...learnerCoursesArray]
      newLearnerCoursesArray.sort((a, b) => a.id - b.id).map((course) => {
      // apiLearnerCourses.map((course) => {
        if (!assignCoursesRawData.courseIds.includes(course.id)) {
          assignCoursesRawData.courseIds.push(course.id)
        }
      })
      if (learnerCoursesArray.length > 0) {
        apiService.assignLearnerCourses(assignCoursesRawData).then(res => {
          console.log('res', res)
          if(res) {
            setApiLearnerCourses(newLearnerCoursesArray)
            setLearnerCoursesArray([])
            // setAssignCoursesResArray(res)
            setNotify({
              isOpen: true,
              message: `Success to assign courses to ${learner?.email}`,
              type: 'success',
            })
            setIsCourseLoading(false)
          }
        }).catch(err => {
          console.log('Fail to assign course', err)
          setNotify({
            isOpen: true,
            message: `Fail to assign course for ${learner?.email}`,
            type: 'error',
          })
        })
      } else {
        setTimeout(() => {
          setIsCourseLoading(false)
        }, 400)
      }
    }
    setSubmit(false)
  }, [submit])
  // console.log('learnerCoursesArray', learnerCoursesArray)
  // console.log('apiLearnerCourses', apiLearnerCourses)
  // console.log('assignCoursesRawData', assignCoursesRawData)
  
  useEffect(() => {
    if(removeLearnerCourse !== undefined){
      apiService.removeLearnerCourse(removeLearnerCourse).then((res) => {
        if(res){
          console.log('Course removed', Boolean(res))
          setApiLearnerCourses(filteredLearnerCourses)
          setNotify({
            isOpen: true,
            message: `Success to remove course Id - ${removeLearnerCourse.courseId}`,
            type: 'success',
          })
        } 
      }).catch(err => {
        console.log('Fail to remove course', err)
        setNotify({
          isOpen: true,
          message: `Fail to remove course Id - ${removeLearnerCourse.courseId}`,
          type: 'error',
        })
      })
    }
  }, [removeLearnerCourse])

  const handleSetInitCourses = (id: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newInitCourses = initCourses.map(element => {
      if (element.id === id) 
        return {
          ...element,
          checked: e.target.checked
        }
        return element
    })
    setInitCourses(newInitCourses)
  }

  const removeCourseFromList = (id: number, courses: ICourseResponse[], e:React.ChangeEvent<HTMLInputElement>) => {
    const filteredArray = courses.filter(course => course.id !== id)
    // setApiLearnerCourses(filteredArray)
    setFilteredLearnerCourses(filteredArray)
    if (learnerId !== undefined) {
      setRemoveLearnerCourse({
        userId: learnerId,
        courseId: id
      })
    }

    const newArray = assignCoursesRawData.courseIds.filter(courseId => courseId !== id )
    setAssignCoursesRawData({...assignCoursesRawData, courseIds: newArray})

    handleSetInitCourses(id, e)
  }

  const handleCheckboxChange = (data: ICourseResponse, id: number, e: React.ChangeEvent<HTMLInputElement>) => {  
    if (apiLearnerCourses.find((e) => e.id === data.id)) {
      removeCourseFromList(id, apiLearnerCourses, e)
    } else {
      if(e.target.checked === true) {
        learnerCoursesArray.push(data)
      } else {
       const result = learnerCoursesArray.find(course => course.id === data.id)
       if(result !== undefined) {
        const index = learnerCoursesArray.indexOf(result)
        if (index !== -1) {
          learnerCoursesArray.splice(index, 1)
        }
       }
      }
      // apiLearnerCourses.push(data)
      // apiLearnerCourses.sort((a, b) => a.id - b.id)
      const newArray = learnerCoursesArray.map((course) => {
        const newCourse: Partial<Pick<initCoursesProps, 'checked'>> &
          Omit<initCoursesProps, 'checked'> = course
        delete newCourse.checked
        return newCourse
      })
      setLearnerCoursesArray(newArray)

      handleSetInitCourses(data.id, e)
    }
  }

    // search function
    const [searchBarParams, setSearchBarParams] = useState(useSearchService.initialParams.param);
    useEffect(() => {
      useSearchService.subscribe(setSearchBarParams);
      useSearchService.clearParams();
    }, []);

    const [courseResult,setCourseResult] = useState<ICourseResponse[]>([]);
    useEffect(() => {
      setCourseResult(apiCoursesData.filter(course => {
         if (course.title && searchBarParams && course.title.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return course;
        } else if (course.description && searchBarParams && course.description.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return course;
        } else if (course.comments && searchBarParams && course.comments.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return course;
        }
      }))
    }, [searchBarParams, apiCoursesData])

    const [courseTopics, setCourseTopics] = useState<any>([])
    useEffect(() => {
      let courseTopic = [];
      for (let item of apiCoursesData) {
        courseTopic.push(item.course_topic);
      }
      setCourseTopics(courseTopic.flat())
    }, [apiCoursesData])

    const [topicResult,setTopicResult] = useState<any>([]);
    useEffect(() => {
      setTopicResult(courseTopics.filter((topic: { title: string; description: string}) => {
          if (topic.title && searchBarParams && topic.title.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return topic;
        } else if (topic.description && searchBarParams && topic.description.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return topic;
        }
      }))
    }, [searchBarParams,courseTopics])

    const [courseTopicsTasks, setCourseTopicsTasks] = useState<any>([])
    useEffect(() => {
      let courseTopicTask = [];
      for (let item of courseTopics) {
        courseTopicTask.push(item.course_topic_tasks);
      }
      setCourseTopicsTasks(courseTopicTask.flat())
      // console.log(courseTopicTask.flat())
    }, [courseTopics])

    const [taskResult,setTaskResult] = useState<any>([]);
    useEffect(() => {
      setTaskResult(courseTopicsTasks.filter((task: { title: string; description: string}) => {
          if (task.title && searchBarParams && task.title.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return task;
        } else if (task.description && searchBarParams && task.description.toLowerCase().includes(searchBarParams.toLowerCase())) {
          return task;
        }
      }))
    }, [searchBarParams,courseTopicsTasks])

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        const query = new URLSearchParams(search)
        const topicId = query.get('topicId')
        const taskId = query.get('taskId')
        if(topicId != null && taskId != null){
          searchTask(parseInt(taskId),parseInt(topicId))
        }
      }, 1000)
    
      return () => clearTimeout(timeoutId)
    }, [courseTopics])
    
    const searchTask = (taskID:number,topicID:number) => {
      const courseID = courseTopics.find((topic: { id: number }) => topic.id === topicID).course_id
      setSearchCourseTopicId({
        courseId: courseID,
        topicId: topicID
      })
      courseTopics.filter((topic: { id: any }) => {
        if (topic.id == topicID) {
          return topic
        }
      })[0].course_topic_tasks?.sort((a: { task_code: number },b: { task_code: number })=>a.task_code-b.task_code).map((task: { id: any }, index: any) => {
        TaskTabValueTable.push({taskID:task.id,value:index})
      })
      TaskTabValue = TaskTabValueTable.find(task => task.taskID === taskID)?.value;
      console.log(TaskTabValue)
    }

    const limitwords = (word:string,searchValue:string) => {
      let index = word.indexOf(searchValue)
      let leftEllipsis
      let rightEllipsis
      if (index > 0){
        leftEllipsis = '... '
      } else {
        leftEllipsis = ''
      }
      if (index + 100 < word.length){
        rightEllipsis = ' ...'
      } else {
        rightEllipsis = ''
      }
      return leftEllipsis+ word.substring(index, index + 100) + rightEllipsis
    }

    const windowPositionHorizontal = () => {
      if (userRole() === 'Admin User' && DashBoardTabValue == 3 || userRole() === 'Normal User' && DashBoardTabValue == 3) {
        return '0'
      } else if (userRole() === 'Admin User' && DashBoardTabValue == 1 || userRole() === 'Normal User' && DashBoardTabValue == 1) {
        return '-55px'
      } else {
        return '0'
      }
    }

  return (
    <>
      <div
        id='Course-Page'
        style={pathname.includes('Dashboard') ? { marginTop: '1rem' } : { marginTop: '2rem' }}
      >
        <CourseContext.Provider value={value}>
          <div className='c-page-header-button-wrapper'>
            {/* {pathname === '/Dashboard' && !hasQueryLearnerId() ? null : ( */}
            {sessionStorage.getItem('Tab') === 'Courses' && (
              <UserRoleAuth pathName={pathname} element='button'>
                <MUI.FormGroup
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    order: 1,
                    alignSelf: 'flex-end',
                    marginRight: 'auto'
                  }}
                >
                  <MUI.FormControlLabel
                    sx={{
                      '&.MuiFormControlLabel-root': {
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginRight: 2
                      }
                    }}
                    control={<MUI.Switch onChange={toggleDisplayCourses} color='warning' />}
                    label={<MUI.Typography sx={{ fontSize: 10 }}>Show all courses</MUI.Typography>}
                    labelPlacement='end'
                  />
                  <MUI.FormControlLabel
                    sx={{
                      '&.MuiFormControlLabel-root': {
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                      }
                    }}
                    control={<MUI.Switch onChange={toggleDisplayTopics} color='warning' />}
                    label={<MUI.Typography sx={{ fontSize: 10 }}>Show all topics</MUI.Typography>}
                    labelPlacement='end'
                  />
                </MUI.FormGroup>
              </UserRoleAuth>
            )}
            {/* )} */}

            <div style={{ order: 3, display: 'flex', flexWrap: 'nowrap' }}>
              <MUI.Fab
                disabled={assignCourseState}
                sx={
                  pathname.includes('Dashboard') && apiLearnerCourses.length === 0
                    ? { display: 'none' }
                    : pathname.includes('Courses') && apiCoursesData.length === 0
                    ? { display: 'none' }
                    : pathname.includes('Dashboard')
                    ? { display: 'block', bottom: { xs: '80px', md: '25px' } }
                    : { display: 'block' }
                }
                onClick={collapseAll}
                size='small'
                className='collapse-all'
                style={{
                  transition: 'all .2s',
                  display: openState ? 'flex' : 'none',
                  right: '5px'
                }}
              >
                <MUI.UnfoldLessIcon />
              </MUI.Fab>

              <Box
                sx={{ display: { xs: 'none', sm: 'block' } }}
                style={{ position: 'relative', top: windowPositionHorizontal(), right: 0 }}
              >
                <SearchBar />
                {/* Search Result */}
                {searchBarParams ? (
                  <SearchResultWindow
                    collapseAll={collapseAll}
                    setSearchCourseTopicId={setSearchCourseTopicId}
                    courseResult={courseResult}
                    topicResult={topicResult}
                    taskResult={taskResult}
                    searchBarParams={searchBarParams}
                    searchTask={searchTask}
                    DashBoardTabValue={DashBoardTabValue}
                  />
                ) : (
                  ''
                )}
              </Box>

              <Box
                sx={{ display: { xs: 'block', sm: 'none' } }}
                style={{ position: 'relative', top: windowPositionHorizontal(), right: 0 }}
              >
                <IconButton
                  color='inherit'
                  onClick={handleSearchDrawerToggle}
                  icon={<SearchIcon fontSize='medium' />}
                />
              </Box>

              {sessionStorage.getItem('Tab') === 'Courses' && (
                <UserRoleAuth pathName={pathname} element='button'>
                  <AdminCourse method='add course' />
                </UserRoleAuth>
              )}
            </div>
          </div>

          <MUI.Collapse
            in={searchOpen}
            timeout='auto'
            unmountOnExit
            sx={{ width: '100%', display: { xs: 'block', sm: 'none' } }}
          >
            <Box sx={{ width: '90%', ml: 3, mt: 2 }}>
              <SearchBar />
              {searchBarParams ? (
                <Box>
                  <ListItem
                    component='div'
                    sx={{ height: 20, textAlign: 'center', mt: 1, mb: 1 }}
                    disablePadding
                  >
                    <ListItemText
                      primary={
                        'We found ' +
                        (courseResult.length + topicResult.length + taskResult.length) +
                        ' results in the following categories'
                      }
                      primaryTypographyProps={{ fontSize: 12 }}
                    />
                  </ListItem>
                  <Divider />
                  {/* Course Search Result */}
                  <ListItemText
                    primary={courseResult[0] ? 'Course' : 'Course: No results'}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      lineHeight: '20px',
                      mt: '10px',
                      mb: '2px',
                      ml: '15px'
                    }}
                    sx={{ my: 0 }}
                  />
                  {courseResult.map((item, index) => (
                    <ListItemButton
                      key={index}
                      sx={{ py: 0, minHeight: 32 }}
                      onClick={() => {
                        collapseAll()
                        setSearchCourseTopicId({
                          courseId: item.id,
                          topicId: 0
                        })
                        handleSearchDrawerToggle()
                      }}
                    >
                      <ListItemText
                        primary={item.title}
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                        secondary={
                          item.description && item.comments
                            ? item.description?.indexOf(searchBarParams.toLowerCase()) != -1
                              ? limitwords(item.description!, searchBarParams.toLowerCase())
                              : item.comments?.indexOf(searchBarParams.toLowerCase()) != -1
                              ? limitwords(item.comments!, searchBarParams.toLowerCase())
                              : ''
                            : ''
                        }
                      />
                    </ListItemButton>
                  ))}

                  {/* Topic Search Result */}
                  <ListItemText
                    primary={topicResult[0] ? 'Topic' : 'Topic: No results'}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      lineHeight: '20px',
                      mt: '10px',
                      mb: '2px',
                      ml: '15px'
                    }}
                    sx={{ my: 0 }}
                  />
                  {topicResult.map(
                    (
                      topic: { title: string; description: string; id: number; course_id: number },
                      index: React.Key | null | undefined
                    ) => (
                      <ListItemButton
                        key={index}
                        sx={{ py: 0, minHeight: 32 }}
                        onClick={() => {
                          collapseAll()
                          setSearchCourseTopicId({
                            courseId: topic.course_id,
                            topicId: topic.id
                          })
                          handleSearchDrawerToggle()
                        }}
                      >
                        <ListItemText
                          primary={topic.title}
                          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                          secondary={
                            topic.description
                              ? topic.description?.indexOf(searchBarParams.toLowerCase()) != -1
                                ? limitwords(topic.description!, searchBarParams.toLowerCase())
                                : ''
                              : ''
                          }
                        />
                      </ListItemButton>
                    )
                  )}

                  {/* Task Search Result */}
                  <ListItemText
                    primary={taskResult[0] ? 'Task' : 'Task: No results'}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: 'medium',
                      lineHeight: '20px',
                      mt: '10px',
                      mb: '2px',
                      ml: '15px'
                    }}
                    sx={{ my: 0 }}
                  />
                  {taskResult.map(
                    (
                      task: { title: string; description: string; id: number; pivot: any },
                      index: React.Key | null | undefined
                    ) => (
                      <ListItemButton
                        key={index}
                        sx={{ py: 0, minHeight: 32 }}
                        onClick={() => {
                          collapseAll()
                          searchTask(task.id, task.pivot.course_topic_id)
                          handleSearchDrawerToggle()
                        }}
                      >
                        <ListItemText
                          primary={task.title}
                          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                          secondary={
                            task.description
                              ? task.description?.indexOf(searchBarParams.toLowerCase()) != -1
                                ? limitwords(task.description!, searchBarParams.toLowerCase())
                                : ''
                              : ''
                          }
                        />
                      </ListItemButton>
                    )
                  )}
                </Box>
              ) : (
                ''
              )}
            </Box>
          </MUI.Collapse>

          {/*Dispaly learner courses on Dashboard*/}
          {sessionStorage.getItem('Tab') === 'Progress' && (
            <UserRoleAuth pathName={pathname} element='admin dashboard'>
              {isCourseLoading ? (
                <div style={{ marginTop: '8px' }}>
                  <CommonSkeleton />
                </div>
              ) : !assignCourseState ? (
                apiLearnerCourses.length > 0 ? (
                  apiLearnerCourses
                    .filter((data) => data.is_active === 1)
                    .sort((a, b) => a.course_code - b.course_code)
                    .map((data, index) => (
                      <Course
                        key={index}
                        apiCoursesData={data}
                        index={index}
                        length={dataLength}
                        setOpenState={setOpenState}
                        learnerId={learnerId}
                        currentCourseId={CourseId}
                        learnerCoursesStatus={learnerCoursesStatus}
                        setLearnerCoursesStatus={setLearnerCoursesStatus}
                      />
                    ))
                ) : (
                  sessionStorage.getItem('Selected-Tab') !== 'Courses' && (
                    <Delayed>
                      <div style={{ marginTop: 30 }}>Please assign courses</div>
                    </Delayed>
                  )
                )
              ) : (
                // apiCoursesData
                initCourses
                  .filter((data) => data.is_active === 1)
                  .sort((a, b) => a.course_code - b.course_code)
                  .map((data, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'self-start' }}>
                      <MUI.Checkbox
                        sx={{ marginTop: 2 }}
                        checked={data.checked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleCheckboxChange(data, data.id, e)
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        <Course
                          key={index}
                          apiCoursesData={data}
                          index={index}
                          length={dataLength}
                          setOpenState={setOpenState}
                          currentCourseId={CourseId}
                          learnerCoursesStatus={learnerCoursesStatus}
                          setLearnerCoursesStatus={setLearnerCoursesStatus}
                        />
                      </div>
                    </div>
                  ))
              )}
            </UserRoleAuth>
          )}

          {DashBoardTabValue === 3 ? (
            <UserRoleAuth pathName={pathname} element='display courses'>
              {isCourseLoading ? (
                <div style={{ marginTop: '8px' }}>
                  <CommonSkeleton />
                </div>
              ) : dataLength > -1 ? (
                apiCoursesData
                  .sort((a, b) => a.course_code - b.course_code)
                  .map(
                    (data, index) =>
                      (!displayCoursesState ? !!data.is_active : displayAllCourses) && (
                        <Course
                          key={index}
                          apiCoursesData={data}
                          courseKey={data.id}
                          index={index}
                          length={dataLength}
                          setOpenState={setOpenState}
                          currentCourseId={CourseId}
                          learnerCoursesStatus={learnerCoursesStatus}
                          setLearnerCoursesStatus={setLearnerCoursesStatus}
                        />
                      )
                  )
              ) : (
                <div>No course to display</div>
                // <div>Please contact tutor to assign courses</div>
              )}
            </UserRoleAuth>
          ) : (
            ''
          )}

          <BackTop DashBoardTabValue={DashBoardTabValue} />
          <Notification notify={notify} setNotify={setNotify} />
        </CourseContext.Provider>
      </div>
    </>
  )
}
export default CoursesPage
