import React, { useState, useCallback, useEffect, useContext } from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import "./image-crop.css";
import * as MUI from "../../MUI";
import TextButton from "../button/TextButton";
import { AppContext } from "../../context/AppContext";
import IconButton from "../button/IconButton";
import { setStorage, userKey } from '../../constants/auth'
import apiService from "../../services/api-services"
import Notification from "../../components/notification/notification"

interface ImageCropProps {
  // Define any props that the component will take
  dialogOpen:boolean
  setDialogOpen:Function
  avatar:string
  setAvatar:Function
  adminUserId?:number
}

const ImageCrop: React.FC<ImageCropProps> = (props) => {
  const {dialogOpen, setDialogOpen, avatar, setAvatar, adminUserId} = props
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [editState,setEditState] = useState<boolean>(false);
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedArea | undefined>()
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })
  const [callSnackBar, setCallSnackBar] = useState(false)

  useEffect(() => {
    if (croppedAreaPixels) {
      handleImage()
    }
  }, [croppedAreaPixels, avatar])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  interface CroppedArea {
    x: number;
    y: number;
    width: number;
    height: number;
  }
  
  const handleImage = () => {
    const image = new Image();
    image.src = avatar!;
    image.crossOrigin = "anonymous"; 
    image.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels!.width;
      canvas.height = croppedAreaPixels!.height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height); // 使用白色填充整个canvas
        ctx.drawImage(
          image,
          croppedAreaPixels!.x,
          croppedAreaPixels!.y,
          croppedAreaPixels!.width,
          croppedAreaPixels!.height,
          0,
          0,
          croppedAreaPixels!.width,
          croppedAreaPixels!.height
        );
        canvas.toBlob((blob) => {
          setCroppedImage(blob); // 将生成的Blob对象保存到状态中
        }, "image/jpeg");
      }
    };
  };
  
  
  const handleSave = () => {
    let formData = new FormData()
    formData.append('upload_image', croppedImage!)
    if (adminUserId) {
      formData.append('_method', 'PUT');
      apiService.updateUser(adminUserId, formData).then(res => {
        if (res) {
          setNotify({
            isOpen: true,
            message: 'Success! Changes to the user have been saved.',
            type: 'success',
          })
        }
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }).catch(err => {
        setNotify({
          isOpen: true,
          message: 'Fail! Changes to the user have not been saved.',
          type: 'error',
        })
      }) 
    } else {
      apiService.updateProfile(formData).then((res) => {
        console.log(res)
        if (res) {
          setStorage(userKey, res);
          setNotify({
            isOpen: true,
            message: 'Success! Avatar has been saved.',
            type: 'success'
          })
          setCallSnackBar(!callSnackBar)
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: 'Fail! Avatar has not been saved.',
          type: 'error'
        })
          setCallSnackBar(!callSnackBar)
      })
        setTimeout(() => {
          window.location.reload()
        }, 3000)
    }
    console.log(croppedImage); // 在点击Save按钮时获取状态中的Blob对象
  };
  
  const handleImageUpload = (e:any) =>{
    const selectedImage = e.target.files[0];
    setAvatar(URL.createObjectURL(selectedImage));
    setEditState(true)
  }

  return (
    <MUI.Dialog open={dialogOpen} fullWidth>
      <MUI.DialogContent>
        {avatar?.indexOf("null") == -1 ?
        editState?          
        <div className="image-crop">
        <div className="crop-container">
            <Cropper
              image={avatar}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom} />
          </div>    
          <div className="controls">
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(Number(zoom))}
                classes={{ root: "slider" }} />
            </div>
            </div> : 
              <div style={{textAlign:'center'}}>
                <img src={avatar} 
                style={{width:'100%',maxHeight:'340px', maxWidth:'550px'}}>
                </img>
              </div>
            :         
            <div style={{textAlign:'center',height:'150px',marginTop:'100px'}}>
        <input
              accept="image/*"
              type="file"
              id="contained-button-file"
              onChange={handleImageUpload}
              style={{display:'none'}}
            />
            <label htmlFor="contained-button-file">
              <IconButton variant="contained" text='Upload Avatar' size='medium' component="span"/>
            </label>
        </div>}
      </MUI.DialogContent>
      <MUI.Stack direction="row" spacing={2} justifyContent={"center"}>
      {croppedImage? 
        <TextButton text='Save' onClick={()=>handleSave()}/> 
        :''}
      {avatar?.indexOf("null") == -1 ?       
      <>
      <input
            accept="image/*"
            type="file"
            id="contained-button-file"
            onChange={handleImageUpload}
            style={{ display: 'none' }} />
            <label htmlFor="contained-button-file">
              <TextButton text='Change' component="span" />
            </label>
        </>:''}
        <TextButton text='Cancel' onClick={()=>setDialogOpen(false)} />
        </MUI.Stack>
        <Notification notify={notify} setNotify={setNotify} />
  </MUI.Dialog>  
  );
};

export default ImageCrop;
