import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getSelectedResourcesList } from '../../../../dialogs/resource-dialog/ManageTopicResourcesDialog'
import { useTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles'
import { useMediaQuery } from '@mui/material'
import { ResourcesResponse } from '../../../../interface'
import ResourceDetailPageDialog from '../../../../dialogs/resource-dialog/ResourceDetailPageDialog'
import * as MUI from '../../../../MUI'

export interface courseTopicIdsProps {
  courseId: number
  topicId: number
}
interface TopicResourcesListProps {
  topicResources?: ResourcesResponse[]
  courseTopicIds?: courseTopicIdsProps
}

export function useMatchScreenSize () {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))
  const matchesAboveLG = useMediaQuery(theme.breakpoints.up('lg'))

  return [matchesSM, matchesLG, matchesAboveLG]
}

const TopicResourcesList = (props: TopicResourcesListProps) => {
  const { topicResources, courseTopicIds } = props
  const theme = useTheme()

  const [resourceDialogOpen, setResourceDialogOpen] = useState<boolean>(false)
  const [resourceId, setResourceId] = useState<number>()
  const navigate = useNavigate()
  const location = useLocation()
  
  const [matchesSM, matchesLG, matchesAboveLG] = useMatchScreenSize()


  const handleRsrcDetailOpen = (id?: number) => {
    setResourceDialogOpen(true)
    setResourceId(id)
  }

  const handleOnCloseDialog = () => {
    setResourceDialogOpen(false)
  }

    return (
      <>
        <MUI.Grid container columnSpacing={1} rowSpacing={0}>
          <MUI.Grid item xs={12} sm={6} md={12} lg={6}>
            <MUI.List style={matchesSM || matchesLG ?  { paddingBottom: 0 } : { paddingBottom: 8 }}>
              {topicResources?.map((value, index) => {
                if (index % 2 === 0) return <div key={index}>
                  {getSelectedResourcesList(value, false, handleRsrcDetailOpen, matchesSM, matchesAboveLG, courseTopicIds, navigate, location)}</div>
              })}
            </MUI.List>
          </MUI.Grid>
          <MUI.Grid item xs={12} sm={6} md={12} lg={6} 
            sx={{ 
              [theme.breakpoints.between(600, 900)]: {
                marginTop: '8px',
              },
            }}
          >
            <MUI.List style={matchesSM || matchesLG ?  { paddingTop: 0 } : { paddingTop: 8 }}>
              {topicResources?.map((value, index) => {
                if (index % 2 !== 0) return <div key={index}>{getSelectedResourcesList(value, false, handleRsrcDetailOpen, matchesSM, matchesAboveLG, courseTopicIds, navigate, location)}</div>
              })}
            </MUI.List>
          </MUI.Grid>
        </MUI.Grid>
        <ResourceDetailPageDialog dialogOpen={resourceDialogOpen} onCloseDialog={handleOnCloseDialog} resourceId={resourceId} />
      </>
    )
}

export default TopicResourcesList