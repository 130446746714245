import axios from "axios"
import { setupInterceptorsTo } from "./interceptors"

const axiosInstance = setupInterceptorsTo(axios.create())
axiosInstance.defaults.timeout = 20000

export interface QueryParams {
  [key: string]: string | number
}

type IPath = (string | number)[] | string | number

class BaseApiService {
  private getPath(path: IPath): string {
    return !Array.isArray(path) ? String(path) : path.join("/")
  }

  protected async get<T>(path: IPath, params?: QueryParams): Promise<T> {
    return axiosInstance
      .get(this.getPath(path), params)
      .then((res) => res.data)
  }

  protected async post<T>(path: IPath, params: object): Promise<T> {
    return axiosInstance
      .post(this.getPath(path), params)
      .then((res) => res.data)
  }

  protected async delete<T>(path: IPath, params?: object): Promise<T> {
    return await axiosInstance
      .delete(this.getPath(path), {params})
      .then((res) => res.data)}

  protected async put<T>(path: IPath, params: object): Promise<T> {
    return axiosInstance
      .put(this.getPath(path), params)
      .then((res) => res.data)
  }
}

export default BaseApiService
