import { styled } from "@mui/system"
import * as MUI from '../../../../MUI'
interface StepProps {
  completion?: boolean
}

const StepComponent = styled('div', { shouldForwardProp: (prop) => prop !== 'completion' })<StepProps>(
  ({ completion }) => ({
    height: '20px',
    width: '20px',
    margin: '14px 14px 14px 0',
    // backgroundColor: completion ? '#21bb19' : '#dddddd',
    backgroundColor: '#dddddd',
    borderRadius: '50%',
  })
)

const Step = (props: StepProps) => {
  const { completion } = props
  // return (
  //   <StepComponent completion={completion}>
  //     <div></div>
  //   </StepComponent>
  // )
  if(completion) {
    return (
      <MUI.CheckCircleIcon 
        style={{
          height: '20px',
          width: '20px',
          color: '#21bb19',
          margin: '14px 14px 14px 0'
        }}
      />
    )
  } else {
    return (
      <MUI.CircleIcon
        style={{
          height: '20px',
          width: '20px',
          color: '#dddddd',
          margin: '14px 14px 14px 0'
        }}
      />
    )
  }
}
export default Step
