import React, { useState } from 'react'
import * as MUI from '../../MUI'
import IconButton from '../../components/button/IconButton'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AbcIcon from '@mui/icons-material/Abc'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Grid } from '@material-ui/core'

const orderByList = [
  {
    id: 1,
    title: 'Created Date',
    value: 'created_at',
    icon: <CalendarMonthIcon />,
    info: 'order by date'
  },
  { id: 2, title: 'Title', value: 'title', icon: <AbcIcon />, info: 'order by name' }
]

const orderList = [
  { id: 1, title: 'Ascending', value: 'asc' },
  { id: 2, title: 'Descending', value: 'desc' }
]

const ResourceOrderFilter = ({
  data,
  setData,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  sortData
}: any) => {
  const handleOrderBy = (value: string) => {
    setOrderBy(value)
    sortData(value, order)
  }

  const handleOrder = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc')
    setData((data: any) => [...data.reverse()])
  }

  return (
    <>
        <div className='flex'>
          {orderByList.map((item: any) => (
            <div className='flex align-items-center' key={item.id}>
              <IconButton
                color='inherit'
                className={item.title === 'Title' ? 'abcbtn' : 'calendarbtn'}
                icon={item.icon}
                onClick={orderBy === item.value ? handleOrder : () => handleOrderBy(item.value)}
              />

              {orderBy === item.value &&
                (order === 'asc' ? (
                  <ArrowUpwardIcon style={{ marginLeft: '-20px' }} color='inherit' />
                ) : (
                  <ArrowDownwardIcon style={{ marginLeft: '-20px' }} color='inherit' />
                ))}
            </div>
          ))}
        </div>
    </>
  )
}

export default ResourceOrderFilter
