import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputDateTimeRangeField';
import Textarea from '@mui/joy/Textarea';
import apiService from '../../services/api-services';
import { getStorage, userKey } from "../../constants/auth";
import TextButton from '../../components/button/TextButton';

interface BookSessionProp {
  open:boolean,
  setOpen:Function,
  selectedStartTime:Date,
  setSelectedStartTime:Function,
  selectedEndTime:Date,
  setSelectedEndTime:Function,
  globalEvent:boolean,
}

export default function BookSession(props:BookSessionProp) {
  const {open, setOpen,selectedStartTime, selectedEndTime, setSelectedStartTime, setSelectedEndTime, globalEvent} = props
  const [value, setValue] = useState<DateRange<Dayjs>>()
  const [comments, setComments] = useState<string>('')
  const userInfo = getStorage(userKey)

  useEffect(() => {
    setValue([dayjs(selectedStartTime),dayjs(selectedEndTime),])
  }, [selectedStartTime,selectedEndTime])

  const handleClose = () => {
    setOpen(false);
  };

  const formattedDate = (date:Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  
  const handleSubmit = () => {
    if(!globalEvent){
      const studentData = new FormData()
      studentData.append('start', formattedDate(selectedStartTime))
      studentData.append('end', formattedDate(selectedEndTime))
      studentData.append('comments',userInfo.first_name + ' Meeting: '+ comments)
      studentData.append('user_id', userInfo.id)
      studentData.append('tutor_id',userInfo.tutor_id)
      studentData.append('event','Meeting')
      apiService.addTimeSheet(studentData).then((res) => {
        console.log(res)
        setOpen(false)
        window.location.reload()
      })
    } else{
      const globalEventData = new FormData()
      globalEventData.append('start', formattedDate(selectedStartTime))
      globalEventData.append('end', formattedDate(selectedEndTime))
      globalEventData.append('comments',' Plenary Session: '+ comments)
      globalEventData.append('user_id', '0')
      globalEventData.append('event','Plenary Session')
      apiService.addTimeSheet(globalEventData).then((res) => {
        console.log(res)
        setOpen(false)
        window.location.reload()
      })
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{globalEvent? 'Add Global Event':'Booking Session'}</DialogTitle>
        <DialogContent style={{width:'500px'}}>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <p></p>
         <SingleInputDateTimeRangeField
            label="Time Period"
            value={value}
            disabled={true}
            onChange={(newValue) => setValue(newValue)}
            fullWidth
            />
          </LocalizationProvider>

            <p></p>
          <Textarea placeholder="Comments" variant="outlined" 
          onChange={(e) => {setComments(e.target.value)}}/>

        </DialogContent>
        <DialogActions>
          <TextButton onClick={handleClose} text={'Cancel'}/>
          <TextButton onClick={handleSubmit} text={'Submit'}/>
        </DialogActions>
      </Dialog>
    </div>
  );
}
