import React, {useState, useEffect} from 'react'
import { User } from '../../../../interface'
import SearchBar from '../../../../components/search-bar/SearchBar'
import useSearchService from '../../../../services/useSearchService'

interface UserSearchBarProps {
  searchArray: User[]
  setFilteredArray: React.Dispatch<React.SetStateAction<User[]>>
  text: string
}

const UserSearchBar = (props: UserSearchBarProps) => {
  const {searchArray, setFilteredArray, text} = props

  const [searchBarLearners, setSearchBarLearners] = useState(useSearchService.initialParams.param)

  const searchLearner = (checkArray: User[], searchText: string) => {
    searchText = searchText.toLowerCase()
    if (!!searchText) {
      return checkArray.filter((user) => {
        return (
          [`${user.first_name} ${user.last_name}`]
            .toString()
            .toLowerCase()
            .includes(searchText) || user.email.toString().toLowerCase().includes(searchText)
        )
      })
    } else {
      return checkArray
    }
  }

  useEffect(() => {
    useSearchService.subscribeLearner(setSearchBarLearners)
    useSearchService.clearParams()
  }, [])

  useEffect(() => {
    setFilteredArray(searchLearner(searchArray, searchBarLearners))
  }, [searchArray, searchBarLearners])

  return (
    <SearchBar id={`search-${text.toLowerCase()}`} text={text} />
  )
}

export default UserSearchBar