import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import React, { useRef } from "react";
import { authHeader } from "../../constants/auth";
import { apiUrl } from "../../environment";
import apiService from "../../services/api-services";
import { CourseTopicContext } from "../../pages/courses-page/course-components/context/CourseTopicContext";
import { useContext } from "react"
import TextButton from "../../components/button/TextButton";

interface DeleteDialogProps {
  open: boolean
  deleteProperty?: string
  closeDialog: () => void
  handleDelete: (itemId?: any) => void
  itemId?: any
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const { deleteProperty, open, closeDialog, handleDelete, itemId } = props
  // const {deleteProperty, id open, closeDialog, getApiData setNotify topicId handleDelete} = props;
  const {apiTopic, setApiTopic, setIsTopicLoading} = useContext(CourseTopicContext)

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDelete = async () => {
  //   await axios.delete(`${apiUrl}/${deleteProperty}/${id}`, {
  //     headers: deleteProperty === 'user' ? authHeader : {}
  //   })
  //   .then(res => {
  //       handleClose();
  //       setNotify({
  //         isOpen: true,
  //         message: `${deleteProperty} Deleted Successfully`,
  //         type: "error",
  //       });
  //       getApiData();
  //   });
  // }

  // const handleDeleteTask = () => {
  //   setIsTopicLoading(true)
  //   try {
  //     apiService.deleteAdminCourseTopicTask(topicId, id)
  //       .then((res) => {
  //           if (res) {
  //             if (apiTopic?.length) {
  //               const filteredApiTopicTask = apiTopic[0].course_topic_tasks.filter((task) => {
  //                 if (task.id !== id) {
  //                   return true
  //                 }
  //                 return false
  //               })

  //               const newApiTopic = apiTopic.map((topic) => {
  //                 if (topic.id === topicId) {
  //                   topic.course_topic_tasks = filteredApiTopicTask
  //                 }
  //                 return topic
  //               })
  //               setApiTopic(newApiTopic)
  //               handleClose()
  //               setIsTopicLoading(false)
  //               setNotify({
  //                 isOpen: true,
  //                 message: `${deleteProperty} deleted successfully`,
  //                 type: 'success'
  //               })
  //             }
  //           }
  //       }).catch((err) => {
  //         setNotify({
  //           isOpen: true,
  //           message: `${deleteProperty} failed to delete`,
  //           type: 'error'
  //         })
  //       })
  //   } catch(err) {
  //     console.log(err)
  //   }
  // }
 
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        Are you sure to delete this {deleteProperty === 'customer' ? 'donor' : deleteProperty}?
      </DialogTitle>
      <DialogContent>This action can't be reversed.</DialogContent>
      <DialogActions>
        <TextButton onClick={closeDialog} text='Cancel' />
        <TextButton onClick={() => handleDelete(itemId)} text='Delete' color='error' />
      </DialogActions>
    </Dialog>
  )
};

export default DeleteDialog;
