import { useState, useContext } from 'react'
import { useFormValidation } from '../../../../dialogs/form-dialog/FormDialogValidation'
import { CourseTopicContext } from '../context/CourseTopicContext'
import { CourseContext } from '../context/CourseContext'
import { ICourseTopickTaskCodeLinkRes, ICourseTopicTask, ITextFieldProps, ITaskLearner } from '../../../../interface'
import  customUrl  from 'custom-url-check'
import apiService from '../../../../services/api-services'
import FormDialog from '../../../../dialogs/form-dialog/FormDialog'
import DeleteDialog from '../../../../dialogs/delete-dialog/delete-dialog'
import * as MUI from '../../../../MUI'
import IconButton from '../../../../components/button/IconButton'

interface TaskCodeLinkFormData extends Pick<ICourseTopicTask,
  'code_link'
> {
  [key: string]: any
}

interface AdminTaskCodeLinkProps {
  method?: 'upload codelink' | 'remove codelink'
  data: ICourseTopicTask
  disable?: boolean
}

const AdminTaskCodeLink = (props: AdminTaskCodeLinkProps) => {
  const { method, data, disable } = props

  const initFormValue: TaskCodeLinkFormData = {
    code_link: ''
  }

  const [codeLinkIsOpen, setCodeLinkIsOpen] = useState(false)
  const [taskCodeLinkFormData, setTaskCodeLinkFormData] = useState<TaskCodeLinkFormData>(initFormValue)
  const { tasks, setTasks } = useContext(CourseTopicContext)
  const { setNotify } = useContext(CourseContext)
  const { error, setError, formIsEmpty} = useFormValidation()
  const submit = {} as Pick<ITaskLearner, 'completion_stage' | 'tutor_notification_status'>
  
  const handleCodeLinkOpen = () => {
    setCodeLinkIsOpen(true)
  }

  const handleCodeLinkClose = () => {
    setCodeLinkIsOpen(false)
    setTaskCodeLinkFormData(initFormValue)
    setError({codeLink: false})
  }

  const isValidUrl = (urlString?: string) => {
    let url
    try {
      if(urlString)
      url = new URL(urlString)
    }
    catch(err) {
      console.log('url incorrect',err);
    }
    if(url){
      return (url.protocol === 'http:' || url.protocol === 'https:') && customUrl(url.href, 'codesandbox')
    }
  }

  const handleCodeLinkSubmit = () => {
    if(!formIsEmpty(taskCodeLinkFormData) && isValidUrl(taskCodeLinkFormData.code_link)) {
      try {
        if (data) {
          if (tasks?.length) {
            submit.completion_stage = 'submitted'
            submit.tutor_notification_status = 'notified'
            const newData = tasks.map((task: ICourseTopickTaskCodeLinkRes) => {
              if (task.id === data.id) {
                return {
                  ...task,
                  task_learner: {
                    ...task.task_learner,
                    id: data.id,
                    git_url: taskCodeLinkFormData.code_link,
                    completion_stage: submit?.completion_stage,
                    tutor_notification_status: submit.tutor_notification_status
                  }
                }
              }
              return task
            })
            console.log('newData', newData)
            // setTasks(newData)
            // handleCodeLinkClose()
            newData.forEach(task => {
              if(task.id === data.id) {
                if(task.task_learner) {
                  apiService.updateLearnerTask(task.id, task.task_learner).then(
                    res => {
                      if(res) {
                        setNotify({
                          isOpen: true,
                          message: 'Successfully added codelink',
                          type: 'success'
                        })
                        setTasks(newData)
                        handleCodeLinkClose()
                      } else {
                        return
                      }
                    }
                  ).catch(err => {
                    console.log(err)
                    setNotify({
                      isOpen: true,
                      message: 'Failed to add codelink',
                      type: 'error'
                    })
                  })
                }
              }
            })
          }
        }
      } catch (err) {
        console.log(err)
        setNotify({
          isOpen: true,
          message: 'Failed to add codelink',
          type: 'error'
        })
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'Not a valid CodeSandbox URL',
        type: 'error'
      })
    }
  }

  const handleCodeLinkDelete = () => {
    try{
      submit.completion_stage = ''
      if (tasks?.length) {
        const newData = tasks.map((task) => {
          if (task.id === data.id) {
            if(task.task_learner) {
              return { 
                ...task, 
                task_learner: {
                  ...task.task_learner,
                  git_url: '',
                  completion_stage: submit.completion_stage
                } 
              }
            }
          }
          return task
        })

        newData.forEach(task => {
          if(task.id === data.id) {
            if(task.task_learner) {
              apiService.updateLearnerTask(task.id, task.task_learner).then(
                res => {
                  if (res){
                    setNotify({
                      isOpen: true,
                      message: `Successfully delete ${data.task_learner?.git_url}`,
                      type: 'success'
                    })
                    handleCodeLinkClose()  
                    setTasks(newData)
                  }
                }
              ).catch(err => {
                console.log(err)
                setNotify({
                  isOpen: true,
                  message: `Fail to delete ${data.task_learner?.git_url}`,
                  type: 'error'
                })
              })
            }
          }
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const toggleRevisedButton = () => {
    console.log('Revised Button Clicked')
    submit.completion_stage = 'submitted'
    submit.tutor_notification_status = 'notified'

    if(tasks?.length) {
      const newData = tasks.map(task => {
        if(task.id === data.id) {
          if(task.task_learner?.learner_id) {
            return {
              ...task,
              task_learner: {
                ...task.task_learner,
                id: task.id,
                completion_stage: submit.completion_stage,
                tutor_notification_status: submit.tutor_notification_status,
              }
            }
          }
        }
        return task
      })

      newData.forEach(task => {
        if(task.id === data.id) {
          if(task.task_learner) {
            apiService.updateLearnerTask(task.id, task.task_learner).then(
              res => {
                if(res) {
                  setTasks(newData)
                }
              }
            ).catch(err => {
              console.log(err)
              setNotify({
                isOpen: true,
                message: 'Failed to revise',
                type: 'error'
              })
            })
          }
        }
      })
    }
  }

  const children: ITextFieldProps[] = [
    {
      autoFocus: true,
      margin: 'dense',
      id:'embed_url',
      label: 'Embed URL',
      type: 'url',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: string)=>{
        taskCodeLinkFormData.code_link = e;
        !taskCodeLinkFormData.code_link.length ? setError({codeLink: true}) : setError({codeLink: false})
      },
      className: '',
      value: taskCodeLinkFormData.code_link, 
      required: true,
      error: error.codeLink,
    }
  ]
  return (
    <div>
      <IconButton 
        variant='contained'  
        onClick={() => handleCodeLinkOpen()} 
        text={
          data.task_learner?.tutor_review === 'in review' ? 'tutor in review' : 
          data.task_learner?.tutor_review === 'review complete' ? 'Not modified' : method
        } 
        size='medium' 
        disable={disable} 
      />
      {(data.task_learner?.completion_stage === 'amending') && 
        <span style={{ marginLeft: '10px'}}>
          <IconButton text='revised' variant='contained' size='medium' onClick={toggleRevisedButton} />
        </span>
      }
      {method === 'upload codelink' && 
        <FormDialog
          isOpen={codeLinkIsOpen}
          handleClose={handleCodeLinkClose}
          handleSubmit={handleCodeLinkSubmit}
          children={children}
          method='add codelink'
        />
      }
      {method === 'remove codelink' &&
        <DeleteDialog 
          open={codeLinkIsOpen}
          closeDialog={handleCodeLinkClose}
          deleteProperty={data.task_learner?.git_url}
          handleDelete={handleCodeLinkDelete}
        />
      }
    </div>
  )
}

export default AdminTaskCodeLink