import React, { useState } from "react";

interface PaginationPorps {
  next: Function;
  prev: Function;
  jump: Function;
  currentData: Function;
  currentPage: number;
  maxPage: number;
}

function usePagination(data: string[], itemsPerPage: number): PaginationPorps {
  const [currentPage, setCurrentPage] = useState<number>(1);
  let maxPage: number;
  if (data.length === 0) {
    maxPage = 1;
  } else {
    maxPage = Math.ceil(data.length / itemsPerPage);
  }

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
