import { useState } from 'react'
import { ICourseTopicResponse, IMenuProps } from '../../../../interface'
import ActionMenu from '../../../../components/menu/ActionMenu'
import AdminCourseTopic from './AdminCourseTopic'
import AdminTopicResources from './AdminTopicResources'
import * as MUI from '../../../../MUI'

interface TopicMenuProps {
  data: ICourseTopicResponse
}

const TopicMenu = (props: TopicMenuProps) => {
  const {data} = props
  const [topicOpen, setTopicOpen] = useState<boolean>(false)

  const config: IMenuProps = {
    method: {
      buttonId: 'topic-menu-button',
      menuId: 'topic-menu'
    },
    toggleMenuButton: <MUI.SettingsIcon />,
    childrenMenuItem: [
      {
        component: (
          <AdminCourseTopic method='edit topic' data={data}>
            Edit Topic
          </AdminCourseTopic>
        ),
        onClickMenuItem() {
          setTopicOpen(true)
        },
      },
      {
        component: (
          <AdminTopicResources />
        )
      },
      {
        component: (
          <AdminCourseTopic method='delete topic' data={data}>
            Delete Topic
          </AdminCourseTopic>
        )
      }
    ],
    toggleMenuBtnStyle: {
      marginTop: '0px'
    },
    taskMenuListProps: 'toggle-topic-menu-button'
  }

  return (
      <ActionMenu config={config} />
  )
}

export default TopicMenu