import { useState, useEffect } from "react"
import { Editor } from "@tinymce/tinymce-react"
import apiService from "../../../../services/api-services"
import CircularProgress from '@mui/material/CircularProgress';
import "./Components.css"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "../../../../components/button/IconButton"
import TextButton from "../../../../components/button/TextButton"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Fold } from "../../../../components/fold/fold"
import moment_tz from 'moment-timezone';
import { imageBaseUrl } from '../../../../constants/constants'
let formData = new FormData()

interface TopicCommentProps {
  taskId: any,
  learnerId: any
}

const TopicComments = (props: TopicCommentProps) => {
  const { taskId, learnerId } = props
  const [userID, setUserID] = useState<any>("")
  const [comment, setComment] = useState<string>("")
  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [commentsLoading,setCommentsLoading] = useState<boolean>(true)
  const [editorLoading, setEditorLoading] = useState<boolean>(true)
  const [deleteDialogOpen,setDeleteDialogOpen] = useState<boolean>(false)
  const [deleteID, setDeleteID] = useState<number>(0)
  const [commentsData, setCommentsData] = useState<Array<any>>([])
  const [commentsCount, setCommentsCount] = useState<number>(0)

  useEffect(() => {
    learnerId ? setUserID(learnerId) : setUserID(JSON.parse(localStorage.getItem('USERKEY')!).id)
  }, [])

  useEffect(() => {
    if(userID){
    getCommentsData()
    }
  }, [userID])

  const getCommentsData = () =>{
    apiService.getTaskComment(taskId, userID).then((res) => {
      setCommentsCount(res.length)
      setCommentsData(res)
      setCommentsLoading(false)
    })
  }

  const handleTiny = (e: any) => {
    setComment(e.level.content)
  }

  const handleSubmit = () => {
    setShowEditor(false)
    setCommentsLoading(true)
    formData.append('content', comment)
    formData.append('object_id', taskId)
    formData.append('category','task')
    formData.append('userId',userID)
    apiService.createTaskComment(formData).then(getCommentsData)
    }
  
  const deleteDialog = (id:number) =>{
    setDeleteID(id)
    setDeleteDialogOpen(true)
  }
  
  const handleDelete = (id: number) => {
    setDeleteDialogOpen(false)
    setCommentsLoading(true)
    apiService.deleteTaskComment(id).then(getCommentsData)
  }

  return (
    <div className="c-topic-comment-wrap">
      <div style={{marginBottom:'25px',width:'102%'}}><Divider/></div>
      {!showEditor ? (
        <div className="c-topic-comment-nav">
          <span>
            {commentsCount}
            {commentsCount > 1 ? " comments" : " comment"}
          </span>
          <IconButton variant="contained" onClick={() => {setShowEditor(true)}} text='Leave a comment' size='medium'/>
        </div>
      ) : (
        <div className="c-topic-comment-editor">
          <div style={{ height: 300, display: editorLoading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
          <Editor
            apiKey="poo1h7i6pzm2252c7ljs5s5xcc9ggkskyvkuplkhfvcwl1vq"
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image",
              paste_data_images: true,
              content_style: "body { font-family:Roboto,sans-serif; font-size:16px }",
              images_upload_handler: (blobInfo, progress) => new Promise(async (resolve, reject) => {
                let file = blobInfo.blob();
                formData.append("file",file);
                const promise = apiService.uploadFile(formData);
                promise.then(response => {
                  resolve('https://gols.s3.ap-southeast-2.amazonaws.com/' + response.url)
                }).catch(error => {
                  console.error(error);
                });                
              })
            }}
            onChange={handleTiny}
            onLoadContent={() => {
              setEditorLoading(false)
            }}
          />
          <div className="c-topic-editor-button-group">
            <IconButton variant="outlined" onClick={handleSubmit} text='Submit'/>
            <IconButton variant="outlined" onClick={() => setShowEditor(false)} text='Cancel'/>
          </div>
          <span>
          {commentsCount}
          {commentsCount > 1 ? " comments" : " comment"}
          </span>
        </div>
      )}

  {/* Comments Loading */}
      {commentsLoading?      
      (<div style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
      </div>)
        :
      (commentsData.map((commentData: { id:any; tutor:any; user: any; content: any; updated_at:any}) => (
        <List sx={{ width: '103%', bgcolor: 'background.paper' }} key={commentData.id} style={{marginLeft:'-15px'}}>
          <ListItem style={{textAlign:'right',justifyContent:'flex-end'}}>
            <Grid>
              <span style={{fontSize: '5px', color:'grey'}}>
              {moment_tz(commentData.updated_at).format('YYYY-MM-DD kk:mm:ss')}
              </span>
            </Grid>
          </ListItem>
          <ListItem alignItems="flex-start" style={{marginTop:'-50px'}}>
            <ListItemAvatar>
              <Avatar alt="" src={commentData.tutor? commentData.tutor.user_avatar ? (imageBaseUrl + commentData.tutor.user_avatar):'':'' ||commentData.user.user_avatar ? (imageBaseUrl + commentData.user.user_avatar) : ''} />
            </ListItemAvatar>
            <ListItemText
              primary={<div style={{marginTop:'8px', fontSize:'16px',color:'grey'}}>
                  {commentData.tutor? commentData.tutor.email:commentData.user.email}
                </div>}
              secondary={<Fold maxHeight={80} children={commentData.content} expandEnable={true}/>}
            />
          </ListItem>
          <ListItem style={{textAlign:'right',justifyContent:'flex-end'}}>
            <Grid>
          {!commentData.tutor && commentData.user.email == JSON.parse(localStorage.getItem('USERKEY')!).email ? 
                <IconButton onClick={() => deleteDialog(commentData.id)} icon={<DeleteIcon/>} 
                color='warning' />:<span></span> 
            }
            {JSON.parse(localStorage.getItem('USERKEY')!).role != 3 ?
            <IconButton onClick={() => deleteDialog(commentData.id)} icon={<DeleteIcon/>} color='warning' />:<span></span>}
            </Grid>
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      ))
      )
}

{/* Delete Dialog */}
    <Dialog open = {deleteDialogOpen}>
        <DialogTitle>
            Are you sure to delete this comment?
        </DialogTitle>
        <DialogContent>
            This action can't be reversed!
        </DialogContent>
        <DialogActions>
          <TextButton onClick={()=>setDeleteDialogOpen(false)} text='Cancel'/>
          <TextButton onClick={()=>handleDelete(deleteID)} text='Delete' color="error"/>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default TopicComments
