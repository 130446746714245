import React, { useState, useEffect, useContext, useRef } from "react"
import { useFormValidation } from "../../../../dialogs/form-dialog/FormDialogValidation"
import { delayToDo } from "./Delayed"
import { CourseContext } from "../context/CourseContext"
import { CourseTopicContext } from "../context/CourseTopicContext"
import { ITextFieldProps, IAdminCourseTopicReq, ICourseTopicResponse, ICourseResponse, ICourseTopic, IAdminCourseTopicRes } from "../../../../interface"
import apiService from "../../../../services/api-services"
import FormDialog from "../../../../dialogs/form-dialog/FormDialog"
import * as MUI from "../../../../MUI"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextButton from "../../../../components/button/TextButton"

interface CourseTopicData extends ICourseTopicResponse {
  [key: string]: any
}

interface AdminCourseTopicProps {
  method: 'add topic' | 'edit topic' | 'delete topic'
  data?: CourseTopicData
  courseId?: number
  children?: React.ReactChild
  // topicOpen: boolean
  // setTopicOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface AdminCourseTopicInit extends IAdminCourseTopicReq {
  [key: string]: any
}

const AdminCourseTopic = (props: AdminCourseTopicProps) => {
  const initFormValue: AdminCourseTopicInit = {
    course_topic_code: undefined,
    lecture_hours: 0,
    suggested_learning_hours: 0,
    title: "",
    description: "",
    type: "elective",
  }

  const { method, data, courseId, children } = props
  const { error, setError, formIsEmpty } = useFormValidation()
  const { apiCoursesData, setApiCoursesData, setIsCourseLoading, setNotify, setSubmitCourseTopicId } = useContext(CourseContext)
  const { apiTopic, setApiTopic, setIsTopicLoading, courseTopics, setCourseTopics } = useContext(CourseTopicContext)
  const [courseTopicFormData, setCourseTopicFormData] = useState<AdminCourseTopicInit>(initFormValue)
  const [topicIsOpen, setTopicIsOpen] = useState(false)

  useEffect(() => {
      if (method === "edit topic" && !!data) {
        for (let k in data) {
          if (courseTopicFormData.hasOwnProperty(k)) {
            courseTopicFormData[k] = data[k]
          }
        }
      }
  // }, [topicIsOpen])
  }, [])

  const handleTopicOpen = () => {
    console.log("handleOpen" + method)
    setTopicIsOpen(true)
  }

  const handleTopicClose = () => {
    console.log('handleClose')
    if(method === 'add topic') {
      setCourseTopicFormData(initFormValue)
    }
    setTopicIsOpen(false)
    // setTopicOpen(false)
  }

  const handleTopicSubmit = () => {
    console.log("onSubmit")
    let submitItem = {
      course_id: 0,
      topic_id: 0,
    }
    if (method === "add topic" && courseId) {
      setIsCourseLoading(true)
      apiService
        .addAdminCourseTopic(courseId, courseTopicFormData)
        .then((res) => {
          console.log('show add topic response', res)
          setNotify({
            isOpen: true,
            message: 'Add Topic successfully',
            type: 'success',
          })
          apiCoursesData.forEach((el: ICourseResponse) => {
            if (el.id === courseId) {
              el.course_topic.push(res)
              // el.course_topic.push({
              //   ...courseTopicFormData,
              //   course_id: courseId,
              //   id: 42
              // })
              submitItem.course_id = el.id
              submitItem.topic_id = res.id  //pass res.id - topicId
            }
            // setTopicIsOpen(false)
            setApiCoursesData(apiCoursesData)
            setCourseTopicFormData(initFormValue)
            delayToDo(()=> {setIsCourseLoading(false)},2500)
            setSubmitCourseTopicId({
              courseId: submitItem.course_id,
              topicId: submitItem.topic_id
            })
          })
        }).catch(err => {
          console.log('Show fail to add topic',err)
          if(err) {
            setNotify({
              isOpen: true,
              message: 'Fail to add topic',
              type: 'error'
            })
            setIsCourseLoading(false)
          }
        })
    }

    if (method === "edit topic" && !!data) {
      setIsTopicLoading(true)
      apiService
        .editAdminCourseTopic(data.id, courseTopicFormData)
        .then((res) => {
          if(res) {
            const newTopicData = apiTopic?.map(
              (el: ICourseTopicResponse) => 
                el.id === data.id ? {
                  ...el,
                  course_topic_code:courseTopicFormData.course_topic_code,
                  lecture_hours: courseTopicFormData.lecture_hours,
                  suggested_learning_hours: courseTopicFormData.suggested_learning_hours,
                  title: courseTopicFormData.title,
                } : el
            )
            setApiTopic(newTopicData)
            const newTopicTitleType = courseTopics?.map(current =>{
              if (current.id === data.id) {
                return {
                  ...current, title: courseTopicFormData.title, type: courseTopicFormData.type
                }
              } 
              return current
            })
            setCourseTopics?.(newTopicTitleType!)
          }
          setTopicIsOpen(false)
          // setTopicOpen(false)
          setIsTopicLoading(false)
          // setCourseTopicFormData(initFormValue)
        })
    }
  }

  const courseTopicForm: ITextFieldProps[] = [
    {
      autoFocus: true,
      margin: 'dense',
      id: 'title',
      label: 'Title',
      type: 'title',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: string) => (courseTopicFormData.title = e),
      className: '',
      value: courseTopicFormData.title,
      required: true,
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'course_topic_code',
      label: 'Course Topic Code',
      type: 'number',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: number) => {
        courseTopicFormData.course_topic_code = e
        console.log(courseTopicFormData.course_topic_code.valueOf())
        courseTopicFormData.course_topic_code.valueOf() <= 0
          ? setError({ ...error, course_topic_code: true })
          : setError({ ...error, course_topic_code: false })
      },
      className: '',
      value: courseTopicFormData.course_topic_code,
      required: true,
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'description',
      label: 'Description',
      type: 'description',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: string) => (courseTopicFormData.description = e),
      className: '',
      value: courseTopicFormData.description,
      required: false,
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'lecture_hours',
      label: 'Lecture Hours',
      type: 'lecture_hours',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: number) => (courseTopicFormData.lecture_hours = e),
      className: '',
      value: courseTopicFormData.lecture_hours,
      required: false,
    },
    {
      autoFocus: false,
      margin: 'dense',
      id: 'suggested_learning_hours',
      label: 'Suggested Learning Hours',
      type: 'suggested_learning_hours',
      fullWidth: true,
      select: false,
      variant: 'outlined',
      onChange: (e: number) => (courseTopicFormData.suggested_learning_hours = e),
      className: '',
      value: courseTopicFormData.suggested_learning_hours,
      required: true,
    }
  ]

  const [deleteDialogOpen,setDeleteDialogOpen] = useState<boolean>(false)
  const [deleteID, setDeleteID] = useState<number>(0)

  const deleteDialog = (id:number) =>{
    setDeleteID(id)
    setDeleteDialogOpen(true)
  }

  const handleDelete = (id:number) =>{
    setDeleteDialogOpen(false)
    apiService.deleteAdminCourseTopic(id).then((res) => {
      if(res){
        setNotify({
          isOpen: true,
          message: 'Delete Topic successfully',
          type: 'success',
        })
        apiService.getAllCourses().then((res) => {
          setApiCoursesData(res)
        })
      }
  })
}

  return (
    <>
        {method === 'add topic' && 
                  <MUI.IconButton
                  sx={{ width: '100%', justifyContent: 'flex-start', borderRadius: 0 }}
                  onClick={handleTopicOpen}
                  disableRipple
                >
                  <MUI.AddBoxIcon />
                  <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
                </MUI.IconButton>
        }
                {method === 'edit topic' && 
                  <MUI.IconButton
                  sx={{ width: '100%', justifyContent: 'flex-start', borderRadius: 0 }}
                  onClick={handleTopicOpen}
                  disableRipple
                >
                  <MUI.EditIcon />
                  <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
                </MUI.IconButton>
        }
                {method === 'delete topic' && 
                  <MUI.IconButton
                  sx={{ width: '100%', justifyContent: 'flex-start', borderRadius: 0 }}
                  onClick={() => deleteDialog(data!.id)}
                  disableRipple
                >
                  <MUI.DeleteIcon />
                  <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
                </MUI.IconButton>
        }
      <FormDialog
        // isOpen={topicOpen}
        isOpen={topicIsOpen}
        handleClose={handleTopicClose}
        children={courseTopicForm}
        handleSubmit={handleTopicSubmit}
        method={method}
      />
          {/* Delete Dialog */}
    <Dialog open = {deleteDialogOpen}>
        <DialogTitle>
            Are you sure to delete this topic?
        </DialogTitle>
        <DialogContent>
            This action can't be reversed!
        </DialogContent>
        <DialogActions>
          <TextButton onClick={()=>setDeleteDialogOpen(false)} text='Cancel'/>
          <TextButton onClick={()=>handleDelete(deleteID)} text='Delete' color="error"/>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AdminCourseTopic
