import { imageBaseUrl } from '../../../constants/constants'
import timeDiff from './time-different'
import * as MUI from '../../../MUI'

interface LearnerNotificationMenuProps {
  learnerNotificationsItems: Array<any>
  handleClickOnItem: (taskId: number, learnerId: number) => void
  handleCloseNotificationMenu: () => void
}

const NotificationText = (props: {tutorReview: any, completionStage: any}) => {
    const {tutorReview, completionStage} = props

    if(tutorReview === 'in review') {
      return <>has been in review</>
    } else if(completionStage === 'amending') {
      return <>needs to amend</>
    } else if(completionStage === 'review complete') {
      return <>has completed</>
    }

    return <></>
} 

const LearnerNotificationMenu = (props: LearnerNotificationMenuProps) => {
  const { learnerNotificationsItems, handleClickOnItem, handleCloseNotificationMenu } = props


  return (
    <>
      {learnerNotificationsItems.sort((a: any, b: any) => {
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      }).map((item, index) => {
        return (
          item.learner_notification_status && (
            <MUI.MenuItem
              style={{ marginTop: 10, marginBottom: 10 }}
              key={index}
              onClick={() => {
                console.log(item.task_id, item.learner_id)
                handleClickOnItem(item.task_id, item.learner_id)
                handleCloseNotificationMenu()
              }}
            >
              <MUI.ListItemAvatar>
                <MUI.Avatar
                  src={item.tutor.user_avatar ? imageBaseUrl + item.tutor.user_avatar : ''}
                />
              </MUI.ListItemAvatar>
              <span style={{ display: 'flex', flexFlow: 'column' }}>
                <MUI.Typography>
                  <span style={{ fontWeight: '600' }}>
                    {item.tutor.first_name} {item.tutor.last_name}
                  </span>
                </MUI.Typography>
                <span style={{ display: 'flex', flexFlow: 'column' }}>
                  <MUI.Typography>
                    <span style={{ fontSize: 12, fontWeight: 600 }}>Task {item.task.title}</span>
                    {' '}
                    <span style={{ color: 'grey', fontSize: 12 }}>
                      <NotificationText
                        tutorReview={item.task_learner.tutor_review}
                        completionStage={item.task_learner.completion_stage}
                      />
                    </span>
                  </MUI.Typography>
                  <span>
                    <MUI.AccessTimeFilledIcon color='disabled' fontSize='small' />
                    <span style={{ fontSize: 12 }}> {timeDiff.timeAgo(item.updated_at)}</span>
                  </span>
                </span>
              </span>
            </MUI.MenuItem>
          )
        )
      })}
    </>
  )
}

export default LearnerNotificationMenu