export interface ActiveValue {
  is_active: number,
  label: string
}

export interface RoleValue {
  role: number,
  label: string
}

export const activeValue: ActiveValue[] = [
  {
    is_active: 0,
    label: "Inactive",
  },
  {
    is_active: 1,
    label: "Active",
  },
]

export const roleValue: RoleValue[] = [
  {
    role: 1,
    label: 'Admin' 
  },
  {
    role: 2,
    label: 'Tutor'
  },
  {
    role: 3,
    label: 'Learner'
  }
]
