import * as MUI from '../../MUI'
import { IResourceTag } from '../../interface'
import './ResourceCardStyles.css'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '../../components/button/IconButton'
import { Fold } from '../../components/fold/fold'
import { Chip } from '@material-ui/core'
import { Badge } from '@mui/material'

type CardProps = {
  data: any
  isCardEdit: boolean
  toggleDialog: Function
  setDataEdit: Function
  onDelete: Function
}

type TagColor = {
  [key: string]: any
}

export const tagColor: TagColor = {
  HTML: 'primary',
  JS: 'secondary',
  React: 'success',
  CSS: 'dark',
  Sessions: 'danger',
}

const ResourceCard = ({ data, isCardEdit, toggleDialog, setDataEdit, onDelete }: CardProps) => {
 
  const handleChange = () =>{
    toggleDialog()
    setDataEdit(data)
  }

  const handleDelete = () =>{
    onDelete(data.id)
  }

  return (
    <Grid item xs={12} md={6} lg={4} xl={3} p={0} style={{ position: 'relative' }}>
      <a href={`/Resources/${data.id}`}>
        <Box className='resource-card' style={{ display: 'flex', flexDirection: 'column' }}>
          <Box className='resource-title'>{data.title}</Box>
          <Box className='resource-description'>
            <Fold maxHeight={80} children={data.description} expandEnable={false} />
          </Box>
          <Box
            className={`resource-tag-container flex fw-no-wrap ${
              isCardEdit ? 'fade-out' : 'fade-in'
            }`}
          >
            {data.resource_tags.map((tag: IResourceTag) => (
              <>
                <span key={tag.id} className={`badge rounded-pill bg-${tagColor[tag.title]}`}>
                  <Chip label={tag.title} color={tagColor[tag.title]} />
                </span>
              </>
            ))}
          </Box>
        </Box>
      </a>

      <Box
        className={`flex content-between resource-btn-container ${
          isCardEdit ? 'fade-in' : 'fade-out'
        }`}
      >
        <IconButton
          onClick={handleChange}
          color='info'
          icon={<MUI.NoteAltOutlinedIcon />}
          className='resource-button'
        />
        <IconButton
          onClick={handleDelete}
          color='warning'
          icon={<MUI.DeleteIcon />}
          className='resource-button'
        />
      </Box>
    </Grid>
  )
}

export default ResourceCard
