export enum RootPath {
  resources = 'resources',
  resource = 'resource',
  video = 'video',
  resourceVideo = 'resourceVideo',
  config = 'config',
  courses = 'courses',
  course = 'course',
  topic = 'topic',
  login = 'login',
  comment = 'comment',
  comments = 'comments',
  getappconfiginfo = 'getappconfiginfo',
  addappconfig='addappconfig',
  admin = 'admin',
  task = 'task',
  tutor = 'tutor',
  learners = 'learners',
  learner = 'learner',
  user = 'user',
  users = 'users',
  courseTopicResource = 'courseTopicResource',
  courseTopicResources = 'courseTopicResources',
  courseTopic = 'courseTopic',
  notification = 'notification',
  userUpdateProfile = 'user-updateprofile',
  tags = 'tags',
  tag='tag',
  timesheet='timesheet'
}

export enum SubPath {
  add = 'add',
  update = 'update',
  delete = 'delete'
}
