import { useContext, useEffect, useState } from 'react'
import { CourseTopicContext } from '../context/CourseTopicContext'
import { ICourseTopicTask, ITaskLearner } from '../../../../interface'
import { ProgressContext } from '../course-list/Course'
import IconButton from '../../../../components/button/IconButton'
import apiService from '../../../../services/api-services'
import HandleTaskDeadline from './HandleTaskDeadline'

interface HandleReviewTaskProps {
  data: ICourseTopicTask
  learnerId: number
  due: Date | undefined
  timesheetId:number| undefined
  apiLearnerTimesheet:any
  setApiLearnerTimesheet: Function
}

const HandleReviewTask = (props: HandleReviewTaskProps) => {
  const { data, learnerId, due, timesheetId, apiLearnerTimesheet, setApiLearnerTimesheet } = props

  const { tasks, setTasks } = useContext(CourseTopicContext)
  const { reduceObj, setReduceObj, setProgressHeight } = useContext(ProgressContext)
  const submit = {} as Pick<ITaskLearner, 'tutor_review' | 'completion_stage' | 'learner_notification_status'>

  const toggleButton = (method: 'review' | 'dissatisfied' | 'pass' | 'reopen') => {
    if(tasks?.length) {
      switch(method) {
        case 'review' : 
          submit.tutor_review = 'in review'
          submit.learner_notification_status = 'notified'
          break
        case 'dissatisfied' : 
          submit.tutor_review = ''
          submit.completion_stage = 'amending'
          submit.learner_notification_status = 'notified'
          break
        case 'pass' :
          submit.tutor_review = 'review complete'
          submit.learner_notification_status = 'notified'
          break
        case 'reopen':
          submit.tutor_review = ''
          submit.completion_stage = 'amending'
          submit.learner_notification_status = 'notified'
          break
        default: 
          break
      }

      const newData = tasks.map((task) => {
        // console.log(data.id)
        if (task.id === data.id) {
          if(method === 'review')
            return {
              ...task,
              task_learner: {
                ...task.task_learner,
                id: task.id,
                tutor_review: submit.tutor_review,
                learner_notification_status: submit.learner_notification_status
              }
            }
          if(method === 'dissatisfied') {
            return {
              ...task,
              task_learner: {
                ...task.task_learner,
                id: task.id,
                tutor_review: submit.tutor_review,
                completion_stage: submit.completion_stage,
                learner_notification_status: submit.learner_notification_status
              }
            }
          }
          if(method === 'pass') {
            console.log('Pass button clicked', submit.tutor_review)
            return {
              ...task,
              task_learner: {
                ...task.task_learner,
                id: task.id,
                tutor_review: submit.tutor_review,
                learner_notification_status: submit.learner_notification_status
              }
            }
          }
          if(method === 'reopen') {
            console.log('Reopen clicked', submit)
            return {
              ...task,
              task_learner: {
                ...task.task_learner,
                id: task.id,
                tutor_review: submit.tutor_review,
                completion_stage: submit.completion_stage,
                learner_notification_status: submit.learner_notification_status
              }
            }
          }
        }
        return task
      })
      // console.log(newData)
      // setTasks(newData)
      newData.forEach((task) => {
        if (task.id === data.id) {
          if (task.task_learner?.learner_id) {
            apiService
              .tutorUpdateLearnerTask(task.task_learner.learner_id, data.id, task.task_learner)
              .then((res) => {
                if (res) {
                  setTasks(newData)
                  if(method === 'pass') {
                    if(reduceObj.numOfCompletedTasks < reduceObj.numOfTasks) {
                      reduceObj.numOfCompletedTasks += 1
                      setReduceObj(reduceObj)
                    }
                  } else if(method === 'reopen') {
                    if(reduceObj.numOfCompletedTasks !== 0) {
                      reduceObj.numOfCompletedTasks -= 1
                      setReduceObj(reduceObj)
                    }
                  }
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        }
      })
    }
  }

  useEffect(() => {
    // console.log(reduceObj)
    tasks?.forEach(task => {
      if(task.id === data.id) {
        setProgressHeight(reduceObj.numOfCompletedTasks / reduceObj.numOfTasks)
      }
    })
  }, [tasks])


  return (
    !!data.task_learner?.tutor_review ?
      (data.task_learner.tutor_review === 'review complete' ?
        <div>
          <IconButton text='Re-open Task' size='medium' variant='contained' onClick={()=>toggleButton('reopen')} />
        </div> 
      :
        <div>
          <span style={{ marginRight: '5px' }}>
            <IconButton text='Pass' size='medium' variant='contained' onClick={()=>toggleButton('pass')} />
          </span>
          <span style={{ marginLeft: '5px' }}>
            <IconButton text='Dissatisfied' size='medium' variant='contained' onClick={()=>toggleButton('dissatisfied')} />
          </span>
        </div>)
    : 
      <div>
        <span style={{ marginRight: '5px' }}>
          <IconButton text='review' size='medium' variant='contained' onClick={()=>toggleButton('review')} disable={data.task_learner?.completion_stage === 'amending' ? true : false} />
        </span>

        <HandleTaskDeadline 
          data={data} learnerId={learnerId!} due={due} timesheetId={timesheetId}
          apiLearnerTimesheet={apiLearnerTimesheet} 
          setApiLearnerTimesheet={setApiLearnerTimesheet}/>
      </div>
  )
}

export default HandleReviewTask