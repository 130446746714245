import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AppContext, AppContextProps } from './context/AppContext'
import { authTokenKey, getStorage, userKey } from './constants/auth'
import { getUserRole } from './constants/constants'
import { useObservable } from './util/useObservable'
import { apiInfoObservable } from './observables'
import Navbar from './layouts/navbar/Navbar'
import LoginPage from './pages/login-page/LogIn'
import DashboardPage from './pages/dashboard-page/DashBoard'
import ResourcesPage from './pages/resources-page/ResourcesPage'
import CoursesPage from './pages/courses-page/CoursesPage'
import TimesheetPage from './pages/timesheet-page/timesheetPage'
import './App.css'
import Notification from './components/notification/notification'
import { useState, useEffect } from 'react'
import Container from '@mui/material/Container'

function App() {
  const auth = getStorage(authTokenKey)
  const apiState = useObservable(apiInfoObservable().getObservable())
  const userRole = () => getUserRole(getStorage(userKey)?.role)

  const routes = [
    { path: '/Dashboard', component: <DashboardPage /> },
    { path: '/timesheet', component: <TimesheetPage /> },
    { path: '/Resources/*', component: <ResourcesPage /> },
    { path: '/Courses/*', component: <CoursesPage DashBoardTabValue={3} /> }
  ]

  const userRoleValue: AppContextProps = { userRole, currentUser: getStorage(userKey) || null }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })

  useEffect(() => {
    const hideNotification =() =>{
      if(apiState.errorMsg = 'Error 401: undefined'){
        return true
      } else {
        return false
      }
    }
    setNotify({
      isOpen: hideNotification()? false : apiState.error,
      message: apiState.errorMsg,
      type: "error",
    })
  }, [apiState])

  return (
    <AppContext.Provider value={userRoleValue}>
      <Router>
        <Navbar />
        <Container
          maxWidth={false}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            maxWidth: '1600px'
          }}
        >
          <Routes>
            <Route path='/login' element={auth ? <Navigate to='/Dashboard' /> : <LoginPage />} />
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={auth ? route.component : <Navigate to='/login' />}
              />
            ))}
            <Route
              path='*'
              element={auth ? <Navigate to='/Dashboard' /> : <Navigate to='/login' />}
            />
          </Routes>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </Router>
    </AppContext.Provider>
  )
}

export default App
