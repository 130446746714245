import React from 'react'
import { NavigateFunction, Link, useLocation, Location } from 'react-router-dom'
import { ResourceDialogProps, ResourcesResponse, Tag } from '../../interface'
import { courseTopicIdsProps } from '../../pages/courses-page/course-components/components/TopicResourcesList'
import { topicResourcesResponse } from '../../pages/courses-page/course-components/components/AdminTopicResources'
import { tagColor } from '../../pages/resources-page/ResourceCard'
import { useMatchScreenSize } from '../../pages/courses-page/course-components/components/TopicResourcesList'
import Delayed from '../../pages/courses-page/course-components/components/Delayed'
import Filter from '../../pages/resources-page/Filter'
import * as MUI from '../../MUI'
import TextButton from '../../components/button/TextButton'
import IconButton from '../../components/button/IconButton'
import {ThemeProvider} from '@mui/material/styles'
import theme from '../../pages/courses-page/course-components/components/theme'

type ManageTopicResourcesDialogProps = Pick<ResourceDialogProps, 'dialogOpen'> & {
  tagList: Tag[]
  handleDialogClose: () => void
  handleOnClick: (id: number, e: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: () => void
  allResources: topicResourcesResponse[]
  selectedResources: ResourcesResponse[]
  setSearchParams: Function
}

// const location = useLocation()

function getResourceTags (resource: ResourcesResponse) {
  const tags = resource.resource_tags?.map((tag) => {
    return (
      <span
        key={tag.id}
        className={`badge rounded-pill bg-${tagColor[tag.title]}`}
        style={{ marginRight: '3px' }}
      >
        {tag.title}
      </span>
    )
  })
  return tags
}

function resourceListItem (resource: ResourcesResponse) {
  return (
    <ThemeProvider theme={theme}>
      <MUI.ListItemAvatar sx={{ minWidth: 40 }}>
        <MUI.Avatar sx={{ width: 32, height: 32 }}>
          { resource.resource_videos?.length ?
            <MUI.VideoLibraryIcon /> : <MUI.TextSnippetIcon />
          }
        </MUI.Avatar>
      </MUI.ListItemAvatar>
      <MUI.ListItemText
        sx={{ 
          minHeight: '40px', 
          minWidth: '130px',
          transition: theme.transitions.create(['width'], {
            duration: theme.transitions.duration.enteringScreen
          }),
          [theme.breakpoints.down(430)]: {
            width: '130px',
          }
        }}
        primary={
          <div
            style={{
              whiteSpace: 'nowrap',
              height: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {resource.title}
          </div>
        }
        secondary={<MUI.Typography style={{ whiteSpace: 'nowrap', overflowX:'auto' }}>{getResourceTags(resource)}</MUI.Typography>}
      />
    </ThemeProvider>
  )}

export function getSelectedResourcesList (resource: ResourcesResponse, dialogOpen?: boolean, resourceDialogOpen?: (id: number)=>void, matchSmSize?: boolean, matchAboveLgSize?:boolean, ids?: courseTopicIdsProps, navigate?: NavigateFunction, location?: Location) {

  return (
    <MUI.ListItem
      key={resource.id}
      style={{
        marginBottom: '5px',
        backgroundColor: 'rgb(245,245,245, 1)',
        ...(matchAboveLgSize && { paddingRight: '24px' })
      }}
      secondaryAction={
        !dialogOpen ? (
            <Link to={`/Resources/${resource.id}`} state={{prevPathName: location?.pathname}}>
              <Delayed>
                <MUI.IconButton
                  onClick={()=> {
                    const courseTopicIds ={
                      courseId: ids?.courseId,
                      topicId: ids?.topicId
                    }
                    sessionStorage.setItem('courseTopicIds', JSON.stringify(courseTopicIds))
                    window.sessionStorage.setItem('scrollPosition', JSON.stringify(window.scrollY))
                    // !!navigate && navigate(`/Resources/${resource.id}`)
                  }}
                >
                  <MUI.OpenInNewIcon />
                </MUI.IconButton>
              </Delayed>
            </Link>
        ) : null
      }
    >
      {!dialogOpen && !matchSmSize ? (
        <MUI.ListItemButton
          disableRipple
          disableGutters
          dense
          style={{ backgroundColor: '#f5f5f5' }}
          onClick={() => resourceDialogOpen?.(resource.id)}
        >
          {resourceListItem(resource)}
        </MUI.ListItemButton>
      ) : (
        resourceListItem(resource)
      )}
    </MUI.ListItem>
  )
}

const ManageTopicResourcesDialog = (props: ManageTopicResourcesDialogProps) => {
  const {dialogOpen, handleDialogClose, allResources, handleOnClick, selectedResources, handleSubmit, tagList, setSearchParams} = props

  const [ matchesSM ] = useMatchScreenSize()

  const handleResourcesListAction = () => {
    return (
      <MUI.DialogActions
        style={{ 
          // backgroundColor: 'rgb(207,214,227, 0.5)', 
          width: '100%'
        }}
      >
        <TextButton onClick={() => handleDialogClose()} text='Cancel'/>
        <TextButton onClick={() => handleSubmit()} text='Done'/>
      </MUI.DialogActions>
    )
  }

  const getResourcesCard = (resource: topicResourcesResponse) => {
    return (
      <MUI.Card
        key={resource.id}
        variant='outlined'
        style={{
          margin: '2px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <MUI.CardContent>
          <MUI.Typography
            component='div'
            style={{ height: 10, overflow: 'visible', fontWeight: '500' }}
          >
            {resource.title}
          </MUI.Typography>
        </MUI.CardContent>
        <MUI.CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '5px'
          }}
        >
          <MUI.Typography sx={{ alignSelf: 'center' }}>{getResourceTags(resource)}</MUI.Typography>
          <MUI.CardActions>
            {/* <MUI.Button
              size='small'
              color={resource.selected ? 'error' : 'primary'}
              onClick={() => handleOnClick(resource.id)}
            >
              {resource.selected ? <>remove</> : <>select</>}
            </MUI.Button> */}
            <MUI.Switch
              color='warning'
              checked={resource.selected}
              onChange={(e) => handleOnClick(resource.id, e)}
            />
          </MUI.CardActions>
        </MUI.CardContent>
      </MUI.Card>
    )
  }

  return (
    <MUI.Dialog 
      open={dialogOpen} 
      maxWidth='lg' 
      fullWidth
    >
      <MUI.Grid container spacing={1} columns={12} style={{ height: '100vh' }}>
        <MUI.Grid container item sm={6} md={6}>
          <MUI.DialogContent>
            <MUI.DialogTitle>Selected Resources</MUI.DialogTitle>
            <MUI.List
              sx={{
                width: '100%',
                height: '77vh',
                ...(matchesSM && {height: '25vh'}),
                overflowY: 'auto'
              }}
            >
              {!selectedResources.length ? (
                <MUI.ListItem>No item</MUI.ListItem>
              ) : (
                selectedResources.map((resource) => {
                  return getSelectedResourcesList(resource, dialogOpen)
                })
              )}
            </MUI.List>
            {handleResourcesListAction()}
          </MUI.DialogContent>
        </MUI.Grid>

        <MUI.Grid container item sm={6} md={6}>
          <MUI.DialogContent
            style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', paddingTop: 15 }}
          >
            <MUI.Box style={{ width: '100%', display: 'flex' }}>
            {/* <MUI.DialogTitle style={{padding: '16px'}}>All Resources</MUI.DialogTitle> */}
                <Filter tagList={tagList} setSearchParams={setSearchParams} />
            </MUI.Box>
            <MUI.Grid
              container
              item
              style={{
                // display: 'flex',
                // flexWrap: 'wrap',
                height: '77vh',
                overflowY: 'auto',
                alignContent: 'flex-start',
                // flexGrow: 1
              }}
            >
              {allResources.map((resource, index) => {
                if (resource.title !== null)
                  return (
                    index % 2 === 0 ? 
                      <MUI.Grid key={index} item xs={12} sm={12} md={6}>
                        {getResourcesCard(resource)}
                      </MUI.Grid>
                      : 
                      <MUI.Grid key={index} item xs={12} sm={12} md={6}>
                        {getResourcesCard(resource)}
                      </MUI.Grid>
                  )
              })}
            </MUI.Grid>
          </MUI.DialogContent>
        </MUI.Grid>
      </MUI.Grid>
    </MUI.Dialog>
  )
}

export default ManageTopicResourcesDialog