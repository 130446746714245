import { useState } from 'react'
import { ICourseResponse, IMenuProps } from '../../../../interface'
import ActionMenu from "../../../../components/menu/ActionMenu" 
import AdminCourse from "./AdminCourse"
import AdminCourseTopic from "./AdminCourseTopic"
import * as MUI from '../../../../MUI'

interface CourseMenuProps {
  data: ICourseResponse
  courseId: number
}

const CourseMenu = (props: CourseMenuProps) => {
  const { data, courseId } = props
  const [topicOpen, setTopicOpen] = useState<boolean>(false)

  const config: IMenuProps = {
    method: {
      buttonId: 'course-menu-button',
      menuId: 'course-menu'
    },
    toggleMenuButton: <MUI.SettingsIcon />,
    childrenMenuItem: [
      {
        component: (
          <AdminCourseTopic 
            method='add topic' 
            courseId={courseId} 
          >
            Add Topic
          </AdminCourseTopic>
        ),
        // onClickMenuItem() {
        //   setTopicOpen(true)
        //   console.log('onClickMenuItem', {topicOpen})
        // },
      },
      {
        component: (
          <AdminCourse method='edit course' data={data}>
            Edit Course
          </AdminCourse>
        )
      },
      {
        component:(
          <AdminCourse method='delete course' data={data}>
            Delete Course
          </AdminCourse>
        )
      }
    ],
    toggleMenuBtnStyle: {
      marginTop: '0px'
    },
    taskMenuListProps: 'toggle-course-menu-button'
  }

  return (
      <ActionMenu config={config} />
  )
}

export default CourseMenu