import React from 'react'
import ResourceDetail from '../../pages/resource-detail-page/ResourceDetail'
import * as MUI from '../../MUI'

interface ResourceDetailPageDialogProps {
  dialogOpen: boolean
  resourceId?: number
  onCloseDialog: () => void
}

const ResourceDetailPageDialog = (props: ResourceDetailPageDialogProps) => {
  const { dialogOpen, onCloseDialog, resourceId } = props

  return (
    <>
      <MUI.Dialog open={dialogOpen} maxWidth='xl' fullWidth>
        <MUI.DialogTitle
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'rgb(245,245,245,0.8)',
            boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
            zIndex: '1'
          }}
        >
          <MUI.IconButton onClick={() => onCloseDialog()}>
            <MUI.CloseIcon />
          </MUI.IconButton>
        </MUI.DialogTitle>
        <MUI.DialogContent style={{ height: '100vh' }}>
          <ResourceDetail resourceId={resourceId} resourceDetailDialogOpen={dialogOpen} />
        </MUI.DialogContent>
      </MUI.Dialog>
    </>
  )
}

export default ResourceDetailPageDialog