import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { IMenuChildrenItemProps, IMenuProps } from '../../interface'
import * as MUI from '../../MUI'

interface ActionMenuProps {
  config: IMenuProps
}

const ActionMenu = ({ config }: ActionMenuProps) => {
  const menu = config.childrenMenuItem
  const { userRole } = useContext(AppContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const handleScroll = (event: Event) => {
      handleClose()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <div style={{paddingTop:'15px',textAlign:'center',maxWidth: 80}}>
      <MUI.IconButton
        id={config.method?.buttonId}
        onClick={handleClick}
        aria-controls={openMenu ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={openMenu ? 'true' : undefined}
        color='default'
        style={config.toggleMenuBtnStyle}
        disableRipple={config.method.buttonId === 'user-profile-button' ? true : false}
        size='small'
      >
        <span style={{display:'block',verticalAlign:'middle', maxWidth:'50px'}}>
        <span style={{display:'block',margin:'0 10px'}}>{config.toggleMenuButton}</span>
        <p style={{color:'black',fontSize:'10px',overflow: 'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap',marginBottom:'15px'}}>{config.firstName}</p>
        </span>
      </MUI.IconButton>

      </div>
      <MUI.Menu
        id={config.method?.menuId}
        anchorEl={anchorEl}
        open={openMenu}
        keepMounted
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': config.taskMenuListProps
        }}
        PaperProps={{
          style: config.menuStyle
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        disableScrollLock={true}
      >
        { menu.filter((data) => {
                if(userRole() !== 'Admin User') {
                  return  data.component.key !== 'create-new-user'
                } else {
                  return true
                }
              }).map((menuItem: IMenuChildrenItemProps, index: number) => {
                return (
                  <div key={index}>
                    <MUI.MenuItem
                      disableGutters={true}
                      sx={{
                        padding: 0,
                        '&.Mui-disabled': {
                          opacity: '1'
                        }
                      }}
                      disabled={
                        index === 0 && config.method.menuId === 'user-profile-menu' ? true : false
                      }
                      // onClick={(e)=>{
                      //   menuItem.onClickMenuItem &&
                      //   menuItem.onClickMenuItem()
                      // }}
                      onClick={()=>handleClose()}
                    >
                      {menuItem.component}
                    </MUI.MenuItem>
                    {index === 0 && config.method.menuId === 'user-profile-menu' && <MUI.Divider />}
                  </div>
                )
              })}
      </MUI.Menu>
    </>
  )
}

export default ActionMenu
