import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../../context/AppContext'
import apiService from '../../../services/api-services'
import TutorNotificationMenu from './tutor-notification-menu'
import LearnerNotificationMenu from './learner-notification-menu'
import * as MUI from '../../../MUI'

interface NotificationMenuProps {
  anchorEl: HTMLElement | null
  open: boolean
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  notificationItems: Array<any>
  setNotifications: React.Dispatch<React.SetStateAction<any[]>>
  numOfNotifications: number
}

const NotificationMenu = (props: NotificationMenuProps) => {
  const { anchorEl, setAnchorEl , open, notificationItems, setNotifications, numOfNotifications } = props
  const { userRole } = useContext(AppContext)

  const handleCloseNotificationMenu = () => {
    setAnchorEl(null)
  } 

  const handleClickOnItem = (taskId: number, learnerId: number) => {
    // try{
    //   apiService
    //     .removeTutorNotification(learnerId, taskId, { tutor_notification_status: 'null' })
    //     .then((res) => {
    //       // console.log('res', res)
    //       if (res) {
            let newArray: any = [] 
            if(userRole() === 'Admin User' || userRole() === 'Normal User') {
              try{
                apiService
                  .removeTutorNotification(learnerId, taskId, { tutor_notification_status: 'null' })
                  .then((res) => {
                    // console.log('res', res)
                    if (res) {
                      newArray = notificationItems.map((item) => {
                        if (item.task_id === taskId && item.learner_id === learnerId) {
                          return {
                            ...item,
                            tutor_notification_status: null
                          }
                        }
                        return item
                      })
                      setNotifications(newArray)
                    }
                  })
              } catch (err) {
                console.log(err)
              }
            }  else if(userRole() === 'Others') {
              try{
                apiService.removeLearnerNotification(taskId, {learner_notification_status: 'null'}).then(res => {
                  console.log('call remove learner notification', res);
                  
                  if(res) {
                    newArray = notificationItems.map(item => {
                      if (item.task_id === taskId && item.learner_id === learnerId) {
                        return {
                          ...item,
                          learner_notification_status: null
                        }
                      }
                      return item
                    })
                    setNotifications(newArray)
                  }
                })
              } catch (err) {
                console.log(err)
              }
            }
            // console.log(newArray)

        //   }
        // })
    // } catch (err) {
    //   console.log(err)
    // }
  }

  return (
    <MUI.Menu
      anchorEl={anchorEl}
      open={numOfNotifications ? open : false}
      onClose={handleCloseNotificationMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {(userRole() === 'Admin User' || userRole() === 'Normal User') && 
        <TutorNotificationMenu 
          tutorNotificationsItems={notificationItems} 
          handleClickOnItem={handleClickOnItem}
          handleCloseNotificationMenu={handleCloseNotificationMenu}
        />
      }

      {userRole() === 'Others' && 
        <LearnerNotificationMenu 
          learnerNotificationsItems={notificationItems}
          handleClickOnItem={handleClickOnItem}
          handleCloseNotificationMenu={handleCloseNotificationMenu}
        />
      }
    </MUI.Menu>
  )
}

export default NotificationMenu