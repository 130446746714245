import { useEffect, useState } from 'react'
import { learnerCoursesStatusProps } from '../../CoursesPage'
import "./Progress.css"
import * as MUI from '../../../../MUI'
import Fade from '@mui/material/Fade'

interface ProgressProps {
  open: boolean
  isLast: boolean
  height?: number
  showProgressBar: boolean
  completion?: boolean
  learnerCoursesStatus: learnerCoursesStatusProps[]
}

const Progress = (props: ProgressProps) => {
  const { open, isLast, height, showProgressBar, completion, learnerCoursesStatus } = props  

  const [courseCompleted, setCourseCompleted] = useState<boolean>(false)

  useEffect(() => {
    const courseNotCompletionFound = learnerCoursesStatus.find(value => value.course_completion === false)
    
    if(courseNotCompletionFound) {
      setCourseCompleted(false)
    } else {
      setCourseCompleted(true)
    }
  }, [learnerCoursesStatus])

  return (
    <Fade in={showProgressBar}>
      <MUI.Box className='p-bar-container'>
        {/* <div className={isLast ? "l-bar-base" : open ? "p-bar-base" : "c-bar-base"} > */}
        <MUI.Box className={open ? 'p-bar-base' : 'c-bar-base'}>
          <MUI.Box
            // className={open ? 'p-bar-active' : 'c-bar-active'}
            className={'p-bar-active'}
            style={{ height: height ? `${height * 100}%` : 0 }}
          >
          </MUI.Box>
        </MUI.Box>
          {isLast && (
            <MUI.Box 
              style={{ 
                marginTop: 20, 
                marginLeft: -2, 
                opacity: open ? 0 : 1,
                transition: 'opacity 1.5s',
              }}
            >
              <MUI.AssignmentTurnedInIcon color={courseCompleted ? 'primary' : 'disabled'} />
            </MUI.Box>
          )}
      </MUI.Box>
    </Fade>
  )
}
export default Progress
