import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { services } from '../../services/services'
import apiService from '../../services/api-services'
import { ResourceResponse, ResourcesResponse, ResourceTag, ResourceVideo, Tag } from '../../interface'
import CommonSkeleton from '../../components/skeleton/CommonSkeleton'
import './ResourceStyles.css'
import Videos from './Videos'
import * as MUI from '../../MUI'
import { type } from '@testing-library/user-event/dist/type'
import IconButton from '../../components/button/IconButton'
import ResourcesControls from '../resources-page/ResourcesControls'
import ResourceDialog from '../../dialogs/resource-dialog/ResourceDialog'

type ResourseDetail = {
  id: number
  user_id: number
  title: string
  description: string
  content: string
  is_active: number
  external_link: string
  slug?: string
  created_at?: string
  updated_at?: string
  resource_tags: ResourceTag[]
  resource_videos: ResourceVideo[]
}

type Detail = Pick<ResourseDetail, 'title' | 'description' | 'content' | 'resource_videos'>

type VideoItem = {
  id: number
  title: string
  description: string
  link_url: string
}

type IdParams = {
  id: string
}

type ResourceDetailProps = {
  resourceId?: number
  resourceDetailDialogOpen?: boolean
}

interface LocationState {
  prevPathName: string
}

/*
 * Resouce Detail Page Component
 */
const ResourceDetail = (props: ResourceDetailProps) => {
  const { resourceId, resourceDetailDialogOpen = false } = props
  const [isResourceLoading, setIsResourceLoading] = useState<boolean>(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dataEdit, setDataEdit] = useState<any>(null)
  const [isNewCard, setIsNewCard] = useState<boolean>(false)
  const [tagList, setTagList] = useState<Tag[]>([])
  // const [resource, setResource] = useState<ResourseDetail>({
  //   title: '',
  //   description: '',
  //   content: '',
  //   resource_videos: []
  // })
  // const { title, description, resource_videos, content }: ResourseDetail  = resource

  const [resource, setResource] = useState<ResourseDetail[]>([
    {
      id: 0,
      user_id: 0,
      is_active: 0,
      external_link: '',
      slug: '',
      created_at: '',
      updated_at: '',
      resource_tags: [],
      title: '',
      description: '',
      content: '',
      resource_videos: []
    }
  ])

  const [detail, setDetail] = useState<Detail>({
    title: '',
    description: '',
    content: '',
    resource_videos: []
  })
  // const [dataEdit, setDataEdit] = useState<boolean>(false)
  const location = useLocation()
  const { prevPathName } = (location.state as LocationState) || ''
  const navigate = useNavigate()
  const { id } = useParams<keyof IdParams>() as IdParams
  const ApiId = parseInt(id)

  const getResourceDetail = async (id: number) => {
    apiService
      .getResources(id)
      .then((res) => {
        setResource(res)
        setDetail(res[0])
        setIsResourceLoading(false)
        console.log(detail)
      })
      .catch((err) => console.log(err))
  }

  const parse = (data: Detail) => {
    data.description === null && (data.description = '')
    data.content === null && (data.content = '')
    return data
  }

  useEffect(() => {
    apiService.getConfig().then((res) => setTagList(res.tags))
  },[])
  
  useEffect(() => {
    if (!!ApiId) {
      getResourceDetail(ApiId)
    }

    if (!!resourceId) {
      getResourceDetail(resourceId)
    }

    // window.scrollTo(0, 0)
    // if (!dataEdit) return
    // console.log(resource[0].title)
  }, [id, resourceId])
  // const parse = (data: ResourceResponse[]) => {
  //   data[0].description === null && (data[0].description = "")
  //   data[0].content === null && (data[0].content = "")
  //   return data
  // }

  const toggleDialog = () => setDialogOpen(!dialogOpen)

  const handleChange = () => {
    toggleDialog()
    setDataEdit(detail)
  }

  return isResourceLoading ? (
    <div style={{ paddingTop: 50, height: '100vh' }}>
      <CommonSkeleton />
    </div>
  ) : (
    <div style={location.pathname.includes('Resources') ? { padding: '0' } : { padding: '30px 0' }}>
      {!resourceDetailDialogOpen && (
        <div className="d-flex justify-content-between">
          <IconButton
            onClick={() => navigate(-1)}
            text={(sessionStorage.getItem('Tab') === 'Courses' || prevPathName === '/Courses') ? 'Courses' : 'Resources'}
            color='info'
            icon={<MUI.ArrowBackIcon />}
            className='mb-2'
            sx={{paddingLeft:0}}
          />
          {JSON.parse(localStorage.getItem('USERKEY')!).role !== 3 && 
            <IconButton icon={<MUI.NoteAltOutlinedIcon />} color='info' className='mb-2' onClick={handleChange}/>
          }
        </div>
      )}
      <MUI.Box sx={{ minWidth: 275 }}>
        {/* Bsic Info */}
        <MUI.Card>
          <MUI.CardContent>
            <MUI.Typography gutterBottom variant='h4' component='div'>
              {detail.title}
            </MUI.Typography>
            <MUI.Typography gutterBottom color='text.secondary'>
              {detail.description}
            </MUI.Typography>
          </MUI.CardContent>
          <MUI.CardActions></MUI.CardActions>
        </MUI.Card>

        {/* Content */}
        {detail.content && (
          <MUI.Card sx={{ marginTop: 3, marginBottom: 3 }}>
            <MUI.CardContent>
              <section id='Resource-Content'>
                <MUI.Typography
                  gutterBottom
                  dangerouslySetInnerHTML={{ __html: detail.content }}
                ></MUI.Typography>
              </section>
            </MUI.CardContent>
          </MUI.Card>
        )}

        {/* Vidoes */}
        {detail.resource_videos.length > 0 && (
          <MUI.Card sx={{ marginTop: 3, marginBottom: 3 }}>
            <MUI.CardContent>
              <section id='Videos'>
                <MUI.Typography gutterBottom variant='h5' component='div' className='mb-3'>
                  Videos
                </MUI.Typography>
                <Videos videos={detail.resource_videos} />
              </section>
            </MUI.CardContent>
          </MUI.Card>
        )}
      </MUI.Box>
      <ResourceDialog
        dialogOpen={dialogOpen}
        toggleDialog={toggleDialog}
        tagList={tagList}
        setData={setDetail}
        dataEdit={dataEdit}
        setDataEdit={setDataEdit}
        setDialogOpen={setDialogOpen}
        isNewCard={isNewCard}
        toggleNew={() => null}
        setIsNewCard={setIsNewCard}
      />
    </div>
  )
}

// Message for no video resources
/* 
  // <MUI.Typography variant="body2">
  //   <span style={{color: 'rgba(0,0,0,.55)'}}>
  //     <i>This resource has no video</i>
  //   </span>
  // </MUI.Typography>
*/
export default ResourceDetail
