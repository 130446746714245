import { useState, useEffect, useContext } from "react"
import { CourseContext } from "../context/CourseContext"
import FormDialog from "../../../../dialogs/form-dialog/FormDialog"
import apiService from "../../../../services/api-services"
import { ITextFieldProps, ICourseResponse } from "../../../../interface"
import * as MUI from "../../../../MUI"
import IconButton from "../../../../components/button/IconButton"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextButton from "../../../../components/button/TextButton"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useObservable } from "../../../../util/useObservable"
import { apiInfoObservable } from "../../../../observables"
// import { Label } from "@mui/icons-material"

interface AdminCourseData extends ICourseResponse {
  [key: string]: any
}

interface AdminCourseProps {
  method: "add course" | "edit course" | "delete course"
  data?: AdminCourseData
  children?: React.ReactNode
}

interface CourseFormData extends Pick<ICourseResponse, "title" | "description" | "comments" | "is_active" | "course_code"> {
  [key: string]: any
}

const AdminCourse = (props: AdminCourseProps) => {
  const { method, data, children } = props
  const { apiCoursesData, setApiCoursesData, setIsCourseLoading, setSubmitCourseTopicId, submitCourseTopicId } = useContext(CourseContext)

  const apiState = useObservable(apiInfoObservable().getObservable())

  const initFormValue: CourseFormData = {
    title: "",
    description: "",
    comments: "",
    course_code:0,
    is_active: '',
  }

  const [courseFormData, setCourseFormData] = useState<CourseFormData>(initFormValue)
  const [courseIsOpen, setCourseIsOpen] = useState(false)

  useEffect(() => {
    if (method === "edit course" && !!data) {
      for (let k in data) {
        if (courseFormData.hasOwnProperty(k)) {
          courseFormData[k] = data[k]
        }
      }
    }
  }, [courseIsOpen])

  const handleCourseOpen = () => {
    console.log("handleOpen" + method)
    setCourseIsOpen(true)
  }

  const handleCourseClose = () => {
    console.log("handleClose")
    setCourseIsOpen(false)
    setCourseFormData(initFormValue)
  }

  const handleCourseSubmit = () => {
    console.log("onSubmit")
    let submitItem = {
      course_id: 0,
    }
    setIsCourseLoading(true)
    if (method === "add course") {
      apiService.addAdminCourse(courseFormData).then((res) => {
        console.log('Show Add Course Data', res)
        apiCoursesData.push({...res, course_topic: []})
        setApiCoursesData(apiCoursesData)
        setCourseIsOpen(false)
        setIsCourseLoading(false)
        setSubmitCourseTopicId({
          ...submitCourseTopicId,
          courseId: res.id
        })
      })
      setCourseFormData(initFormValue)
    }
    if (method === "edit course" && data) {
      apiService.editAdminCourse(data.id, courseFormData).then((res) => {
        //will return true or false
        if (res) {
          console.log('Edit course',apiState)
          const newData: ICourseResponse[] = apiCoursesData.map(
            (el: ICourseResponse) =>{
                if(el.id === data.id) {
                  submitItem.course_id = el.id
                  return {
                    ...el,
                    comments: courseFormData.comments,
                    description: courseFormData.description,
                    is_active: courseFormData.is_active,
                    title: courseFormData.title,
                  }
                }
                return el
              })
          setApiCoursesData(newData)
          setCourseIsOpen(false)
          setIsCourseLoading(false)
          setSubmitCourseTopicId({
            ...submitCourseTopicId,
            courseId: submitItem.course_id,
          })
        }
        // setCourseFormData(initFormValue)
      })
    }
  }

  const [deleteDialogOpen,setDeleteDialogOpen] = useState<boolean>(false)
  const [deleteID, setDeleteID] = useState<number>(0)

  const deleteDialog = (id:number) =>{
    setDeleteID(id)
    setDeleteDialogOpen(true)
  }

  const handleDelete = (id:number) =>{
    setDeleteDialogOpen(false)
    apiService.deleteAdminCourse(id).then((res) => {
      if(res){
        apiService.getAllCourses().then((res) => {
          setApiCoursesData(res)
        })
      }
    })
  }

  const courseForm: ITextFieldProps[] = [
    {
      autoFocus: true,
      margin: "dense",
      id: "title",
      label: "Course Title",
      type: "title",
      fullWidth: true,
      select: false,
      variant: "outlined",
      onChange: (e: string) => (courseFormData.title = e),
      className: "",
      value: courseFormData.title,
      required: true,
    },
    {
      autoFocus: false,
      margin: "dense",
      id: "course_code",
      label: "Course Code",
      type: "number",
      fullWidth: true,
      select: false,
      variant: "outlined",
      onChange: (e: number) => (courseFormData.course_code = e),
      className: "",
      value: courseFormData.course_code,
      required: true,
    },
    {
      autoFocus: false,
      margin: "dense",
      id: "description",
      label: "Description",
      type: "description",
      fullWidth: true,
      select: false,
      variant: "outlined",
      onChange: (e: string) => (courseFormData.description = e),
      className: "",
      value: courseFormData.description,
      required: false,
    },
    {
      autoFocus: false,
      margin: "dense",
      id: "comments",
      label: "Comments",
      type: "comments",
      fullWidth: true,
      select: false,
      variant: "outlined",
      onChange: (e: string) => (courseFormData.comments = e),
      className: "",
      value: courseFormData.comments,
      required: false,
    },
    {
      autoFocus: false,
      margin: "dense",
      id: "is_active",
      label: "Condition",
      type: "is_active",
      fullWidth: true,
      select: true,
      variant: "outlined",
      onChange: (e: 0 | 1 ) => (courseFormData.is_active = e),
      className: "",
      value: courseFormData.is_active,
      required: true,
    },
  ]
  
  return (
    <>
      {method === 'add course' && (
        <MUI.Tooltip title={method}>
          <>
            <IconButton
              color = 'inherit'
              onClick={handleCourseOpen}
              icon={<AddOutlinedIcon fontSize='medium' />}
            />
          </>
        </MUI.Tooltip>
      )}
      {method === 'edit course' && (
          <MUI.IconButton
            sx={{ width: '100%', justifyContent: 'flex-start', borderRadius: 0 }}
            onClick={handleCourseOpen}
            disableRipple
          >
            {method === 'edit course' && <MUI.EditIcon fontSize='medium' />}
            <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
          </MUI.IconButton>
      )}
      {method === 'delete course' && (
          <MUI.IconButton
            sx={{ width: '100%', justifyContent: 'flex-start', borderRadius: 0 }}
            onClick={()=> deleteDialog(data!.id)}
            disableRipple
          >
            {method === 'delete course' && <MUI.DeleteIcon fontSize='medium' />}
            <div style={{ fontSize: '16px', color: 'black', paddingLeft: '10px' }}>{children}</div>
          </MUI.IconButton>
      )}
      <FormDialog
        isOpen={courseIsOpen}
        handleClose={handleCourseClose}
        children={courseForm}
        handleSubmit={handleCourseSubmit}
        method={method}
      />
    {/* Delete Dialog */}
    <Dialog open = {deleteDialogOpen}>
        <DialogTitle>
            Are you sure to delete this course?
        </DialogTitle>
        <DialogContent>
            This action can't be reversed!
        </DialogContent>
        <DialogActions>
          <TextButton onClick={()=>setDeleteDialogOpen(false)} text='Cancel'/>
          <TextButton onClick={()=>handleDelete(deleteID)} text='Delete' color="error"/>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AdminCourse
