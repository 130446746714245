import React, { Fragment, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { AppBlockingTwoTone } from '@mui/icons-material'
import { services } from '../../services'
import { ITagResponse, ITagFormData, User } from '../../interface'
import apiService from '../../services/api-services'
import AddConfig from './config-components/AddConfig'
import DeleteDialog from '../../dialogs/delete-dialog/delete-dialog'
import EditableRow from './config-components/EditableRow'
import { Typography } from '@mui/material'

type ConfigPageProps = {
  learnerId?: number
  learner?: User
  DashBoardTabValue: number
}

interface TagFormData {
  tagId: number
  title: string
}

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value
    })
  )
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}))

export default function ConfigPage(props: ConfigPageProps) {
  const [tags, setTags] = useState<ITagResponse[]>([])
  const [onAddRow, setOnAddRow] = useState(false)
  const [open, setOpen] = useState(false)
  const [tagId, setTagId] = useState()
  const [editTagId, setEditTagId] = useState(null)
  const [editTagData, setEditTagData] = useState<TagFormData>({ tagId: 0, title: '' })

  const addTableRow = () => setOnAddRow(!onAddRow)

  const getTags = async () => {
    const tags = await apiService.getTags()
    setTags(tags)
  }

  const handleDeleteClick = (tagId: any) => {
    apiService.deleteTag({ tagId: tagId }).then((res) => {
      let updateTags = tags.filter((tag) => tag.id !== tagId)
      setTags(updateTags)
      handleClose()
    })
  }

  const handleClickOpen = (tagId: any) => {
    setOpen(true)
    setTagId(tagId)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEditClick = (tag: any) => {
    setEditTagId(tag.id)

    const tagFormValue: TagFormData = {
      tagId: tag.id,
      title: tag.title
    }
    setEditTagData(tagFormValue)
  }

  const handleEditTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = e.target.value
    setEditTagData((prevState) => ({
      ...prevState,
      [e.target.name]: fieldValue
    }))
  }

  const handleEditTagSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const editedTag: ITagFormData = {
      tagId: editTagData.tagId,
      title: editTagData.title
    }

    apiService.editTag(editedTag).then((res) => {
      const newTags = [...tags]
      const index = tags.findIndex((tag) => tag.id === editTagData.tagId)
      newTags[index].title = editedTag.title

      setTags(newTags)
      setEditTagId(null)
    })
  }

  useEffect(() => {
    getTags()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752, margin: 'auto', padding: '16px 0 5px 150px' }}>
        <Typography sx={{ marginRight: 3, display:'inline-block' }}>Resource Tags</Typography>
        <IconButton aria-label='add' onClick={addTableRow}>
          <AddCircleIcon />
        </IconButton>
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <Demo>
            <List>
              {onAddRow && <AddConfig tags={tags} setTags={setTags} setOnAddRow={setOnAddRow} />}
              {tags?.map((tag: any, index: number) => {
                return (
                  <Fragment key={tag.id}>
                    {editTagId == tag.id ? (
                      <EditableRow
                        tags={tags}
                        setEditTagId={setEditTagId}
                        handleEditTagChange={handleEditTagChange}
                        editTagData={editTagData}
                        handleEditTagSubmit={handleEditTagSubmit}
                      />
                    ) : (
                      <div>
                        <ListItem
                          secondaryAction={
                            <>
                              <IconButton
                                edge='end'
                                aria-label='Edit'
                                sx={{ marginRight: 0.5 }}
                                onClick={() => handleEditClick(tag)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => handleClickOpen(tag.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          }
                        >
                          <ListItemText primary={tag.title} />
                        </ListItem>
                      </div>
                    )}
                  </Fragment>
                )
              })}
            </List>
          </Demo>
          <DeleteDialog
            open={open}
            closeDialog={handleClose}
            handleDelete={() => handleDeleteClick(tagId)}
            itemId={tagId}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
