import { useState, useEffect, useRef } from 'react'
import * as MUI from '../../MUI'
import { parseVideoUrl } from '../../util/paresVideoUrl'
import ConfirmPopup from '../../components/confirm-dialog/confirm-popup'
import { services } from '../../services/services'
import { authHeader } from '../../constants/auth'
import Notification from '../../components/notification/notification'
import { VideoFormProps, TabPanelProps, Notify } from '../../interface/index'
import './ResourceDialogStyles.css'
import IconButton from '../../components/button/IconButton'
import apiService from '../../services/api-services'

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`video-tabpanel-${index}`}
      aria-labelledby={`video-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MUI.Box sx={{ p: 3 }}>
          <div>{children}</div>
        </MUI.Box>
      )}
    </div>
  )
}

function allyProps(index: number) {
  return {
    id: `video-tab-${index}`,
    'aria-controls': `video-tabpanel-${index}`
  }
}

const defaultVideo = {
  title: '',
  description: '',
  link_url: ''
}

const VideoForm = ({
  videoList,
  setVideoList,
  dialogOpen,
  setAlertDisplay,
  setVideoEdit,
  setInitVideoList
}: VideoFormProps) => {
  const [value, setValue] = useState<number | boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [isAdd, setIsAdd] = useState(false)
  const [notify, setNotify] = useState<Notify>({
    isOpen: false,
    message: '',
    type: 'error'
  })

  useEffect(() => (dialogOpen ? setValue(0) : setValue(false)), [dialogOpen])  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)

  const onAdd = () => {
    setIsAdd(true)
    setVideoEdit(true)
    setVideoList([...videoList, defaultVideo])
    setValue(videoList.length ? videoList.length : 0)
  }

  const updateVideoForm = (value: any, index: number, field: string) => {
    const newVideoList = [...videoList]
    newVideoList[index] = { ...newVideoList[index], [field]: value }
    setVideoList(newVideoList)
    setVideoEdit(true)
  }

  /* Open confirm popup */
  const onConfirmPopup = () => {
    setOpen(true)
  }

  /* Confirm clicked */
  const onConfirm = (id: number) => {
    apiService
      .deleteResourceVideo(id)
      .then((res) => {
        if (res) {
          setNotify({
            isOpen: true,
            message: 'Delete success!',
            type: 'success'
          })
          let afterDelete = videoList.filter((item) => item.id !== id)
          setValue(afterDelete.length - 1)
          setInitVideoList([...afterDelete])
          setVideoList([...afterDelete])
        }
      })
      .catch((error) =>
        setNotify({
          isOpen: true,
          message: 'Error! Refer to devtool.',
          type: 'error'
        })
      )
  }

  /* Close new tab */
  const handleCloseTab = (id: number) => {
    setIsAdd(false)
    setValue(id - 1)
    videoList.splice(id, 1)
    setVideoList([...videoList])
  }

  /* Video URL check */
  const isValidURL = (url: string) => {
    return url.includes('https://www.youtube.com') || url.includes('https://youtu.be')
      ? true
      : false
  }

  //save reference for dragItem and dragOverItem
  const dragItem = useRef<any>(null)
  const dragOverItem = useRef<any>(null)

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let newArr = [...videoList]

    //remove and save the dragged item content
    const draggedItemContent = newArr.splice(dragItem.current, 1)[0]

    //switch the position
    newArr.splice(dragOverItem.current, 0, draggedItemContent)

    //reset the position ref
    dragItem.current = null
    dragOverItem.current = null

    newArr.forEach((item: any, index: number) => {
      item.order = index + 1
    })

    //update the actual array
    setVideoList(newArr)
  }

  return (
    <>
      <MUI.Box sx={{ height:85, marginTop:6.5, borderBottom: 1, borderColor: 'divider' }} className='video-tabs'>
      <MUI.Tabs
          value={value}
          onChange={handleChange}
          aria-label='Video Form Tabs'
          className='video-tab'
        >
          {videoList.map((video: any, index) => (
            <MUI.Tab
              key={index}
              draggable
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
              {...allyProps(index)}
              label={
                <span>
                  {`Video #${index + 1}`}
                  {!!video.id ? (
                    ''
                  ) : (
                    <IconButton
                      component='div'
                      onClick={() => handleCloseTab(index)}
                      size='small'
                      sx={{
                        padding: 0,
                        fontSize: 5
                      }}
                      icon={<MUI.CloseIcon color='warning' />}
                    />
                  )}
                </span>
              }
            />
          ))}
        </MUI.Tabs>
        <IconButton
          onClick={onAdd}
          color='primary'
          className='video-add'
          icon={<MUI.AddBoxIcon />}
        />
      </MUI.Box>

      {videoList.length ? (
        videoList.map((video: any, index) => (
          <TabPanel key={index} value={value} index={index}>
            <MUI.TextField
            sx={{marginTop:1.2, marginBottom:3.6}}
              value={video.title}
              margin='normal'
              label='Video Title'
              fullWidth
              variant='outlined'
              onChange={(e) => {
                updateVideoForm(e.target.value, index, 'title')
                // !isAdd && setVideoEdit(false)
              }}
            />
            <div className='resource-required'>*required</div>
            
            <MUI.TextField
              value={video.description || ''}
              margin='normal'
              label='Video Description'
              fullWidth
              variant='outlined'
              type='text'
              multiline
              minRows={3}
              maxRows={5}
              onChange={(e) => {
                updateVideoForm(e.target.value, index, 'description')
                // !isAdd && setVideoEdit(false)
              }}
            />

            <MUI.TextField
              value={video.link_url}
              margin='normal'
              label='Video Link'
              fullWidth
              variant='outlined'
              type='url'
              onChange={(e) => {
                updateVideoForm(e.target.value, index, 'link_url')
                // !isAdd && setVideoEdit(false)
              }}
            />
            <div className='resource-required'>*required</div>
            
            {isValidURL(video.link_url) ? (
              <>
                <hr />
                <iframe
                  src={parseVideoUrl(video.link_url)}
                  className='d-block mx-auto'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </>
            ) : (
              <div className='invalid-video'>
                <i>Paste in YouTube video link to preview</i>
              </div>
            )}
            {video.id && (
              <div className='flex content-center mb-1r mt-1r video-delete'>
                <IconButton
                  onClick={() => onConfirmPopup()}
                  color='error'
                  variant='outlined'
                  text='Delete'
                  icon={<MUI.DeleteIcon sx={{ fontSize: 20 }} />}
                />
                <ConfirmPopup
                  info={{
                    message: 'Are you sure?',
                    positiveBtn: 'Confirm',
                    negativeBtn: 'Cancel'
                  }}
                  severity='warning'
                  open={open}
                  setOpen={setOpen}
                  onConfirm={() => onConfirm(video.id)}
                  sx={{ position: 'unset', marginTop: '1rem' }}
                />
              </div>
            )}
          </TabPanel>
        ))
      ) : (
        <div className='dialog-no-video'>
          <i>This Resource Has No Video</i>
        </div>
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}
export default VideoForm
