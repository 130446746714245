import { createContext } from 'react'
import { ICourseResponse, Notify } from '../../../../interface'
import { CourseAndTopicID } from '../../CoursesPage'

export type CourseContextProps = {
  learnerId?: number
  apiCoursesData: ICourseResponse[]
  notify: Notify | undefined
  displayTopicsState: boolean
  pathname: string
  submitCourseTopicId: CourseAndTopicID
  searchCourseTopicId: CourseAndTopicID
  setSubmitCourseTopicId: (arg: CourseAndTopicID) => void
  setSearchCourseTopicId: (arg:CourseAndTopicID) => void
  setNotify: (arg: Notify) => void
  setApiCoursesData: (arg: ICourseResponse[]) => void
  setIsCourseLoading: (arg: boolean) => void
}

export const CourseContext = createContext<CourseContextProps>({
  learnerId: 0, 
  apiCoursesData: [],
  notify: {} as Notify,
  displayTopicsState: false,
  pathname: '',
  submitCourseTopicId: {courseId: 0, topicId: 0},
  searchCourseTopicId: {courseId: 0, topicId: 0},
  setNotify: () => {},
  setApiCoursesData: () => {},
  setIsCourseLoading: () => {},
  setSubmitCourseTopicId: () => {},
  setSearchCourseTopicId: () => {}
})
