import * as MUI from '../../MUI'
import { textButtonProps } from "../../interface"

const TextButton = (props:textButtonProps) =>{

  const {text, onClick, color, disabled, type, size, className, component} = props;

  return(
    <MUI.Button 
    onClick={onClick} 
    color={color} 
    disabled={disabled} 
    type={type} 
    size={size} 
    className={className}
    component={component}>
      {text}
    </MUI.Button>
  )
}

export default TextButton