import { imageBaseUrl } from '../../../constants/constants'
import timeDiff from './time-different'
import * as MUI from '../../../MUI'

interface TutorNotificationMenuProps {
  tutorNotificationsItems: Array<any>
  handleClickOnItem: (taskId: number, learnerId: number) => void
  handleCloseNotificationMenu: () => void
}

const TutorNotificationMenu = (props: TutorNotificationMenuProps) => {
  const { tutorNotificationsItems, handleClickOnItem, handleCloseNotificationMenu } = props

  return (
    <>
      {tutorNotificationsItems.sort((itemA:any, itemB:any) => {
        return new Date(itemA.updated_at).getTime() - new Date(itemB.updated_at).getTime()
      }).map((item, index) => {
        return (
          item.tutor_notification_status && (
            <MUI.MenuItem
              key={index}
              onClick={() => {
                console.log(item.task_id, item.learner_id)
                handleClickOnItem(item.task_id, item.learner_id)
                handleCloseNotificationMenu()
              }}
            >
              <MUI.ListItemAvatar>
                <MUI.Avatar
                  src={item.learner.user_avatar ? imageBaseUrl + item.learner.user_avatar : ''}
                />
              </MUI.ListItemAvatar>
              <span style={{ display: 'flex', flexFlow: 'column' }}>
                <MUI.Typography>
                  <span style={{ fontWeight: '600' }}>
                    {item.learner.first_name} {item.learner.last_name}
                  </span>
                </MUI.Typography>
                <span style={{ display: 'flex', flexFlow: 'column' }}>
                  <MUI.Typography>
                    <span style={{ color: 'grey', fontSize: 12 }}>has submitted task</span>
                    <span style={{ fontSize: 12, fontWeight: 600 }}> {item.task.title}</span>
                  </MUI.Typography>
                  <span>
                    <MUI.AccessTimeFilledIcon color='disabled' fontSize='small' />
                    <span style={{ fontSize: 12 }}>
                      {' '}
                      {timeDiff.timeAgo(item.updated_at)}
                    </span>
                  </span>
                </span>
              </span>
            </MUI.MenuItem>
          )
        )
      })}
    </>
  )
}

export default TutorNotificationMenu