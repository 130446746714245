import React, { useState, useEffect, useContext, createContext } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { CourseContext } from '../context/CourseContext'
import { AppContext } from '../../../../context/AppContext'
import { DashboardContext } from '../../../dashboard-page/dashboard-components/context/DashboardContext'
import { useObservable } from '../../../../util/useObservable'
import { collapseObservable } from '../../../../observables/collapseAll'
import { ICourseResponse, ICourseTopic } from '../../../../interface'
import UserRoleAuth from '../../../../components/userrole-authentication/UserRoleAuth'
import Step from '../status/Step'
import Progress from '../status/Progress'
import CourseTopic from '../course-list/CourseTopic'
import CourseMenu from '../components/CourseMenu'
// import TopicCard from "../components/TopicCard"
import * as MUI from '../../../../MUI'
import './Course.css'
import { useLocation, useSearchParams } from 'react-router-dom'
import { learnerCoursesStatusProps } from '../../CoursesPage'
interface CoursesProps {
  apiCoursesData: ICourseResponse
  index: number
  length: number
  setOpenState: any
  courseKey?: number
  learnerId?: number
  currentCourseId: number
  learnerCoursesStatus: learnerCoursesStatusProps[]
  setLearnerCoursesStatus: React.Dispatch<React.SetStateAction<learnerCoursesStatusProps[]>>
}

interface reduceObjProps {
  courseId: number
  numOfTasks: number
  numOfCompletedTasks: number
}

interface ProgressContextProps {
  reduceObj: reduceObjProps
  setProgressHeight: (arg: number) => void
  setReduceObj: (arg: reduceObjProps) => void
}

export const ProgressContext = createContext<ProgressContextProps>({
  reduceObj: {} as reduceObjProps,
  setProgressHeight: () => {},
  setReduceObj: () => {}
})

const Course = (props: CoursesProps) => {
  const { apiCoursesData, index, length, setOpenState, courseKey, learnerId, currentCourseId, learnerCoursesStatus, setLearnerCoursesStatus } = props
  const [courseTopics, setCourseTopics] = useState<ICourseTopic[]>(apiCoursesData.course_topic)
  const [open, setOpen] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState<boolean>(true)
  const { displayTopicsState, pathname, submitCourseTopicId, searchCourseTopicId } = useContext(CourseContext)
  // const { userRole } = useContext(AppContext)
  const { assignCourseState } = useContext(DashboardContext)
  const collapseState = useObservable(collapseObservable().getObservable())

  const [searchParams, setSearchParams] = useSearchParams()
  const {search } = useLocation()
  // const query = new URLSearchParams(window.location.search)
  const query = new URLSearchParams(search)
  const TopicId = Number(query.get('TopicId'))




  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  let displayAllTopics = true
  let isLast = index === length
  // let isLast = !open && index === length
  const courseItemStyles = {
    minHeight: '60px',
    ...(matchesSM && { minHeight: '90px' })
  }

  const handleOpen = () => {
      setOpen(!open)
      setOpenState(!open)
      searchParams.delete('CourseId')
      searchParams.delete('TopicId')
      setSearchParams(searchParams)

  }

  useEffect(() => {
    setOpen(!collapseState.collapseAll)
    setOpenState(!collapseState.collapseAll)
  }, [collapseState])

  useEffect(() => {
    setCourseTopics(apiCoursesData.course_topic)
  }, [apiCoursesData.course_topic])

  const [topicOpen, setTopicOpen] = useState<Array<any>>([])

  useEffect(() => {
    setTopicOpen(
      courseTopics.map(value => {
        return {
          topic_id: value.id,
          topic_open: false,
        }
      })
    )
  }, [courseTopics])

  useEffect(() => {
    if (submitCourseTopicId.courseId !== 0 && courseKey === submitCourseTopicId.courseId) {
      setOpen(true)
    }
    // console.log(submitCourseTopicId)
  }, [submitCourseTopicId])

  useEffect(() => {
    if (searchCourseTopicId.courseId !== 0 && courseKey === searchCourseTopicId.courseId) {
      setOpen(true)
    }
  }, [searchCourseTopicId])

  useEffect(() => {
    if (currentCourseId == apiCoursesData.id){
      setOpen(true)
    }
  },[currentCourseId])

  useEffect(() => {
    const sessionCourseId = sessionStorage.getItem('courseTopicIds')
    if(!!sessionCourseId){
      JSON.parse(sessionCourseId).courseId === courseKey && setOpen(true)
    } 
  },[])

  const [progressHeight, setProgressHeight] = useState(0)
  const [completion, setCompletion] = useState(false)
  const [reduceObj, setReduceObj] = useState<reduceObjProps>({
    courseId: 0,
    numOfTasks: 0,
    numOfCompletedTasks: 0,
  })
  const value ={
    reduceObj,
    setProgressHeight,
    setReduceObj,
  }

  useEffect(() => {
    if(courseTopics) {
      const mfData = courseTopics.reduce<reduceObjProps>(
        (accObj, curObj) => {
          accObj.courseId = curObj.course_id
  
          if(curObj.course_topic_tasks) {
            accObj.numOfTasks += curObj.course_topic_tasks.length
          }
  
          curObj.course_topic_tasks?.forEach(task => {
            if(task.task_learner?.tutor_review === 'review complete') {
              accObj.numOfCompletedTasks += 1
            }
          })
  
          return accObj
      },{
      courseId: 0,
      numOfTasks: 0,
      numOfCompletedTasks: 0
    })
  
      if(mfData) setReduceObj(mfData)
    }
  }, [])

  useEffect(() => {
    if(reduceObj.numOfTasks) {
      setProgressHeight(reduceObj.numOfCompletedTasks / reduceObj.numOfTasks)
    } else {
      setProgressHeight(0)
    }
  }, [reduceObj])

  useEffect(() => {
    if(progressHeight === 1) {
      setCompletion(true)
    } else {
      setCompletion(false)
    }
  }, [progressHeight])

  useEffect(() => {
    setLearnerCoursesStatus(
      learnerCoursesStatus.map((value) => {
        if (value.course_id === apiCoursesData.id) {
          return {
            course_id: apiCoursesData.id,
            course_completion: completion
          }
        }
        return value
      })
    )
  }, [completion])

    // useEffect(() => {
    //   console.log('learnerCoursesStatus', learnerCoursesStatus)
    //   // console.log('currentCourseId', currentCourseId)
    // }, [learnerCoursesStatus])

  return (
    <MUI.List id={`Course#${index}`} component='div' key={index}>
      {/* Course Item  */}
        <div className='c-nav' style={courseItemStyles}>
          {!assignCourseState && (
            <UserRoleAuth pathName={pathname} element='progress'>
              {sessionStorage.getItem('Selected-Tab') !== 'Courses' &&
                <Step completion={completion} />}
            </UserRoleAuth>
          )}
          {
          // assignCourseState && 
          //   <MUI.Checkbox 
          //     checked={courseAssigned}
          //     onChange={() => handleCheckboxChange(course.id)} 
          //   />
          }
          <MUI.ListItem
            // disabled
            secondaryAction={
              <div>
                <UserRoleAuth pathName={pathname} element='button'>
                  {open && sessionStorage.getItem('Selected-Tab') === 'Courses' && <CourseMenu data={apiCoursesData} courseId={apiCoursesData.id} />}
                </UserRoleAuth>
              </div>
            }
            disablePadding={true}
          >
            <MUI.ListItemButton 
              disabled={assignCourseState} 
              onClick={()=>handleOpen()} 
              className='c-list-course-button'
            >
              {open ? <MUI.ExpandLess /> : <MUI.ExpandMore />}
              <div className='course-title'>
                <MUI.ListItemText
                  primary={apiCoursesData.title}
                  primaryTypographyProps={{
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '36px',
                    ...(matchesSM && { minHeight: '66px' })
                  }}
                />
              </div>
            </MUI.ListItemButton>
          </MUI.ListItem>
        </div>

        {/* Course Content */}
        <div id={`Couse#${index}`} className='c-content'>
          {/* {userRole === 'Others' && <Progress open={open} isLast={isLast} />} */}
          {!assignCourseState && (
            <UserRoleAuth pathName={pathname} element='progress'>
              {sessionStorage.getItem('Selected-Tab') !== 'Courses' && 
                <Progress open={open} isLast={isLast} height={progressHeight} showProgressBar={showProgressBar} completion={completion} learnerCoursesStatus={learnerCoursesStatus} />
              }
            </UserRoleAuth>
          )}

          {/* Course Content Panel */}
          <MUI.Collapse 
            id={`Couse#${index}-Content`} 
            sx={{ width: '100%' }} 
            in={open} 
            timeout='auto'
          >
            <div
              className='course-content mt-1r mb-2r'
              style={{ marginLeft: 40 }}
            >
              Dscription: {apiCoursesData.description}
              <hr />
              Comments: {apiCoursesData.comments}
              {/* Alternative rendering of topics using cards */}
              {/* <div className="topic-wrapper">
                {!!apiCoursesData.course_topic.length &&
                  apiCoursesData.course_topic.map((apiCoursesData, index) => {
                    return <TopicCard key={index} data={apiCoursesData} />;
                })}
              </div> */}
            </div>

            {/* Topic List */}
            <ProgressContext.Provider value={value}>
            {!!courseTopics &&
              !!courseTopics.length &&
              courseTopics.sort((a,b) => a.course_topic_code - b.course_topic_code).map(
                (data, index) =>
                  (!displayTopicsState ? data.type !== 'elective' : displayAllTopics) && (
                    <CourseTopic
                      key={index}
                      topicId={data.id}
                      topicTitle={data.title}
                      isCourseOpen={open}
                      setCourseTopics={setCourseTopics}
                      courseTopics={courseTopics}
                      currentTopicId={TopicId}
                      showProgressBar={showProgressBar}
                      setShowProgressBar={setShowProgressBar}
                      topicOpen={topicOpen}
                      setTopicOpen={setTopicOpen}
                    />
                    )
                )}
            </ProgressContext.Provider>
          </MUI.Collapse>
        </div>
    </MUI.List>
  )
}
export default Course
