import { useEffect, useState, useContext, SyntheticEvent } from 'react'
import { makeStyles } from '@mui/styles'
import { styled, useTheme } from '@mui/material/styles'
import { Container, useMediaQuery } from '@mui/material'
import { createSearchParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { ICourseResponse, User } from '../../interface'
import { AppContext } from '../../context/AppContext'
import { DashboardContext, DashboardContextProps } from './dashboard-components/context/DashboardContext'
import CoursesPage from '../courses-page/CoursesPage'
import DashboardUserCard from '../../dialogs/user-dialog/DashboardUserCard'
import ConfigPage from '../config-page/ConfigPage'
import apiService from '../../services/api-services'
import UserRoleAuth from '../../components/userrole-authentication/UserRoleAuth'
import UserSearchBar from './dashboard-components/components/UserSearchBar'
import * as MUI from '../../MUI'
import IconButton from '../../components/button/IconButton'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import DashboardTabSwitch from './dashboard-components/context/DashboardTabSwitch'
import UsersPage from '../users-page/UsersPage'
import PieChart from './dashboard-components/PieChart'
import { imageBaseUrl } from '../../constants/constants'
import TuneIcon from '@mui/icons-material/Tune'
import DoneIcon from '@mui/icons-material/Done'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import MenuBookIcon from '@mui/icons-material/MenuBook'

const drawerWidth = 250
const transitionDuration = 600

export const useStyles = makeStyles({
  adminRoot: {
    // display: 'flex',
  },
  learnerRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '7rem'
  },
  selectLearnerField: {
    maxWidth: 250,
    margin: 'auto'
  },
  mobileDrawerPaper: {
    width: drawerWidth,
    padding: '0 5px'
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
    zIndex: 1,
    // height: `calc(100vh - 60px - 58px)`,
    height: `calc(var(--app-height) - 60px - 58px)`,
    paddingRight: '5px'
  },
  avatar: {
    width: 32,
    height: 32
  },
  active: {
    background: '#f4f4f4',
    borderRight: '2px solid'
  },
  listTitle: {
    fontSize: 25,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between'
  },
  learnerDashboard: {
    paddingTop: 30,
    fontWeight: 500
  }
})

export const adjustToAppHeight = () => {
  const appHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  window.addEventListener('resize', appHeight)
  appHeight()
}

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  [theme.breakpoints.down('md')]: { marginLeft: 0 },
  marginLeft: `${drawerWidth + 30}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    display: 'flex',
    justifyContent: 'center'
  }),
  display: 'flex',
  justifyContent: 'center'
}))

export type LearnerId = Pick<User, 'id'>

export default function DashboardPage() {
  const classes = useStyles()

  const [currentCourse, setCurrentCourse] = useState(0)
  const [currentTopic, setCurrentTopic] = useState(0)

  const route = { path: '/Courses', component: <CoursesPage DashBoardTabValue={0} /> }
  const navigate = useNavigate()
  const params = { CourseId: currentCourse.toString(), TopicId: currentTopic.toString() }
  const goToCurrent = () => {
    navigate({
      pathname: '/Courses',
      search: `?${createSearchParams(params)}`
    })
  }

  const theme = useTheme()
  const MatchesMd = useMediaQuery(theme.breakpoints.up('md'))

  const [apiLearners, setApiLearners] = useState<User[]>([])
  const [learnerId, setLearnerId] = useState<LearnerId>()
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(true)
  const [assignCourseState, setAssignCourseState] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [userDialogOpen, setUserDialogOpen] = useState<boolean>(false)
  const [email, setEmail] = useState('')
  const [filteredLearners, setFilteredLearners] = useState<User[]>([])

  const [module_one_all_tasks, setModuleOneAllTasks] = useState(0)
  const [module_one_tasks_completed, setModuleOneTasksCompleted] = useState(0)
  const [module_two_all_tasks, setModuleTwoAllTasks] = useState(0)
  const [module_two_tasks_completed, setModuleTwoTasksCompleted] = useState(0)
  const [module_three_all_tasks, setModuleThreeAllTasks] = useState(0)
  const [module_three_tasks_completed, setModuleThreeTasksCompleted] = useState(0)
  const [module_four_all_tasks, setModuleFourAllTasks] = useState(0)
  const [module_four_tasks_completed, setModuleFourTasksCompleted] = useState(0)

  const { search, pathname } = useLocation()
  const { userRole } = useContext(AppContext)
  const query = new URLSearchParams(search)
  const id = query.get('id')

  const [displayProgress, setDisplayProgress] = useState(true)
  const [userInfoToDisplay, setUserInfoToDisplay] = useState('Show User Details')

  adjustToAppHeight()

  const toggleProgress = () => {
    userInfoToDisplay == 'Show Progress'
      ? setUserInfoToDisplay('Show User Details')
      : setUserInfoToDisplay('Show Progress')
    setDisplayProgress(!displayProgress)
  }
  const config: DashboardContextProps = {
    submit,
    setSubmit,
    assignCourseState,
    courseCheckbox: <MUI.Checkbox />,
    userDialogOpen,
    setUserDialogOpen,
    email,
    setEmail
  }

  const [apiCoursesData, setApiCoursesData] = useState<ICourseResponse[]>([])

  useEffect(() => {
    if (userRole() === 'Admin User' || userRole() === 'Normal User') {
      apiService.getAllLearners().then((res) => {
        setApiLearners(res)
      })
    }
  }, [])

  useEffect(() => {
    setLearnerId({ id: parseInt(id!) })
  }, [])

  useEffect(() => {
    learnerId && setSearchParams({ id: String(learnerId.id) })
  }, [learnerId, setSearchParams])

  useEffect(() => {
    const newArray = apiLearners.map((learner) => {
      if (learner.id === learnerId?.id) {
        return {
          ...learner,
          email: email
        }
      }
      return learner
    })
    setApiLearners(newArray)
  }, [userDialogOpen])

  useEffect(() => {
    apiService.getLearnerSelfCourses().then((res) => {
      setApiCoursesData(res)
    })
  }, [])

  const [courseTopics, setCourseTopics] = useState<any>([])
  useEffect(() => {
    let courseTopic = []
    for (let item of apiCoursesData) {
      courseTopic.push(item.course_topic)
    }
    setCourseTopics(courseTopic.flat())
  }, [apiCoursesData])

  useEffect(() => {
    testData()
  }, [courseTopics])

  const getCourseTopics = (topicId: number) => {
    return courseTopics.filter((topic: { id: number }) => {
      if (topic.id == topicId) {
        return topic
      }
    })
  }

  const testData = () => {
    let module_one_all_tasks_counter = 0
    let module_one_completed_tasks_counter = 0
    // console.log(apiCoursesData)
    for (const i in apiCoursesData) {
      for (const j in apiCoursesData[i].course_topic) {
        let res = getCourseTopics(apiCoursesData[i].course_topic[j].id)
        for (const z in res) {
          if (res[z].course_topic_tasks.length != 0) {
            // console.log(res[z].course_topic_tasks)
            for (const key in res[z].course_topic_tasks) {
              module_one_all_tasks_counter += 1
              // setModuleOneAllTasks(module_one_all_tasks+1)
              // console.log(res[z].course_topic_tasks[key])

              //check this part move it down under module and change submit to complete
              if (res[z].course_topic_tasks[key].task_learner?.completion_stage == 'submitted') {
                module_one_completed_tasks_counter += 1

                //for current course
                setCurrentCourse(apiCoursesData[i].id)
                setCurrentTopic(apiCoursesData[i].course_topic[j].id)
              }
              // console.log("number")

              // if (res[z].course_topic_tasks[key].module === 1) {

              //   console.log('HTML')
              //   setModuleOneAllTasks(module_one_all_tasks+1)
              // } else if (res[z].course_topic_tasks[key].module === 2) {
              //   console.log('JS')
              // } else if (res[z].course_topic_tasks[key].module === 3){
              //   console.log('React')
              // }
              // else {
              //   console.log('Back-End')
              // }
            }
            setModuleOneTasksCompleted(module_one_completed_tasks_counter)
            setModuleOneAllTasks(module_one_all_tasks_counter)
          }
        }
      }
    }
  }

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const hasQueryLearnerId = () => {
    return apiLearners.some((learner) => {
      return learner.id === Number(id)
    })
  }

  const drawer = (
    <>
      <MUI.Typography className={classes.listTitle}>
        Learners
        {MatchesMd && hasQueryLearnerId() && (
          <MUI.IconButton disabled={!hasQueryLearnerId()} onClick={handleDrawerClose}>
            <MUI.KeyboardArrowLeftIcon />
          </MUI.IconButton>
        )}
      </MUI.Typography>
      <MUI.List>
        <UserSearchBar
          searchArray={apiLearners}
          setFilteredArray={setFilteredLearners}
          text='Learner'
        />
      </MUI.List>
      <MUI.List>
        {filteredLearners.map((learner, index) => {
          // {apiLearners.map((learner, index) => {
          return (
            <MUI.ListItem
              disabled={assignCourseState}
              id='drawer-item'
              key={index}
              button
              disablePadding
              onClick={() => {
                setLearnerId({ id: learner.id })
                setDisplayProgress(true)
                setUserInfoToDisplay('Show User Details')
                setOpen(false)
              }}
              className={Number(id) === learner.id ? classes.active : undefined}
            >
              <MUI.ListItemButton disableGutters>
                <MUI.ListItemAvatar>
                  <MUI.Avatar
                    className={classes.avatar}
                    src={learner.user_avatar ? imageBaseUrl + learner.user_avatar : ''}
                  />
                </MUI.ListItemAvatar>
                <MUI.ListItemText
                  primary={
                    <div
                      style={{
                        maxWidth: '230px',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <span style={{ fontWeight: '600' }}>
                        {learner.first_name} {learner.last_name}
                      </span>
                      <span
                        style={{
                          width: '180px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '12px',
                          color: 'rgb(0,0,0,.55)'
                        }}
                      >
                        {learner.email}
                      </span>
                    </div>
                  }
                />
              </MUI.ListItemButton>
            </MUI.ListItem>
          )
        })}
      </MUI.List>
    </>
  )

  const handleAssignCourseState = () => {
    setAssignCourseState(true)
    // setAssignCourseState(!assignCourseState)
  }

  const handleSubmitAssignCourses = () => {
    setSubmit(true)
    setAssignCourseState(false)
  }

  // value:Tab selection
  const [value, setValue] = useState(() => {
    const tabValue = sessionStorage.getItem('Tab')
    if (tabValue) {
      switch (tabValue) {
        case 'Overview':
          return 0
        case 'Progress':
          return 1
        case 'Users':
          return 2
        case 'Courses':
          return 3
        case 'Configs':
          return 4
        default:
          break
      }
    }
    return 1
  })

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div>
      <UserRoleAuth pathName={pathname} element='admin dashboard'>
        <MUI.Box
          sx={{ bgcolor: 'background.paper' }}
          style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            <Tab label='Overview' />
            <Tab label='Progress' />
            {JSON.parse(localStorage.getItem('USERKEY')!)?.role === 1 ? (
              <Tab label='Users' />
            ) : (
              <Tab style={{ display: 'none' }} />
            )}
            <Tab label='Courses' />
            <Tab label='Configs' />
          </Tabs>
        </MUI.Box>

        <DashboardTabSwitch value={value} element='Overview'>
          <></>
        </DashboardTabSwitch>

        <DashboardTabSwitch value={value} element='Progress'>
          <MUI.Box id='admin-dashboard'>
            {/* Drawer */}
            <MUI.Box
              component='nav'
              sx={{
                width: { md: drawerWidth },
                position: 'fixed'
              }}
            >
              <MUI.Drawer
                className={classes.mobileDrawerPaper}
                variant='temporary'
                anchor='left'
                open={open}
                onClose={toggleDrawer}
                classes={{ paper: classes.mobileDrawerPaper }}
                PaperProps={{ elevation: 15 }}
                ModalProps={{
                  keepMounted: true
                }}
                transitionDuration={{
                  enter: transitionDuration,
                  exit: transitionDuration
                }}
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none'
                  }
                }}
              >
                {drawer}
              </MUI.Drawer>
              <MUI.Drawer
                classes={{ paper: classes.drawerPaper }}
                variant='persistent'
                sx={{
                  display: { xs: 'none', md: 'block' }
                  // height: { md: `calc(100vh - 60px)` },
                  // height: { md: `var(--app-height)` },
                }}
                open={openDrawer}
              >
                {drawer}
              </MUI.Drawer>
            </MUI.Box>
            {/* <MUI.Box
              component='main'
              sx={{
                marginLeft: { md: `${drawerWidth + 30}px`},
                width: { md: `calc(100% - ${drawerWidth + 30}px)` },
                display: 'flex',
                justifyContent: 'center'
              }}
            > */}
            {/* Dashboard - Tutor view */}
            <Main open={!openDrawer}>
              <MUI.Fab
                onClick={toggleDrawer}
                size='small'
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none'
                  },
                  position: 'fixed',
                  right: '5px',
                  bottom: '25px'
                }}
              >
                <MUI.PeopleIcon />
              </MUI.Fab>
              {!hasQueryLearnerId() ? (
                <div
                  style={{
                    paddingTop: 100,
                    fontWeight: 500,
                    order: 1
                  }}
                >
                  <MUI.Typography variant='h3'>Select learner to view progress</MUI.Typography>
                </div>
              ) : (
                <div style={{ flexGrow: 1 }}>
                  <div
                    style={{
                      marginTop: 20,
                      display: 'flex'
                    }}
                  >
                    {MatchesMd && (
                      <MUI.IconButton
                        sx={{ mr: 2, ...(openDrawer && { display: 'none' }), padding: 0 }}
                        onClick={handleDrawerOpen}
                        size='small'
                        disableRipple
                      >
                        <MUI.MenuIcon />
                      </MUI.IconButton>
                    )}
                    {apiLearners.map((learner, index) => {
                      return (
                        learner.id === (learnerId?.id || Number(query.get('id'))) && (
                          <div key={index}>
                            <span style={{ fontSize: '14px' }}>{learner.email}</span>
                            {!assignCourseState && (
                              <IconButton
                                onClick={() => toggleProgress()}
                                color='inherit'
                                icon={
                                  userInfoToDisplay == 'Show User Details' ? (
                                    <FolderSharedIcon />
                                  ) : (
                                    <MenuBookIcon />
                                  )
                                }
                              />
                            )}
                            <IconButton
                              icon={<TuneIcon />}
                              color='inherit'
                              onClick={() => handleAssignCourseState()}
                              sx={
                                !assignCourseState
                                  ? userInfoToDisplay == 'Show Progress'
                                    ? { display: 'none' }
                                    : { display: 'inline-flex' }
                                  : { display: 'none' }
                              }
                            />
                            <IconButton
                              startIcon={<DoneIcon />}
                              color='inherit'
                              onClick={() => handleSubmitAssignCourses()}
                              sx={
                                assignCourseState
                                  ? { marginLeft: 2, display: 'inline-flex' }
                                  : { display: 'none' }
                              }
                              text='Done'
                            />
                          </div>
                        )
                      )
                    })}
                  </div>
                  <DashboardContext.Provider value={config}>
                    {displayProgress ? (
                      apiLearners.map((learner) => {
                        return (
                          learner.id === (learnerId?.id || Number(query.get('id'))) && (
                            <div key={learner.id}>
                              <CoursesPage
                                learnerId={learner.id}
                                learner={learner}
                                DashBoardTabValue={value}
                              />
                            </div>
                          )
                        )
                      })
                    ) : (
                      <DashboardUserCard learnerId={learnerId!} learners={apiLearners} />
                    )}
                  </DashboardContext.Provider>
                </div>
              )}
            </Main>
          </MUI.Box>
        </DashboardTabSwitch>

        {JSON.parse(localStorage.getItem('USERKEY')!)?.role === 1 && (
          <DashboardTabSwitch value={value} element='Users'>
            <UsersPage />
          </DashboardTabSwitch>
        )}

        <DashboardTabSwitch value={value} element='Courses'>
          <CoursesPage DashBoardTabValue={value} />
        </DashboardTabSwitch>
        <DashboardTabSwitch value={value} element='Configs'>
          <ConfigPage DashBoardTabValue={value} />
        </DashboardTabSwitch>
      </UserRoleAuth>
      <UserRoleAuth pathName={pathname} element='learner dashboard'>
        <MUI.Box id='learner-dashboard' className={classes.learnerRoot}>
          <Container>
            <MUI.Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 2, md: 3 }}
              textAlign={'center'}
              direction='row-reverse'
              justifyContent='center'
            >
              <MUI.Grid item xs={12} sm={6}>
                <MUI.Box sx={{ boxShadow: 3, p: 4, borderRadius: '16px' }}>
                  {/* <ProgressBar />  */}
                  <PieChart
                    htmlFinish={module_one_tasks_completed}
                    htmlAll={module_one_all_tasks}
                  />
                  <MUI.Button
                    variant='contained'
                    onClick={goToCurrent}
                    sx={{
                      display: { xs: 'none', sm: 'inline' },
                      textAlign: 'center',
                      marginTop: '2em'
                    }}
                  >
                    current course
                  </MUI.Button>
                </MUI.Box>
                <MUI.Box
                  sx={{
                    boxShadow: 3,
                    p: 4,
                    borderRadius: '16px',
                    display: { xs: 'flex', sm: 'none' },
                    marginTop: '1.5em',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <MUI.Button
                    variant='contained'
                    onClick={goToCurrent}
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    current course
                  </MUI.Button>
                </MUI.Box>
              </MUI.Grid>
            </MUI.Grid>
          </Container>
        </MUI.Box>
      </UserRoleAuth>
    </div>
  )
}
