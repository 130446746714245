import * as MUI from '../../MUI'
import { useEffect, useState } from "react"
import UserCard from './UserCard'
import apiService from "../../services/api-services"
import ChangeUserDetails from './ChangeUserDetails'

const UserData = () => {
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const [editState, setEditState] = useState(false)
  
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [user_avatar, setUserAvatar] = useState("")

  useEffect(() => {
    apiService.getLearner().then((res: any) => {
      setFirstName(res.first_name)
      setLastName(res.last_name)
      setEmail(res.email)
      setUserAvatar(res.user_avatar)
    })
    if(userDialogOpen == false){
      setEditState(false)
    }
  }, [userDialogOpen]) 

  const handleUserDialogOpen = () => {
    setUserDialogOpen(true)
  }

  const handleUserDialogClose = () => {
    setEditState(false)
    setUserDialogOpen(false)
  }

  const handleEdit = () => {
    setEditState(!editState)
  }

  return (
    <>
      <MUI.IconButton
        sx={{ borderRadius: 0, padding: '10px 10px', width: '100%', justifyContent: 'flex-start' }}
        onClick={handleUserDialogOpen}
        disableRipple
      >
        <MUI.PersonOutlineIcon sx={{ marginRight: '5px' }} fontSize='medium' />
        <div style={{ fontSize: '18.5px', color: 'black' }}>My account</div>
      </MUI.IconButton>
      {editState ? (
        <ChangeUserDetails userDialogOpen={userDialogOpen} setUserDialogOpen={setUserDialogOpen}/>
      ) : (
        <UserCard
          isOpen={userDialogOpen}
          handleClose={handleUserDialogClose}
          handleEdit={handleEdit}
          first_name={first_name}
          last_name={last_name}
          user_email={email}
          user_avatar={user_avatar}
        />
      )}
    </>
  )
}
export default UserData

