import {
  ResourcesResponse,
  ICourseResponse,
  ICourseTopicResponse,
  ResourceResponse,
  ResourcesRequest,
  AddResourceResponse,
  ResourceVideo,
  Config,
  IAdminCourseAddReq,
  IAdminCourseAddRes,
  IAdminCourseTopicReq,
  IAdminCourseTopicRes,
  IAdminCourseTopicTaskRes,
  IAdminCourseTopicTaskReq,
  LoginReq,
  LoginResponse,
  User,
  IAssignCourses,
  IAssignCoursesRes,
  IRemoveLearnerCourse,
  IRemoveCourseTopicResource,
  ICourseTopicResources,
  ICourseTopicTask,
  ITaskLearner,
  INotification,
  ITagResponse,
  ITagFormData,
  AddConfig,
  configRequest
} from '../interface/index'
import { RootPath, SubPath } from './api-path'
import BaseApiService from './api-base-service'

class ApiService extends BaseApiService {
  /**
   *
   * @returns Login API
   */
  loginUser(req: LoginReq): Promise<LoginResponse> {
    return this.post(RootPath.login, req)
  }

  getLearner(): Promise<User> {
    return this.get(RootPath.learner)
  }

  /**
   *
   * @returns Resources API
   */
  getAllResources(): Promise<ResourcesResponse[]> {
    return this.get(RootPath.resources)
  }

  getResources(id: number): Promise<ResourceResponse[]> {
    return this.get([RootPath.resource, id])
  }

  addResource(req: ResourcesRequest): Promise<AddResourceResponse> {
    return this.post(RootPath.resource, req)
  }

  addResourceVideo(req: ResourceVideo[], id: number): Promise<AddResourceResponse> {
    return this.post([RootPath.resourceVideo, id], req)
  }

  updateResource(req: ResourcesRequest, id: number): Promise<AddResourceResponse> {
    return this.put([RootPath.resource, id], req)
  }

  deleteResource(id: number) {
    return this.delete([RootPath.resource, id])
  }

  updateResourceVedio(req: ResourceVideo[]): Promise<AddResourceResponse> {
    return this.put([RootPath.resourceVideo], req)
  }

  deleteResourceVideo(id: number): Promise<boolean> {
    return this.delete([RootPath.resourceVideo, id])
  }

  /**
   *
   * @returns Course API
   */
  getAllCourses(): Promise<ICourseResponse[]> {
    return this.get(RootPath.courses)
  }

  getCourseTopic(id: number): Promise<ICourseTopicResponse[]> {
    return this.get([RootPath.course, RootPath.topic, id])
  }

  getMockCourseTopic(id: number): Promise<ICourseTopicResponse> {
    return this.get([RootPath.topic, id])
  }

  getTaskComment(id: number, userID: any): Promise<any> {
    return this.get([RootPath.learner, RootPath.task, id, RootPath.comments, userID])
  }

  createTaskComment(content: any): Promise<any> {
    return this.post([RootPath.learner, RootPath.task, RootPath.comment], content)
  }

  deleteTaskComment(id: number): Promise<any> {
    return this.delete([RootPath.learner, RootPath.task, RootPath.comment, id])
  }

  uploadFile(file: any): Promise<any> {
    return this.post('uploadFile', file)
  }
  /**
   *
   *  @return Admin Course
   */
  addAdminCourse(req: IAdminCourseAddReq): Promise<IAdminCourseAddRes> {
    return this.post(`${RootPath.admin}/${RootPath.course}`, req)
  }

  addTag(req: configRequest): Promise<AddConfig> {
    return this.post([RootPath.admin, RootPath.tag], req)
  }

  editAdminCourse(id: number, req: IAdminCourseAddReq): Promise<boolean> {
    return this.put([RootPath.admin, RootPath.course, id], req)
  }

  deleteAdminCourse(id: number): Promise<any> {
    return this.delete([RootPath.admin, RootPath.course, id])
  }

  addAdminCourseTopic(id: number, req: IAdminCourseTopicReq): Promise<IAdminCourseTopicRes> {
    return this.post([RootPath.admin, RootPath.course, id, RootPath.topic], req)
  }

  editAdminCourseTopic(id: number, req: IAdminCourseTopicReq): Promise<boolean> {
    return this.put([RootPath.admin, RootPath.course, RootPath.topic, id], req)
  }

  deleteAdminCourseTopic(id: number): Promise<any> {
    return this.delete([RootPath.admin, RootPath.course, RootPath.topic, id])
  }

  addAdminCourseTopicTask(
    id: number,
    req: IAdminCourseTopicTaskReq
  ): Promise<IAdminCourseTopicTaskRes> {
    return this.post([RootPath.admin, RootPath.course, RootPath.topic, id, RootPath.task], req)
  }

  editAdminCourseTopicTask(taskId: number, req: IAdminCourseTopicTaskReq): Promise<any> {
    return this.put([RootPath.admin, RootPath.task, taskId], req)
  }

  attachAdminTaskToCourseTopic(
    courseTopicId: number,
    taskId: number,
    req: IAdminCourseTopicReq
  ): Promise<boolean> {
    return this.put(
      [RootPath.admin, RootPath.course, RootPath.topic, courseTopicId, RootPath.task, taskId],
      req
    )
  }

  deleteAdminCourseTopicTask(courseTopicId: number, taskId: number): Promise<number> {
    return this.delete([
      RootPath.admin,
      RootPath.course,
      RootPath.topic,
      courseTopicId,
      RootPath.task,
      taskId
    ])
  }

  addCourseTopicResources(req: ICourseTopicResources): Promise<any> {
    return this.post([RootPath.admin, RootPath.courseTopicResources], req)
  }

  removeCourseTopicResource(req: IRemoveCourseTopicResource): Promise<any> {
    return this.delete([RootPath.admin, RootPath.courseTopicResource], req)
  }

  /**
   *
   *  @return Learner API
   */
  getAllLearners(): Promise<User[]> {
    return this.get([RootPath.tutor, RootPath.learners])
  }

  // editAdminCourse(id: number, req: IAdminCourseAddReq): Promise<boolean> {
  //   return this.put([RootPath.admin, RootPath.course, id], req)
  // }

  updateUser(id: number, req: any): Promise<User> {
    return this.post([RootPath.admin, RootPath.user, id], req)
  }

  getUser(id: number): Promise<User> {
    return this.get([RootPath.admin, RootPath.user, id])
  }

  getAllUsers() {
    return this.get([RootPath.admin, RootPath.users])
  }

  createNewUser(req: User): Promise<User[]> {
    return this.post([RootPath.admin, RootPath.user], req)
  }

  getLearnerCourses(id: number): Promise<ICourseResponse[]> {
    return this.get([RootPath.tutor, RootPath.learner, id, RootPath.courses])
  }

  getLearnerAllCourses(): Promise<ICourseResponse[]> {
    return this.get([RootPath.learner, RootPath.courses])
  }

  assignLearnerCourses(req: IAssignCourses): Promise<IAssignCoursesRes[]> {
    return this.post([RootPath.admin, RootPath.courses], req)
  }

  removeLearnerCourse(req: IRemoveLearnerCourse): Promise<any> {
    return this.delete([RootPath.admin, RootPath.course], req)
  }

  getLearnerCourseTopics(learnerId: number, courseTopicId: number): Promise<any> {
    return this.get([
      RootPath.tutor,
      RootPath.learner,
      learnerId,
      RootPath.course,
      RootPath.topic,
      courseTopicId
    ])
  }

  getLearnerViewCourseTopics(courseTopicId: number): Promise<any> {
    return this.get([RootPath.learner, RootPath.courseTopic, courseTopicId])
  }

  updateLearnerTask(
    taskId: number,
    // req: Pick<ICourseTopicTask,'task_learner'>
    req: ITaskLearner
  ): Promise<any> {
    return this.put([RootPath.course, RootPath.topic, RootPath.task, taskId], req)
  }

  tutorUpdateLearnerTask(learnerId: number, taskId: number, req: ITaskLearner): Promise<any> {
    return this.put(
      [
        RootPath.tutor,
        RootPath.learner,
        learnerId,
        RootPath.course,
        RootPath.topic,
        RootPath.task,
        taskId
      ],
      req
    )
  }

  getLearnerSelfCourses(): Promise<ICourseResponse[]> {
    return this.get([RootPath.learner, RootPath.courses])
  }

  getLearnerSelfCoursesTopic(id: number): Promise<ICourseTopicResponse[]> {
    return this.get([RootPath.learner, RootPath.courseTopic, id])
  }
  
  getStudentTimeSheet(id:number):Promise<any>{
    return this.get([RootPath.timesheet,id])
  }

  /**
   *
   *  @return Notification API
   */
  getTutorNotifications(): Promise<any> {
    return this.get([
      RootPath.tutor,
      RootPath.notification
    ])
  }

  removeTutorNotification(
    learnerId: number,
    taskId: number,
    req: INotification
  ): Promise<any> {
    return this.put([
      RootPath.tutor,
      RootPath.learner,
      learnerId,
      RootPath.task,
      taskId,
      RootPath.notification
    ], req)
  }

  getLearnerNotifications() : Promise<any> {
    return this.get([
      RootPath.learner,
      RootPath.notification
    ])
  }

  removeLearnerNotification(
    taskId: number,
    req: INotification
  ): Promise<any> {
    return this.put([
      RootPath.learner,
      RootPath.task,
      taskId,
      RootPath.notification
    ], req)}
    
  getTutorTimeSheet(id:number):Promise<any>{
    return this.get([RootPath.timesheet,RootPath.tutor,id])
  }

  addTimeSheet(req: any): Promise<any> {
    return this.post([RootPath.timesheet], req)
  }

  deleteTimeSheet(id:string):Promise<any>{
    return this.delete([RootPath.timesheet,id])
  }

  /**
   *
   *  @return Config API
   */
  getConfig(): Promise<Config> {
    return this.get(RootPath.getappconfiginfo)
  }

  getTags(): Promise<ITagResponse[]> {
    return this.get(RootPath.tags)
  }

  deleteTag(req: { tagId: number }): Promise<any> {
    return this.delete([RootPath.admin, RootPath.tag], req)
  }

  editTag(req: ITagFormData): Promise<ITagFormData> {
    return this.put([RootPath.admin, RootPath.tag], req)
  }

  updateProfile(req: any): Promise<any> {
    return this.post(RootPath.userUpdateProfile, req)
  }
}

const apiService = new ApiService()

export default apiService