import { useContext } from 'react'
import { DashboardContext } from '../../pages/dashboard-page/dashboard-components/context/DashboardContext'
import * as MUI from '../../MUI'
import UserDialog from './UserDialog'
import apiService from '../../services/api-services'
import { useEffect, useState } from 'react'
import { User, Notify } from '../../interface'
import { IUserFieldProps } from '../../interface'
import { useLocation } from 'react-router-dom'
import './DashboardUserCard.css'
import IconButton from '../../components/button/IconButton'
import { imageBaseUrl } from '../../constants/constants'

type LearnerId = Pick<User, 'id'>

type UserDetails = {
  learnerId: LearnerId
  learners: User[]
}

const DashboardUserCard = (props: UserDetails) => {
  const { learnerId, learners } = props

  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')
  const [user_avatar, setUserAvatar] = useState<any>('')
  const [callSnackBar, setCallSnackBar] = useState(false)

  const [password_length, setPasswordLength] = useState(0)
  const [password_caps, setPasswordCaps] = useState(0)
  const [password_num, setPasswordNum] = useState(0)

  const [first_name_error, setFirstNameError] = useState(false)
  const [last_name_error, setLastNameError] = useState(false)
  const [password_error, setPasswordError] = useState(false)
  const [confirm_password_error, setConfirmPasswordError] = useState(false)

  const { userDialogOpen, setUserDialogOpen, email, setEmail } = useContext(DashboardContext)

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: 'error'
  })

  let userDialogForm: IUserFieldProps[] = [
    {
      error: first_name_error,
      helperText: 'First Name length is ' + first_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'first_name',
      label: 'First Name',
      type: 'string',
      fullWidth: true,
      value: first_name,
      select: false,
      onChange: (e: string) => handleFirstNameChange(e),
      required: false
    },
    {
      error: last_name_error,
      helperText: 'Last Name length is ' + last_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'lasts_name',
      label: 'Last Name',
      type: 'string',
      fullWidth: true,
      value: last_name,
      select: false,
      onChange: (e: string) => handleLastNameChange(e),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'email',
      label: 'Email',
      type: 'string',
      fullWidth: true,
      value: email,
      select: false,
      onChange: (e: string) => setEmail(e),
      required: false
    },
    {
      error: password_error,
      helperText:
        'Min password length: ' +
        password_length +
        '/8. ' +
        'Min capital letters: ' +
        password_caps +
        '/2. ' +
        'Min numbers: ' +
        password_num +
        '/2.',
      disabled: false,
      margin: 'dense',
      id: 'password',
      label: 'New Password',
      type: 'password',
      fullWidth: true,
      value: password,
      select: false,
      onChange: (e: string) => handleChangePassword(e),
      required: false
    },
    {
      error: confirm_password_error,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'confirm_password',
      label: 'Confirm New Password',
      type: 'password',
      fullWidth: true,
      value: confirm_password,
      select: false,
      onChange: (e: string) => handleChangeConfirmPassword(e),
      required: false
    }
  ]

  const handleFirstNameChange = (e: string) => {
    setFirstName(e)
  }

  const handleLastNameChange = (e: string) => {
    setLastName(e)
  }

  const handleChangePassword = (e: string) => {
    setPassword(e)
    setPasswordLength(e.length)
    setPasswordCaps((e.match(/[A-Z]/g) || []).length)
    setPasswordNum((e.match(/[0-9]/g) || []).length)
  }

  const handleChangeConfirmPassword = (e: string) => {
    setConfirmPassword(e)
  }

  const getUser = (id:number) => {
    return learners?.filter((data) => data.id === id)[0]
  }

  useEffect(() => {
    if (learnerId !== undefined) {
      let user = getUser(learnerId.id)
      setFirstName(user?.first_name!)
      setLastName(user?.last_name!)
      setEmail(user?.email!)
      if(user.user_avatar){
        setUserAvatar(imageBaseUrl + user?.user_avatar)
      }
    }
  }, [])

  const handleOpen = () => {
    setUserDialogOpen(!userDialogOpen)
  }

  const handleCancel = () => {
    if (learnerId !== undefined) {
      let user = getUser(learnerId.id)
      setFirstName(user?.first_name!)
      setLastName(user?.last_name!)
      setEmail(user?.email!)
      if(user.user_avatar){
        setUserAvatar(imageBaseUrl + user?.user_avatar)
      }
    }

    setFirstNameError(false)
    setLastNameError(false)
    setPasswordError(false)
    setConfirmPasswordError(false)
    setUserDialogOpen(!userDialogOpen)
  }

  const handleClose = () => {
    setUserDialogOpen(!userDialogOpen)
  }

  const handleSave = () => {
    if (userDataValidation() === true) {
      console.log('Form Data')
      formData()
    }
  }

  const formData = () => {
    let formdata = new FormData()
    formdata.append('first_name', first_name)
    formdata.append('last_name', last_name)
    formdata.append('email', email)
    if (password != '' && passwordValidation() === true) {
      formdata.append('password', password)
    }
    formdata.append('_method', 'PUT')
    if (learnerId !== undefined) {
      apiService
        .updateUser(learnerId.id, formdata)
        .then((res) => {
          console.log('res ', res)
          if (res) {
            setNotify({
              isOpen: true,
              message: 'Success! Changes to the user have been saved.',
              type: 'success'
            })
            setCallSnackBar(!callSnackBar)
          }
        })
        .catch((err) => {
          console.log('Fail! Changes to the user have not been saved.', err)
          setNotify({
            isOpen: true,
            message: 'Fail! Changes to the user have not been saved.',
            type: 'error'
          })
          setCallSnackBar(!callSnackBar)
        })
    }
    setTimeout(() => {
      setCallSnackBar(!callSnackBar)
      handleClose()
    }, 2000)
  }

  const userDataValidation = () => {
    const first_name_valide = firstNameValidation()
    const last_name_valid = lastNameValidation()
    const password_valid = passwordValidation()
    const confirm_password_valid = confirmPasswordValidation()
    if (
      first_name_valide === false ||
      last_name_valid === false ||
      password_valid === false ||
      confirm_password_valid === false
    ) {
      return false
    } else {
      return true
    }
  }

  const firstNameValidation = () => {
    if (first_name.length > 10) {
      setFirstNameError(true)
      return false
    } else {
      setFirstNameError(false)
      return true
    }
  }

  const lastNameValidation = () => {
    if (last_name.length > 10) {
      setLastNameError(true)
      return false
    } else {
      setLastNameError(false)
      return true
    }
  }

  const passwordValidation = () => {
    if (password != '') {
      if (
        password.length < 8 ||
        (password.match(/[A-Z]/g) || []).length < 2 ||
        (password.match(/[0-9]/g) || []).length < 2
      ) {
        setPasswordError(true)
        return false
      } else {
        setPasswordError(false)
        return true
      }
    } else {
      return true
    }
  }

  const confirmPasswordValidation = () => {
    if (confirm_password != password) {
      setConfirmPasswordError(true)
      return false
    } else {
      setConfirmPasswordError(false)
      return true
    }
  }

  return (
    <>
      <div id='User-Details-Dashboard-Page'>
        <div className='header'></div>
        <MUI.Stack
          sx={{ position: 'relative', top: -80 }}
          direction='row'
          spacing={2}
          justifyContent={'center'}
        >
          <MUI.Avatar
            src={user_avatar ? user_avatar : ''}
            sx={{ position: 'absolute', width: 200, height: 200 }}
          />
        </MUI.Stack>

        <MUI.Stack
          className='user-name-stack'
          direction='row'
          spacing={2}
          justifyContent={'center'}
        >
          <span>
            {first_name} {last_name}
          </span>
        </MUI.Stack>

        <MUI.Stack className='user-info-stack' spacing={3}>
          <span>
            <MUI.EmailIcon className='user-info-icon' />
            {email}
          </span>
        </MUI.Stack>

        <MUI.Stack direction='row' justifyContent={'center'} style={{marginBottom:'30px'}}>
          <IconButton size='large' variant='contained' onClick={() => handleOpen()} text='Edit' />
        </MUI.Stack>
      </div>

      <UserDialog
        isOpen={userDialogOpen}
        handleClose={handleClose}
        handleCancel={handleCancel}
        handleSave={handleSave}
        label={'Edit My Account'}
        notifyIsOpen={notify.isOpen}
        notifyMessage={notify.message}
        notifyType={notify.type}
        children={userDialogForm}
        callSnackBar={callSnackBar}
        avatarChangeFunction={true}
        avatar={user_avatar}
        setAvatar={setUserAvatar}
        adminUserId={learnerId!.id}
      />
    </>
  )
}
export default DashboardUserCard
