import { Grid } from "@material-ui/core"
import { useEffect,useState } from "react"
import { useLocation } from 'react-router-dom'
import * as MUI from "../../MUI"

type searchByTagsProps = {
  tagList: any
  setSearchParams: Function
  selectedTags: string[]
  setSelectedTags: Function
}

const SearchByTags = ({
  tagList,
  setSearchParams,
  selectedTags,
  setSelectedTags,
}: searchByTagsProps) => {
  useEffect(() => {
    setSearchParams({ tags: selectedTags.join("_") })
  }, [selectedTags, setSearchParams])
  
  const location = useLocation();
  const regex = /[^a-z][a-z]{4}[^a-z]/;
  const subst = ``;

  useEffect(() => {
    if (location.search){
      let params = location.search.replace(regex, subst);
      setSelectedTags([params])
      if (params.search("_") != -1){
        setSelectedTags(params.split("_"))
      }
    }
  }, [])
  
  const handleChange = (
    event: MUI.SelectChangeEvent<typeof selectedTags>
  ) => {
    const {
      target: { value },
    } = event
    setSelectedTags(typeof value === "string" ? value.split(",") : value)
  }

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handler = () => {
      setIsOpen(false);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return (
        <div className='resource-select'>
          <MUI.FormControl sx={{ m: 1, width: 300 }}>
            <MUI.InputLabel>Search by Tags</MUI.InputLabel>
            <MUI.Select
              open={isOpen}
              onOpen={() => {
                setIsOpen(true)
              }}
              onClose={() => {
                setIsOpen(false)
              }}
              multiple
              value={selectedTags}
              onChange={handleChange}
              input={<MUI.FilledInput />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{
                disableScrollLock: true,
                PaperProps: { style: { maxHeight: 224, width: 250 } }
              }}
            >
              {tagList.map((tag: any) => (
                <MUI.MenuItem key={tag.id} value={tag.title}>
                  <MUI.Checkbox checked={selectedTags.indexOf(tag.title) > -1} />
                  <MUI.ListItemText primary={tag.title} />
                </MUI.MenuItem>
              ))}
            </MUI.Select>
          </MUI.FormControl>
        </div>
  )
}

export default SearchByTags
