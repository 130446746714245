import { createContext } from 'react'

export type DashboardContextProps = {
  submit: boolean
  setSubmit: (arg: boolean)=>void
  assignCourseState: boolean
  courseCheckbox: JSX.Element | null
  userDialogOpen: boolean
  email: string
  setEmail: (arg: string) => void
  setUserDialogOpen: (arg: boolean)=> void
}

export const DashboardContext = createContext<DashboardContextProps>({
  submit: false,
  setSubmit: ()=>{},
  assignCourseState: false,
  courseCheckbox: null,
  userDialogOpen: false,
  email: '',
  setEmail: () => {},
  setUserDialogOpen: ()=>{}
})