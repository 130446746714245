import { useRef, useState,useEffect  } from "react";
import { Doughnut } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import apiService from "../../../services/api-services"

import * as MUI from '../../../MUI'
import "./PieChart.css"
import { ICourseResponse, ICourseTopic, ICourseTopicTask } from "../../../interface";
import CourseTopicTask from "../../courses-page/course-components/course-list/CourseTopicTask";

ChartJS.register(ArcElement, Tooltip, Legend)
interface CoursesProps {
  htmlFinish:number
  htmlAll:number
}


const PieChart = (props:CoursesProps) => {
  const {htmlFinish,htmlAll } = props
  const [htmlData, setHtmlData] = useState(0)
  const [htmlLeft, setHtmlLeft] = useState(100)

  const [jsData, setJsData] = useState(0)
  const [jsLeft, setJsLeft] = useState(100)

  const [reactData, setReactData] = useState(0)
  const [reacLeft, setReactLeft]  = useState(100)

  const [backEndData, setBackEndData]  = useState(0)
  const [backEndLeft, setBackEndLeft]  = useState(100)

  const [display, setDisplay] = useState(false)
  
  const chartdata= {
    labels: [
     'HTML/CSS',
     'HTML/CSS',
     'JS',
     'JS',
     'React',
     'React',
     'Backend',
     'Backend'
   ],
   datasets: [{
     label: '',
     data: [htmlData, htmlLeft, jsData, jsLeft, reactData, reacLeft, backEndData, backEndLeft],
     backgroundColor: [
       'rgb(255, 99, 132)',
       'rgb(200, 200, 200)',
       'rgb(255, 205, 86)',
       'rgb(200, 200, 200)',
       'rgb(54, 162, 235)',
       'rgb(200, 200, 200)',
       'rgb(99, 160, 0)',
       'rgb(200, 200, 200)',
     ],
     hoverOffset: 4
   }],
 }
 
 
useEffect(()=>{
  setTimeout(() => {
    setDisplay(true)
   
  }, 2000);
  setHtmlData((htmlFinish*100)/htmlAll)
  
},[htmlFinish,htmlAll,display])




 const prevHtmlDataRef = useRef<number>(htmlData)
 const prevJsDataRef = useRef<number>(jsData)
 const preReactDataRef = useRef<number>(reactData)
 let htmlLeftChange: number 
 let jsLeftChange: number 
 let reactLeftChange: number 
 useEffect(() => {
   //assign the ref's current value to the count Hook
   prevHtmlDataRef.current = htmlData
   htmlLeftChange = 100 - htmlData
   setHtmlLeft(htmlLeftChange)
   prevJsDataRef.current = jsData
   jsLeftChange = 100 - jsData
   setJsLeft(jsLeftChange)
   preReactDataRef.current = reactData
   reactLeftChange = 100 - reactData
   setReactLeft(reactLeftChange)
 }, [htmlData,jsData,reactData])

 

 

  return (
    <div className="chart">
      {/* <MUI.Button variant="outlined" onClick={changeUserOne}>change user1</MUI.Button>
      <MUI.Button variant="outlined" onClick={changeUserTwo}>change user2</MUI.Button>
      <MUI.Button variant="outlined" onClick={changeUserThree}>change user3</MUI.Button> */}
     { display&&<Doughnut data={chartdata} options={
        {
          maintainAspectRatio:false,
      plugins: {
        
        legend: {
          display: false
          
        },
        tooltip: {
          
        }
        
      }
    }}/>
  }
    </div>
  )
}
export default PieChart