import * as MUI from '../../MUI'
import { useEffect, useState } from "react"
import UserDialog from './UserDialog'
import { IUserFieldProps } from '../../interface'
import apiService from "../../services/api-services"
import SettingsIcon from '@mui/icons-material/Settings';
import { setStorage, userKey } from '../../constants/auth'
import { imageBaseUrl } from '../../constants/constants'

const ChangeUserDetails = (props:any) => {
  const {userDialogOpen, setUserDialogOpen} = props
  
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirmPassword] = useState("")
  const [password_length, setPasswordLength] = useState(0)
  const [password_caps, setPasswordCaps] = useState(0)
  const [password_num, setPasswordNum] = useState(0)
  const [user_avatar, setUserAvatar] = useState<any>('')
  const [first_name_error, setFirstNameError] = useState(false)
  const [last_name_error, setLastNameError] = useState(false)
  const [password_error, setPasswordError] = useState(false)
  const [confirm_password_error, setConfirmPasswordError] = useState(false)

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })

  const [callSnackBar, setCallSnackBar] = useState(false)

  useEffect(() => {
    apiService.getLearner().then((res: any) => {
      setFirstName(res.first_name)
      setLastName(res.last_name)
      setEmail(res.email)
      setUserAvatar(imageBaseUrl + res.user_avatar)
    })
  }, [userDialogOpen]) 

  let userDialogForm: IUserFieldProps[] = [
    {
      error: first_name_error,
      helperText: 'First Name length is ' + first_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'first_name',
      label: 'First Name',
      type: 'string',
      fullWidth: true,
      value: first_name,
      select: false,
      onChange: (e: string) => setFirstName(e),
      required: false
    },
    {
      error: last_name_error,
      helperText: 'Last Name length is ' + last_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'lasts_name',
      label: 'Last Name',
      type: 'string',
      fullWidth: true,
      value: last_name,
      select: false,
      onChange: (e: string) => setLastName(e),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'email',
      label: 'Email',
      type: 'string',
      fullWidth: true,
      value: email,
      select: false,
      onChange: (e: string) => setEmail(e),
      required: false
    },
    {
      error: password_error,
      helperText:
        'Min password length: ' +
        password_length +
        '/8. ' +
        'Min capital letters: ' +
        password_caps +
        '/2. ' +
        'Min numbers: ' +
        password_num +
        '/2.',
      disabled: false,
      margin: 'dense',
      id: 'password',
      label: 'New Password',
      type: 'password',
      fullWidth: true,
      value: password,
      select: false,
      onChange: (e: string) => handleChangePassword(e),
      required: false
    },
    {
      error: confirm_password_error,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'confirm_password',
      label: 'Confirm New Password',
      type: 'password',
      fullWidth: true,
      value: confirm_password,
      select: false,
      onChange: (e: string) => handleChangeConfirmPassword(e),
      required: false
    }
  ]

  const userDataValidation = () => {
    const first_name_valide = firstNameValidation()
    const last_name_valid = lastNameValidation()
    const password_valid = passwordValidation()
    const confirm_password_valid = confirmPasswordValidation()
    if (
      first_name_valide === false ||
      last_name_valid === false ||
      password_valid === false ||
      confirm_password_valid === false
    ) {
      return false
    } else {
      return true
    }
  }

  const firstNameValidation = () => {
    if (first_name.length > 10) {
      setFirstNameError(true)
      return false
    } else {
      setFirstNameError(false)
      return true
    }
  }

  const lastNameValidation = () => {
    if (last_name.length > 10) {
      setLastNameError(true)
      return false
    } else {
      setLastNameError(false)
      return true
    }
  }

  const passwordValidation = () => {
    if (password != '') {
      if (
        password.length < 8 ||
        (password.match(/[A-Z]/g) || []).length < 2 ||
        (password.match(/[0-9]/g) || []).length < 2
      ) {
        setPasswordError(true)
        return false
      } else {
        setPasswordError(false)
        return true
      }
    } else {
      return true
    }
  }

  const confirmPasswordValidation = () => {
    if (confirm_password != password) {
      setConfirmPasswordError(true)
      return false
    } else {
      setConfirmPasswordError(false)
      return true
    }
  }

  const handleChangePassword = (e: string) => {
    setPassword(e)
    setPasswordLength(e.length)
    setPasswordCaps((e.match(/[A-Z]/g) || []).length)
    setPasswordNum((e.match(/[0-9]/g) || []).length)
  }

  const handleChangeConfirmPassword = (e: string) => {
    setConfirmPassword(e)
  }

  const handleUserDialogOpen = () => {
    setUserDialogOpen(true)
  }

  const handleUserDialogClose = () => {
    setUserDialogOpen(false)
  }

  const handleSave = () => {
    if (userDataValidation() === true) {
      let formData = new FormData()
      formData.append('first_name',first_name)
      formData.append('last_name',last_name)
      formData.append('email',email)
      formData.append('password', password)
      apiService.updateProfile(formData).then((res) => {
        console.log(res)
        if (res) {
          setStorage(userKey, res);
          setNotify({
            isOpen: true,
            message: 'Success! Change has been saved.',
            type: 'success'
          })
          setCallSnackBar(!callSnackBar)
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: 'Fail! Change has not been saved.',
          type: 'error'
        })
          setCallSnackBar(!callSnackBar)
      })
        setTimeout(() => {
          window.location.reload()
        }, 3000)
    }
  }
  
  return (
    <>
      <MUI.IconButton
        sx={{ borderRadius: 0, padding: '10px 10px', width: '100%', justifyContent: 'flex-start' }}
        onClick={handleUserDialogOpen}
        disableRipple
      >
        <SettingsIcon sx={{ marginRight: '5px' }} fontSize='medium' />
        <div style={{ fontSize: '18.5px', color: 'black' }}>Settings</div>
      </MUI.IconButton>
        <UserDialog
          isOpen={userDialogOpen}
          handleClose={handleUserDialogClose}
          handleCancel={handleUserDialogClose}
          handleSave={handleSave}
          label={'Edit My Account'}
          notifyIsOpen={notify.isOpen}
          notifyMessage={notify.message}
          notifyType={notify.type}
          children={userDialogForm}
          callSnackBar={callSnackBar}
          avatarChangeFunction={true}
          avatar={user_avatar}
          setAvatar={setUserAvatar}
        />
    </>
  )
}
export default ChangeUserDetails