import React, {useEffect, useState} from 'react'
import { useSearchParams } from 'react-router-dom'

export const RemoveQueryParams = (params: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasParams, setHasParams] = useState<boolean>(false)

  useEffect(() => {
    if(params) {
      setHasParams(true)
    }
  },[])

  useEffect(() => {
    if(hasParams) {
      searchParams.delete(params)
      setSearchParams(searchParams)
    }
  }, [hasParams])
}