
class TimeDiff {

  public calculateDays = (day: string) => {
    const d1 = new Date(String(day).slice(0, 10))
    const dayNow = new Date(new Date().toISOString().slice(0,10))

    const diffInMs = dayNow.getTime() - d1.getTime()

    const diffInDays = diffInMs / (1000*60*60*24)


    return diffInDays
  }

  public timeAgo = (date: string) => {
    const currentTime = new Date().getTime()
    const eventTime = new Date(date).getTime()

    const diffTime = currentTime - eventTime

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
  };

    for (const [intervalName, intervalSeconds] of Object.entries(intervals)) {
      const intervalCount = Math.floor(diffTime / (intervalSeconds * 1000));
      if (intervalCount >= 1) {
        return `${intervalCount} ${intervalName}${intervalCount > 1 ? 's' : ''} ago`;
      }
    }

  return 'just now';
  }
}


export const calculateDays = (day: string) => {
  const d1 = new Date(String(day).slice(0, 10))
  const dayNow = new Date(new Date().toISOString().slice(0,10))

  const diffInMs = dayNow.getTime() - d1.getTime()

  const diffInDays = diffInMs / (1000*60*60*24)


  return diffInDays
}

const timeDiff = new TimeDiff()
export default timeDiff
