import ActionMenu from '../../../components/menu/ActionMenu'
import { IMenuProps } from '../../../interface'
import { AppContext } from '../../../context/AppContext'
// import { useStyles } from '../../../pages/dashboard-page/DashBoard'
import React, { useState } from 'react'
import UserData from '../../../dialogs/user-dialog/LocalUserData'
import { useContext } from 'react'
import * as MUI from '../../../MUI'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { imageBaseUrl } from '../../../constants/constants'
import ChangeUserDetails from '../../../dialogs/user-dialog/ChangeUserDetails'

interface NavbarActionMenuProps {
  data: any
  onClickLogout: () => void
}

type Classes = {
  [className: string]: React.CSSProperties
}

const NavbarActionMenu = (props: NavbarActionMenuProps) => {
  const { data, onClickLogout } = props
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const { userRole } = useContext(AppContext)

  const classes: Classes = {
    userMenuTypography: {
      fontSize: '18px',
    },

  }

  const config: IMenuProps = {
    method: {
      buttonId: 'user-profile-button',
      menuId: 'user-profile-menu'
    },
    toggleMenuButton: <MUI.Avatar sx={{width: 30, height: 30}} src={(imageBaseUrl + data.user_avatar) || ''} />,
    childrenMenuItem: [
      {
        component: (
          <div key='display-account'>
            <MUI.ListItemText sx={{ padding: '6px 10px' }}>
              {data.first_Name ? data.first_Name : data.email}
            </MUI.ListItemText>
          </div>
        )
      },
      {
        component: (
          <div key='display-userData' style={{ width: '100%' }}>
            <UserData />
          </div>
        )
      },
      {
        component: (
          <div key='display-userData' style={{ width: '100%' }}>
            <ChangeUserDetails userDialogOpen={userDialogOpen} setUserDialogOpen={setUserDialogOpen}/>
          </div>
        )
      },
      {
        component: (
          <div
            key='sign-out'
            style={{ width: '100%', padding: '10px 10px', display: 'flex' }}
            onClick={() => onClickLogout()}
          >
            <ExitToAppOutlinedIcon
              sx={{ marginRight: '5px' }}
              fontSize='medium'
              style={{ color: 'grey' }}
            />
            <div style={{ fontSize: '18.5px', color: 'black' }}>Sign out</div>
          </div>
        )
      }
    ],
    toggleMenuBtnStyle: {
      paddingRight: 0,
      paddingLeft: 0
    },
    menuStyle: {
      minWidth: 200
    },
    taskMenuListProps: 'user-profile-menu',
    firstName: data.first_name || ''
  }

  return (
    <div style={{ alignItems: 'flex-start', }}>
      <ActionMenu config={config} />
    </div>
  )
}

export default NavbarActionMenu
