import React from "react"
import * as MUI from "../../MUI"
import "./CommonSkeleton.css"


const CommonSkeleton = () => {
  return (
    <MUI.Stack spacing={2}>
      <MUI.Skeleton
        variant="rectangular"
        height={60}
        animation="wave"
        sx={{ maxWidth: "1600px" }}
        className="skeleton-unit"
      />
      <MUI.Skeleton
        variant="rectangular"
        height={30}
        animation="wave"
        // width="90%"
        sx={{ maxWidth: "1600px" }}
        className="skeleton-unit"
      />
      <MUI.Skeleton
        variant="rectangular"
        height={30}
        animation="wave"
        // width="90%"
        sx={{ maxWidth: "1600px" }}
        className="skeleton-unit"
      />
    </MUI.Stack>
  )
}

export default CommonSkeleton
