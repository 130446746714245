import { useEffect, useState } from "react";
import FullCalendar,{DateSelectArg, EventClickArg, EventInput,EventContentArg} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import "./timesheetPage.css"
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import BookSession from "./bookSession";
import { getStorage, userKey } from "../../constants/auth";
import apiService from '../../services/api-services';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import EventView from "./eventView";

const initialData: EventInput[] = [
    {
      id:'1',
      title: 'All-Day Event',
      start: new Date("2023-04-18"),
      end: new Date("2023-04-18"),
      allDay:true
    },
    {
      id:'2',
      title:"Test2",
      start:new Date("2023-04-20 10:00"),
      end: new Date("2023-04-20 10:30"),
    }
  ]

export default function TimesheetPage() {
    const[data, setData] =  useState<EventInput[]>(initialData)
    const[studentTimeSheetData,setStudentTimeSheetData] = useState<Array<any>>([])
    const[globalEventData,setGlobalEventData] = useState<Array<any>>([])
    const[tutorTimeSheetData,setTutorTimeSheetData] = useState<Array<any>>([])
    const[loading,setLoading] = useState<boolean>(true)
    const[showBookSession, setShowBookSession] = useState(false)
    const[bookingOpen, setBookingOpen] = useState(false)
    const[event,setEvent] = useState<any>()
    const[eventViewOpen,setEventViewOpen] = useState(false)
    const[globalEvent,setGlobalEvent] = useState(false)
    const[selectedStartTime, setSelectedStartTime] =  useState(new Date())
    const[selectedEndTime, setSelectedEndTime] =  useState(new Date())
    const[viewType, setViewType] = useState<string>('timeGridWeek');
    const userInfo = getStorage(userKey)

    useEffect(() => {
      if(userInfo.role == 2){
        apiService.getTutorTimeSheet(userInfo.id).then((res) => {
          setTutorTimeSheetData(res)
        })
      }

      if(userInfo.role == 3){
        apiService.getTutorTimeSheet(userInfo.tutor_id).then((res) => {
          setTutorTimeSheetData(res)
        })
      }
    }, [])

    useEffect(() => {
      apiService.getStudentTimeSheet(userInfo.id).then((res) => {
        setStudentTimeSheetData(res)
      })
    }, [])

    useEffect(() => {
      apiService.getStudentTimeSheet(0).then((res) => {
        setGlobalEventData(res)
        setLoading(false)
      })
    }, [])

    useEffect(() => {
      if(!bookingOpen && userInfo.role == 3){
        let timeSheetData = studentTimeSheetData.map((item) => ({
          id:item.id,
          title:item.comments,
          start: item.start,
          end:item.end,
          groupId:item.event,
          url:'./Courses?topicId='+ item.topic_id + '&taskId='+ item.task_id,
        }));

        let globalTimeSheetData = globalEventData.map((item) => ({
          id:item.id,
          title:item.comments,
          start: item.start,
          end:item.end,
          groupId:item.event,
          url:'./Courses?topicId='+ item.topic_id + '&taskId='+ item.task_id,
        }));

        setData(timeSheetData.concat(globalTimeSheetData)) 
      }

      if(bookingOpen && userInfo.role == 3){
        let timeSheetData = tutorTimeSheetData.map((item) => ({
          id:item.id,
          title:item.comments,
          start: item.start,
          end:item.end,
          groupId:item.event
        }));

        let globalTimeSheetData = globalEventData.map((item) => ({
          id:item.id,
          title:item.comments,
          start: item.start,
          end:item.end,
          groupId:item.event
        }));

        setData(timeSheetData.concat(globalTimeSheetData)) 
      }

      if(userInfo.role == 2){
        let timeSheetData = tutorTimeSheetData.map((item) => ({
          id:item.id,
          title:item.comments,
          start: item.start,
          end:item.end,
          groupId:item.event
        }));

        let globalTimeSheetData = globalEventData.map((item) => ({
          id:item.id,
          title:item.comments,
          start: item.start,
          end:item.end,
          groupId:item.event
        }));

        setData(timeSheetData.concat(globalTimeSheetData)) 
      }
    }, [bookingOpen,studentTimeSheetData,tutorTimeSheetData,globalEventData])
  
    const handleSelect = (e:DateSelectArg) => {
      setSelectedStartTime(e.start)
      setSelectedEndTime(e.end)
      console.log(e.start)
      console.log(e.end)
      setShowBookSession(true);
    }

    const eventContent = (e:EventContentArg) => {
      return(
      <> 
        <div className="eventContent">{e.event.title}</div>
      </>
      ) 
    }
 
    const handleEvent = (e:EventClickArg) =>{
      e.jsEvent.preventDefault();
        if(!bookingOpen){
          setEvent(e.event)
          console.log(e.event.url)
          setEventViewOpen(true)
        }
    }

    const getTitle = () => {
      let titleText = 'My Calendar';
      if (bookingOpen || globalEvent){
        titleText = 'Drag to Pick a Period'
      }
      return titleText;
    };

    const handleViewChange = (viewInfo: any) => {
      setViewType(viewInfo.view.type);
    }

    return (
      <>
      {loading?
        <Box sx={{ display: 'block', textAlign:'center', marginTop:'300px'}}>
          <CircularProgress />
        </Box> 
      :        
      <div style={{marginTop:'30px',marginBottom:'30px'}}>
        <FullCalendar
            plugins = {[timeGridPlugin,dayGridPlugin, interactionPlugin, listPlugin]}
            events = {data}
            eventContent = {eventContent}
            // slotMinTime='09:00:00'
            // slotMaxTime='23:59:59'
            selectable={bookingOpen || globalEvent? true : false}
            select = {handleSelect}
            eventClick = {handleEvent}//delete event 
            defaultTimedEventDuration={'00:30:00'}
            customButtons={{
              appointment: {
                text: bookingOpen? 'Done':'Appointment',
                click: () => setBookingOpen(!bookingOpen),
              },
              globalEvent:{
                text: globalEvent?'Done':'Add Global Event',
                click: () => setGlobalEvent(!globalEvent),
              },
            }}
            buttonText= {{
                today: "Today",
                month: "Month",
                week: "Week",
                day: "Day",
                list: "List",
              }} 
            headerToolbar={{
              left: viewType.includes('listMonth') || viewType.includes('dayGridMonth') ? 'prev,next today':
              userInfo.role == 3? 'prev,next today appointment':'prev,next today globalEvent',
              center:'title',
              end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth"
            }}
            titleFormat={getTitle}
            viewDidMount={handleViewChange}
          />
        {bookingOpen || globalEvent? 
        <BookSession open={showBookSession} setOpen={setShowBookSession} selectedStartTime={selectedStartTime} setSelectedStartTime={setSelectedStartTime} selectedEndTime={selectedEndTime} setSelectedEndTime={setSelectedEndTime} globalEvent={globalEvent}/> 
        : ''}
        {eventViewOpen ?
        <EventView open={eventViewOpen} setOpen={setEventViewOpen} event={event}/>
        :''}
      </div>
      }
      </>
    ); 
};
