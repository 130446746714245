import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import apiService from '../../services/api-services';
import Typography from '@mui/material/Typography';
import TextButton from '../../components/button/TextButton';
import { getStorage, userKey } from "../../constants/auth";

interface EventViewProp {
  open:boolean,
  setOpen:Function,
  event:any,
}

export default function EventView(props:EventViewProp) {
  const {open,setOpen,event} = props
  const userInfo = getStorage(userKey)

  const handleClose = () => {
    setOpen(false);
  };

  const formattedDate = (date:Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  
  const handleDelete = () => {
      apiService.deleteTimeSheet(event.id).then((res)=>{
        console.log(res)
        window.location.reload()
      })
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{event.title}</DialogTitle>
        <DialogContent style={{width:'500px'}}>

        {event.groupId.includes('Task Due')? 
          <Typography variant="body1">
           	Deadline: {formattedDate(event.end)}
          </Typography>
          :
          <Typography variant="body1">
          Time Period: {formattedDate(event.start)} —— {formattedDate(event.end)}
        </Typography>
          }

        </DialogContent>
        <DialogActions>
          {event.groupId.includes('Task Due') || (event.groupId.includes('Plenary Session') && userInfo.role ==3)? '' : 
          <TextButton onClick={handleDelete} text={'Delete'} color={'error'}/>}
          {event.groupId.includes('Task Due') ? 
          <TextButton onClick={()=>{window.location.replace(event.url)}} text={'Open'} /> : ''}
          <TextButton onClick={handleClose} text={'OK'}/>
        </DialogActions>
      </Dialog>
    </div>
  );
}