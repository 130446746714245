import { useState } from "react"

export const useFormValidation = () => {
  const [error, setError] = useState({} as any)
  
  const formIsEmpty = (formData: any) => {
    const isEmpty = Object.values(formData).some(el => el === '')

    return isEmpty
  }

  return {
    error,
    setError,
    formIsEmpty
  }
} 