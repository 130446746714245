import { useState } from "react"
import * as MUI from "../../MUI"
import "./BackTop.css"
import { useLocation } from 'react-router-dom'

const BackTop = (props: any) => {
  const {DashBoardTabValue} = props
  const [visible, setVisible] = useState<boolean>(false)
  const { pathname } = useLocation()


  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  window.addEventListener("scroll", toggleVisible)

  return (
    <MUI.Fab
      onClick={scrollToTop}
      size="small"
      className="back-to-top"
      sx={
      DashBoardTabValue == 1?
      {display: 'block', bottom:{xs:'135px',md:'80px'}} :
      {display: 'block'}
    }
      style={{
        visibility: visible ? "visible" : "hidden",
        transition: "all .2s",
        opacity: visible ? "1" : "0",
      }}
    >
      <MUI.VerticalAlignTop />
    </MUI.Fab>
  )
}
export default BackTop
