import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Textarea from '@mui/joy/Textarea';
import apiService from '../../../../services/api-services';
import TextButton from '../../../../components/button/TextButton';
import { ICourseTopicTask } from '../../../../interface'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getStorage, userKey } from '../../../../constants/auth'
import LinearProgress from '@mui/material/LinearProgress';

interface TaskDeadlineProp {
  open:boolean
  setOpen:Function
  data: ICourseTopicTask
  learnerId: number
  setApiLearnerTimesheet: Function
}

export default function SetTaskDeadline(props:TaskDeadlineProp) {
  const {open,setOpen,data, learnerId, setApiLearnerTimesheet} = props
  const [value, setValue] = useState<Dayjs | null>()
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState<string>('')
  const userInfo = getStorage(userKey)

  const handleClose = () => {
    setOpen(false);
  };

  const formattedDate = (date:Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  
  const handleSubmit = () => {
    setLoading(true)
    const endTime = value?.toDate()
    const startTime = new Date(endTime!.getTime() - 30 * 60 * 1000)
    const deadlineData = new FormData()
    deadlineData.append('start', formattedDate(startTime))
    deadlineData.append('end', formattedDate(endTime!))
    deadlineData.append('event','Task Due')
    deadlineData.append('comments', 'Task Due: ' + data.title + ' '+ comments)
    deadlineData.append('task_id', data.id.toString())
    deadlineData.append('topic_id',data.pivot!.course_topic_id.toString())
    deadlineData.append('user_id', learnerId.toString())

    apiService.addTimeSheet(deadlineData).then((res) => {
      const userId: number = learnerId ? learnerId : userInfo.id;
      apiService.getStudentTimeSheet(userId).then((res) => {
        setApiLearnerTimesheet(res);
        setLoading(false)
        setOpen(false)
      });
      })
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set Deadline</DialogTitle>
        <DialogContent >

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <p></p>
            <DateTimePicker
          label="Task Deadline"
          value={value}
          onChange={(newValue) => setValue(newValue)}
          ampm={false}
        />
          </LocalizationProvider>

            <p></p>
          <Textarea placeholder="Comments" variant="outlined" 
          onChange={(e) => {setComments(e.target.value)}}/>

        </DialogContent>

        {loading? 
        <LinearProgress /> 
        :         
        <DialogActions>
          <TextButton onClick={handleClose} text={'Cancel'}/>
          <TextButton onClick={handleSubmit} text={'Submit'}/>
        </DialogActions>}
        
      </Dialog>

    </div>
  );
}