import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { authTokenKey, userKey, removeStorage, getStorage } from '../../constants/auth'
import NavbarActionMenu from './components/NavbarActionMenu'
import GradSpaceLogoLight from '../../assets/icons/logo-light.png'
import * as MUI from '../../MUI'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import './Navbar.css'
import MuiToggleButton from '@mui/material/ToggleButton'
import Link from '@mui/material/Link'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { User } from '../../interface'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useObservable } from '../../util/useObservable'
import RealtimeNotification from '../../components/realtime-notification/realtime-notification'

const settings = ['My Account', 'Logout']
const pages = ['Dashboard', 'Resources', 'Courses']
const drawerWidth = '100%'

const Navbar = () => {
  const { userRole } = useContext(AppContext)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: 'error'
  })

  const auth = getStorage(authTokenKey)

  const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'rgba(70,70,70,1)',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }))

  const theme = useTheme()
  const matchesXL = useMediaQuery(theme.breakpoints.down(1601))

  const location = useLocation()
  // const setActive = (path: string) => (path === location.pathname ? 'nav-link active' : 'nav-link')
  const isLoginPage = location.pathname.search('login') !== -1

  //---------------------------------------------------------------------------------------------------//
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const [userInfo, setUserInfo] = useState<User | null>(null)
  const { currentUser } = useContext(AppContext)

  useEffect(() => {
    currentUser && setUserInfo(currentUser)
  }, [])

  const onLogout = () => {
    setNotify({
      isOpen: true,
      message: "You're logged out!",
      type: 'info'
    })
    setTimeout(() => {
      removeStorage(authTokenKey)
      removeStorage(userKey)
      window.location.reload()
    }, 2000)
  }

  return (
    <>
      <MUI.AppBar
        position='sticky'
        sx={{
          backgroundColor: 'white',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
        }}
      >
        <Container
          maxWidth={false}
          style={{ paddingLeft: '30px', paddingRight: '30px', maxWidth: '1600px' }}
        >
          <MUI.Toolbar variant='dense' disableGutters sx={{ maxHeight: '60px', minHeight: '50px' }}>
            <MUI.Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                display: { xs: 'none', sm: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 100,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                paddingBottom: '0.5rem'
              }}
            >
              <img
                src={GradSpaceLogoLight}
                alt='GradSpace Learning System'
                style={{ width: '170px' }}
              />
            </MUI.Typography>

            {auth ? (
              <MUI.List component='nav'>
                <MUI.Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                  <MUI.IconButton
                    color='default'
                    aria-label='open drawer'
                    edge='start'
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <MenuIcon />
                  </MUI.IconButton>
                </MUI.Box>
              </MUI.List>
            ) : (
              ''
            )}

            <MUI.Typography
              variant='h5'
              noWrap
              component='a'
              href=''
              sx={{
                mr: 2,
                display: { xs: 'flex', sm: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img
                src={GradSpaceLogoLight}
                alt='GradSpace Learning System'
                style={{ width: '170px' }}
              />
            </MUI.Typography>

            <MUI.Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
              {!isLoginPage && (
                <>
                  <Link href='/' underline='none' sx={{ paddingTop: '1px' }}>
                    <ToggleButton
                      value='left'
                      aria-label='left aligned'
                      selected={location.pathname === '/Dashboard' ? true : false}
                      sx={{
                        my: 2,
                        color: 'rgba(0,0,0,.55)',
                        textTransform: 'unset',
                        fontSize: '1.1rem',
                        paddingTop: 0,
                        paddingBottom: 0,
                        border: 'none',
                        selected: { backgroundColor: 'none' }
                      }}
                    >
                      Dashboard
                    </ToggleButton>
                  </Link>
                  <Link href='/Resources' underline='none' sx={{ paddingTop: '1px' }}>
                    <ToggleButton
                      value='left'
                      aria-label='left aligned'
                      selected={location.pathname === '/Resources' ? true : false}
                      onClick={() => {
                        sessionStorage.removeItem('Tab')
                        if (sessionStorage.getItem('Selected-Tab')) {
                          sessionStorage.removeItem('Selected-Tab')
                        }
                      }}
                      sx={{
                        my: 2,
                        color: 'rgba(0,0,0,.55)',
                        textTransform: 'unset',
                        fontSize: '1.1rem',
                        paddingTop: 0,
                        paddingBottom: 0,
                        border: 'none',
                        selected: { backgroundColor: 'none' }
                      }}
                    >
                      Resources
                    </ToggleButton>
                  </Link>

                  {userRole() === 'Admin User' || userRole() === 'Normal User' ? (
                    ''
                  ) : (
                    <Link href='/Courses' underline='none' sx={{ paddingTop: '1px' }}>
                      <ToggleButton
                        value='left'
                        aria-label='left aligned'
                        selected={location.pathname === '/Courses' ? true : false}
                        sx={{
                          my: 2,
                          color: 'rgba(0,0,0,.55)',
                          textTransform: 'unset',
                          fontSize: '1.1rem',
                          paddingTop: 0,
                          paddingBottom: 0,
                          border: 'none',
                          selected: { backgroundColor: 'none' }
                        }}
                        onClick={() => sessionStorage.clear()}
                      >
                        Courses
                      </ToggleButton>
                    </Link>
                  )}

                  {userRole() === 'Others' || userRole() === 'Normal User' ? (
                    <Link href='/Timesheet' underline='none' sx={{ paddingTop: '1px' }}>
                      <ToggleButton
                        value='left'
                        aria-label='left aligned'
                        selected={location.pathname === '/Timesheet' ? true : false}
                        onClick={() => {
                          sessionStorage.removeItem('Tab')
                          if (sessionStorage.getItem('Selected-Tab')) {
                            sessionStorage.removeItem('Selected-Tab')
                          }
                        }}
                        sx={{
                          my: 2,
                          color: 'rgba(0,0,0,.55)',
                          textTransform: 'unset',
                          fontSize: '1.1rem',
                          paddingTop: 0,
                          paddingBottom: 0,
                          border: 'none',
                          selected: { backgroundColor: 'none' }
                        }}
                      >
                        Timesheet
                      </ToggleButton>
                    </Link>
                  ) : (
                    ''
                  )}
                </>
              )}
            </MUI.Box>
            {!isLoginPage && userInfo && (
              <>
                <RealtimeNotification />
                <MUI.Box sx={{ flexGrow: 0 }}>
                  <MUI.Tooltip title='Open settings'>
                    <>
                      <NavbarActionMenu data={userInfo} onClickLogout={onLogout} />
                    </>
                  </MUI.Tooltip>
                </MUI.Box>
              </>
            )}
          </MUI.Toolbar>
        </Container>
        <MUI.Box
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', sm: 'none', color: 'rgba(0,0,0,.55)', textTransform: 'unset' }
          }}
        >
          <MUI.Collapse in={mobileOpen} timeout='auto' unmountOnExit sx={{ width: '100%' }}>
            {!isLoginPage && (
              <MUI.List component='div' disablePadding>
                <Link href='/' underline='none' color='GrayText'>
                  <MUI.ListItemButton
                    sx={{ pl: 4, width: '100%' }}
                    selected={location.pathname === '/Dashboard' ? true : false}
                  >
                    <MUI.ListItemIcon>Dashboard</MUI.ListItemIcon>
                  </MUI.ListItemButton>
                </Link>
                <Link href='/Resources' underline='none' color='GrayText'>
                  <MUI.ListItemButton
                    sx={{ pl: 4, width: '100%' }}
                    selected={location.pathname === '/Resources' ? true : false}
                  >
                    <MUI.ListItemIcon>Resources</MUI.ListItemIcon>
                  </MUI.ListItemButton>
                </Link>
                {userRole() === 'Admin User' || userRole() === 'Normal User' ? (
                  ''
                ) : (
                  <Link href='/Courses' underline='none' color='GrayText'>
                    <MUI.ListItemButton
                      sx={{ pl: 4, width: '100%' }}
                      selected={location.pathname === '/Courses' ? true : false}
                    >
                      <MUI.ListItemIcon>Courses</MUI.ListItemIcon>
                    </MUI.ListItemButton>
                  </Link>
                )}
                {userRole() === 'Others' || userRole() === 'Normal User' ? (
                  <Link href='/Timesheet' underline='none' color='GrayText'>
                    <MUI.ListItemButton
                      sx={{ pl: 4, width: '100%' }}
                      selected={location.pathname === '/Timesheet' ? true : false}
                    >
                      <MUI.ListItemIcon>Timesheet</MUI.ListItemIcon>
                    </MUI.ListItemButton>
                  </Link>
                ) : (
                  ''
                )}
              </MUI.List>
            )}
          </MUI.Collapse>
        </MUI.Box>
      </MUI.AppBar>
    </>
  )
}

export default Navbar
