import { BehaviorSubject } from "rxjs";

const subject = new BehaviorSubject("");
const subjectLearner = new BehaviorSubject('')

const initialParams = {
  param: "",
};

let state = initialParams;
// example can be found in ResourceList.tsx and SearchBar.tsx
const useSearchService = {
  search: (params: string) => {
    state.param = params;
    subject.next(state.param);
  },
  searchLearner: (params: string) => {
    state.param = params
    subjectLearner.next(state.param)
  },
  clearParams: () => {
    state.param = "";
    subject.next(state.param);
  },
  subscribe: (setState: any) => subject.subscribe(setState),
  subscribeLearner: (setState: any) => subjectLearner.subscribe(setState),
  initialParams,
};

export default useSearchService;
