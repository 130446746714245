import { useState } from 'react'
import { ICourseTopicResponse, ICourseTopicTask, IMenuProps } from '../../../../interface'
import ActionMenu from '../../../../components/menu/ActionMenu'
import AdminCourseTopicTask from './AdminCourseTopicTask'
import * as MUI from '../../../../MUI'

interface TopicTaskMenuProps {
  data?: ICourseTopicTask
  addTaskData: ICourseTopicResponse
  // index: number
}

const TopicTaskMenu = (props: TopicTaskMenuProps) => {
  const { data, addTaskData } = props
  const [taskOpen, setTaskOpen] = useState(false)
  const taskMethod = {
    addTask: 'add task',
    editTask: 'edit task',
    deleteTask: 'delete task',
  }

  const handleTaskOpen = (clickItem: string) => {
    switch(clickItem) {
      case 'add task':
        setTaskOpen(true)
        break
      case 'edit task':
        setTaskOpen(true)
        break
      case 'delete task':
        setTaskOpen(true)
        break
      default: 
        throw Error('Invalid method')
    }
  }

  const config: IMenuProps = {
    method: {
      buttonId: 'topic-task-menu-button',
      menuId: 'topic-task-menu'
    },
    toggleMenuButton: <MUI.SettingsIcon />,
    childrenMenuItem: [
      {
        component: (
          <AdminCourseTopicTask
            method='add task'
            data={addTaskData}
            taskIsOpen={taskOpen}
            setTaskIsOpen={setTaskOpen}
          >
            Add Task
          </AdminCourseTopicTask>
        )
        // onClickMenuItem() {
        //   handleTaskOpen('add task')
        // }
      },
      {
        component: (
          <AdminCourseTopicTask
            method='edit task'
            data={data}
            taskIsOpen={taskOpen}
            setTaskIsOpen={setTaskOpen}
          >
            <>Edit {data?.title?.toUpperCase()}</>
          </AdminCourseTopicTask>
        )
        // onClickMenuItem() {
        //   handleTaskOpen('edit task')
        // }
      },
      {
        component: (
          <AdminCourseTopicTask
            method='delete task'
            data={data}
            taskIsOpen={taskOpen}
            setTaskIsOpen={setTaskOpen}
          >
            <>Delete {data?.title?.toUpperCase()}</>
          </AdminCourseTopicTask>
        )
        // onClickMenuItem() {
        //   handleTaskOpen('delete task')
        // }
      }
    ],
    toggleMenuBtnStyle: {
      marginTop: '0px'
    },
    taskMenuListProps: 'toggle-topic-task-menu-button'
  }

  const configOnlyBtnAddTask: IMenuProps = {
    method: {
      buttonId: 'topic-task-menu-button',
      menuId: 'topic-task-menu'
    },
    toggleMenuButton: <MUI.SettingsIcon />,
    childrenMenuItem: [
      {
        component: (
          <AdminCourseTopicTask
            method='add task'
            data={addTaskData}
            taskIsOpen={taskOpen}
            setTaskIsOpen={setTaskOpen}
          >
            Add Task
          </AdminCourseTopicTask>
        )
      },
    ],
    toggleMenuBtnStyle: {
      marginTop: '6px',
      height: '40px'
    },
    taskMenuListProps: 'toggle-topic-task-menu-button'
  }

  if(!!data) {
    return <ActionMenu config={config} />
  } else {
    return <ActionMenu config={configOnlyBtnAddTask} />
  }
}

export default TopicTaskMenu
