import React, { useState, useEffect, useContext} from 'react'
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { AppContext } from '../../context/AppContext';

const SearchResultWindow = (props: any) => {
    const {collapseAll,setSearchCourseTopicId, courseResult, topicResult, taskResult, searchBarParams, searchTask, DashBoardTabValue} = props

    const {userRole} = useContext(AppContext)

    const [open, setOpen] = useState(false)

    useEffect(() => {
      setOpen(true)
    }, [searchBarParams])

    const windowOpen = () => {
      return open ? 'block' : 'none'
    }

      const FireNav = styled(List)<{ component?: React.ElementType }>({
        '& .MuiListItemButton-root': {
          paddingLeft: 24,
          paddingRight: 24,
        },
        '& .MuiListItemIcon-root': {
          minWidth: 0,
          marginRight: 16,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 20,
        },
      });
  
      const limitwords = (word:string,searchValue:string) => {
        let index = word.indexOf(searchValue)
        let leftEllipsis
        let rightEllipsis
        if (index > 0){
          leftEllipsis = '... '
        } else {
          leftEllipsis = ''
        }
        if (index + 100 < word.length){
          rightEllipsis = ' ...'
        } else {
          rightEllipsis = ''
        }
        return leftEllipsis+ word.substring(index, index + 100) + rightEllipsis
      }

      
  return (
            <ThemeProvider
              theme={createTheme({
                components: {
                  MuiListItemButton: {
                    defaultProps: {
                      disableTouchRipple: true,
                    },
                  },
                },
                palette: {
                  mode: 'light',
                  background: { paper: '#f8f9fa' },
                },
              })}
            >
              <Paper elevation={0} sx={{maxWidth: 600, width:600, display: { xs: 'none', 
              sm: windowOpen() }}} style={{position:'absolute',zIndex:999, right:0}}>
                <FireNav component="nav" disablePadding>
                  <ListItem component="div" sx={{ height: 20, textAlign:'center', mt:1, mb:1}} disablePadding>
                      <ListItemText
                        primary={'We found ' + (courseResult.length + topicResult.length + taskResult.length) + ' results in the following categories'}
                        primaryTypographyProps={{fontSize:12, fontWeight:'bold'}}
                      />
                  </ListItem>
                  <Divider />
                  <Box>
                      {/* Course Search Result */}
                      <ListItemText
                        primary={courseResult[0]? 'Course' : 'Course: No results'}
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: 'medium',
                          lineHeight: '20px',
                          mt: '10px',
                          mb: '2px',
                          ml: '15px'
                        }}
                        sx={{ my: 0 }}
                      />
                    {courseResult.map((item: { id: any; title: string; description: string; comments: string;}, index: React.Key | null | undefined) => (
                        <ListItemButton
                          key={index}
                          sx={{ py: 0, minHeight: 32}}
                          onClick={()=>{collapseAll();setSearchCourseTopicId({
                            courseId: item.id,
                            topicId: 0
                          });setOpen(false)}}
                        >
                          <ListItemText
                            primary={item.title}
                            primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                            secondary={item.description && item.comments? item.description?.indexOf(searchBarParams.toLowerCase()) != -1 ? limitwords(item.description!,searchBarParams.toLowerCase()) : 
                              item.comments?.indexOf(searchBarParams.toLowerCase()) != -1 ? limitwords(item.comments!,searchBarParams.toLowerCase()):'' : ''}
                          />
                        </ListItemButton>
                      ))}
      
                      {/* Topic Search Result */}
                      <ListItemText
                        primary={topicResult[0]? 'Topic' : 'Topic: No results'}
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: 'medium',
                          lineHeight: '20px',
                          mt: '10px',
                          mb: '2px',
                          ml: '15px'
                        }}
                        sx={{ my: 0 }}
                      />
                    {topicResult.map((topic: { title: string; description: string; id:number; course_id:number},index: React.Key | null | undefined) => (
                        <ListItemButton
                          key={index}
                          sx={{ py: 0, minHeight: 32}}
                          onClick={()=>{collapseAll();setSearchCourseTopicId({
                            courseId: topic.course_id,
                            topicId: topic.id
                          });setOpen(false)}}
                        >
                          <ListItemText
                            primary= {topic.title}
                            primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                            secondary={topic.description? topic.description?.indexOf(searchBarParams.toLowerCase()) != -1 ? limitwords(topic.description!,searchBarParams.toLowerCase()): '':''}
                          />
                        </ListItemButton>
                      ))}
      
                      {/* Task Search Result */}
                      <ListItemText
                        primary={taskResult[0]? 'Task' : 'Task: No results'}
                        primaryTypographyProps={{
                          fontSize: 15,
                          fontWeight: 'medium',
                          lineHeight: '20px',
                          mt: '10px',
                          mb: '2px',
                          ml: '15px'
                        }}
                        sx={{ my: 0 }}
                      />
                    {taskResult.map((task: { title: string; description: string; id:number; pivot:any},index: React.Key | null | undefined) => (
                        <ListItemButton
                          key={index}
                          sx={{ py: 0, minHeight: 32}}
                          onClick={()=>{collapseAll();searchTask(task.id,task.pivot.course_topic_id);setOpen(false)}}
                        >
                          <ListItemText
                            primary= {task.title}
                            primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                            secondary={task.description? task.description?.indexOf(searchBarParams.toLowerCase()) != -1 ? limitwords(task.description!,searchBarParams.toLowerCase()): '':''}
                          />
                        </ListItemButton>
                      ))}
                  </Box>
                </FireNav>
              </Paper>
            </ThemeProvider> 
  )
}
export default SearchResultWindow