import { useState } from 'react'
import { parseVideoUrl } from '../../util/paresVideoUrl'
import * as MUI from "../../MUI"
import "./ResourceStyles.css"
import {ResourceVideo} from '../../interface'

type VideoItem = {
  id: number
  title: string
  description: string
  link_url: string
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


const Videos = ({ videos }: { videos: Array<ResourceVideo>}) => {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)
  
  return (
    <MUI.Box className='r-video-box-root' sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
      <MUI.Tabs className='r-video-tabs-root' orientation="vertical" variant="scrollable" value={value} onChange={handleChange} aria-label="Vertical tabs" sx={{ borderRight: 1, borderColor: 'divider', paddingRight: '20px' }}>
        { videos.map((video, index) => 
          <MUI.Tab label={`${video.title}`} {...tabProps(video.id)} key={index} /> 
        )}
      </MUI.Tabs>
      { videos.map((video, index) => 
        <TabPanel value={value} index={index} key={index}>
          <div id="video-wrap">
            <iframe src={parseVideoUrl(video.link_url)} className="d-block w-100 h-100" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <MUI.Typography id="video-desc" className="mb-3">
              {video.description}
            </MUI.Typography>
          </div>
        </TabPanel>
      )}
    </MUI.Box>
  )
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const tabPanelStyle = {
    width: '100%'
  }

  return (
    <div
      style={tabPanelStyle}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MUI.Box className='r-video-box-root' sx={{ p: 3 }}>
         {children}
        </MUI.Box>
      )}
    </div>
  );
}

const tabProps = (index?: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default Videos