import { IUserFieldProps } from "../../interface"
import { useState, useEffect } from "react"
import { User } from '../../interface'
import * as MUI from "../../MUI"
import { ActiveValue, activeValue, RoleValue, roleValue } from "./UserManagementSelectType"
import Notification from "../../components/notification/notification"
import "./UserDialog.css"
import TextButton from "../../components/button/TextButton"
import ImageCrop from "../../components/image-crop/image-crop"

interface UserDialogProps {
  handleClose: () => void
  handleCancel: () => void
  handleSave: () => void
  isOpen: boolean
  label: string
  notifyIsOpen: boolean
  notifyMessage: string
  notifyType: string
  callSnackBar: boolean
  children: IUserFieldProps[]
  avatarChangeFunction:boolean
  avatar:string
  setAvatar:Function,
  adminUserId?:number
  tutorList?:User[]
}

export default function UserDialog(props: UserDialogProps) {
  const {
    handleClose, 
    handleCancel,
    handleSave, 
    isOpen, 
    label, 
    notifyIsOpen, 
    notifyMessage, 
    notifyType, 
    callSnackBar,
    children,
    avatarChangeFunction,
    avatar,
    setAvatar,
    adminUserId,
    tutorList
  } = props

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: 'error',
  })

  const [imageDialogOpen, setImageDialogOpen] = useState(false)

  useEffect(() => {
    setNotify({
      isOpen: notifyIsOpen,
      message: notifyMessage,
      type: notifyType,
    })
  }, [callSnackBar]) 

  return (
      <MUI.Dialog open={isOpen} onClose={handleClose} fullWidth>

        <MUI.Stack direction="row" justifyContent={"center"}>
          <MUI.DialogTitle>{label}</MUI.DialogTitle>
        </MUI.Stack>

        <hr />
          
          <MUI.DialogContent>
            {children.map((field: IUserFieldProps, i: number) => {
              return (
                <div key={i}>
                  <form autoComplete='off'>
                    <MUI.TextField 
                      error={field.error}
                      helperText={field.helperText}
                      disabled={field.disabled}
                      margin = {field.margin}
                      label = {field.label}
                      type = {field.type}
                      value = {field.type === 'file' ? null : field.value}
                      fullWidth = {field.fullWidth}
                      select = {field.select}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(field.type === 'file' ? (event.target?.files && event.target?.files[0]) : event.target.value)
                      }}
                      required={field.required}
                    >
                    {field.select && field.id === 'is_active' && activeValue.map((active: ActiveValue) => {
                      return (
                        <MUI.MenuItem
                          key={active.is_active}
                          value={active.is_active}
                        >
                          {active.label}
                        </MUI.MenuItem>
                      )
                    })}
                    {field.select && field.id === 'role' && roleValue.map((role: RoleValue) => {
                      return (
                        <MUI.MenuItem
                          key={role.role}
                          value={role.role}
                        >
                          {role.label}
                        </MUI.MenuItem>
                      )
                    })}
                    {tutorList && field.select && field.id === 'tutor' && tutorList!.map((tutor) => {
                            return (
                              <MUI.MenuItem
                                key={tutor.id}
                                value={tutor.id}
                              >
                                {tutor.first_name + ' ' + tutor.last_name}
                              </MUI.MenuItem>
                            )
                          })}
                    </MUI.TextField>
                  </form>
                </div>
              )
            })} 
          </MUI.DialogContent>
        <MUI.Stack style={{marginBottom:'20px'}}className="footer-stack" direction="row" spacing={2} justifyContent={"center"}>
          {avatarChangeFunction ? 
          <TextButton onClick={() => setImageDialogOpen(true)} text='Avatar'/> 
            :''}
          <TextButton onClick={() => handleSave()} text='Save'/>
          <TextButton onClick={() => handleCancel()} text='Cancel'/>
        </MUI.Stack>
        <Notification notify={notify} setNotify={setNotify} />
      <ImageCrop 
      dialogOpen={imageDialogOpen} 
      setDialogOpen={setImageDialogOpen}
      avatar={avatar}
      setAvatar={setAvatar}
      adminUserId={adminUserId}/>
      </MUI.Dialog>   
  )
}