import * as MUI from "../../MUI"
import "./UserCard.css"
import avatarDefault from './avatar-default-img/AvatarDefault.jpeg'
import { imageBaseUrl } from "../../constants/constants"


interface UserDialogProps {
  handleClose: () => void
  handleEdit: () => void
  isOpen: boolean
  first_name: string | number | null
  last_name: string | number | null
  user_email: string | number | null
  user_avatar: string | undefined
}

export default function UserCard(props: UserDialogProps) {
  const {handleClose, handleEdit, isOpen, first_name, last_name, user_email, user_avatar} = props

  return (
      <MUI.Dialog open={isOpen} onClose={handleClose} fullWidth>
        <div className="header"></div>

        <MUI.Stack sx={{ position: 'relative', top: -80}} direction="row" spacing={2} justifyContent={"center"}>
          <MUI.Avatar src={(imageBaseUrl + user_avatar) || avatarDefault} sx={{ position: 'absolute', width: 150, height: 150 }} />
        </MUI.Stack>

        <MUI.Stack sx={{ position: 'relative', top: -70}} className="user-name-stack" direction="row" spacing={2} justifyContent={"center"}>
          <span style={{fontSize:'24px'}}><b>{first_name} {last_name}</b></span>
        </MUI.Stack>

        <MUI.Stack sx={{ position: 'relative', top: -80}} className="user-info-stack" spacing={2}>
          <span><MUI.EmailIcon className="user-info-icon"/>{user_email}</span>
        </MUI.Stack>

        <MUI.Stack sx={{ position: 'relative', top: -80, marginBottom:'-30px'}} direction="row" justifyContent={"center"}>
          <MUI.Button onClick={() => handleEdit()}>Edit</MUI.Button> 
        </MUI.Stack>

      </MUI.Dialog>
  )
}