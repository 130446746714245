import { createContext } from "react"
import { ICourseTopic, ICourseTopicResponse, ICourseTopicTask, ResourcesResponse } from "../../../../interface"

export type CourseTopicContextProps = {
  apiTopic: ICourseTopicResponse[] | undefined
  topicId: number
  courseTopics: ICourseTopic[] | undefined
  tasks: ICourseTopicTask[] | undefined
  topicResources: ResourcesResponse[] | undefined
  setCourseTopics: (arg: ICourseTopic[]) => void
  setApiTopic: (arg: ICourseTopicResponse[] | undefined) => void
  setIsTopicLoading: (arg: boolean) => void
  setTasks: (arg: ICourseTopicTask[] | undefined) => void
  setTopicResources: (arg: ResourcesResponse[]) => void
}

export const CourseTopicContext = createContext<CourseTopicContextProps>({
  apiTopic: [],
  courseTopics: [],
  topicId: 0,
  tasks: [],
  topicResources: [],
  setCourseTopics: () => {},
  setApiTopic: () => {},
  setIsTopicLoading: () => {},
  setTasks: () => {},
  setTopicResources: () => {}
})
