import * as MUI from '../../MUI'
import { iconButtonProps } from '../../interface'
import Tooltip from '@mui/material/Tooltip'
const IconButton = (props: iconButtonProps) => {
  const {
    text,
    onClick,
    color,
    icon,
    variant,
    className,
    size,
    sx,
    component,
    edge,
    startIcon,
    type,
    disable,
    info
  } = props

  if(info){
    return (
      <Tooltip title={info}>
        <MUI.Button
          disabled={disable}
          onClick={onClick}
          color={color}
          className={className}
          variant={variant}
          size={size}
          sx={sx}
          component={component}
          edge={edge}
          startIcon={startIcon}
          type={type}
        >
          {icon}
          {text}
        </MUI.Button>
      </Tooltip>
    ) 
  } else {
    return (
      <MUI.Button
      disabled={disable}
      onClick={onClick}
      color={color}
      className={className}
      variant={variant}
      size={size}
      sx={sx}
      component={component}
      edge={edge}
      startIcon={startIcon}
      type={type}
    >
      {icon}
      {text}
    </MUI.Button>
    )
  }
}

export default IconButton
