import moment from "moment-timezone";

export const customerDonationType = [
  { value: 1, type: "One Off" },
  { value: 2, type: "Weekly" },
  { value: 3, type: "Fortnightly" },
  { value: 4, type: "Monthly" },
  { value: 5, type: "Bi-Monthly" },
];

export const userRole = [
  { value: 1, role: "Admin User" },
  { value: 2, role: "Normal User" },
];

export const getDonationType = (type: number) => {
  return customerDonationType.filter((item: any) => item.value === type)[0]
    ?.type;
};

export const getTransactionStatus = (status: any) => {
  switch (status) {
    case 0:
      return "Declined";
    case 1:
      return "Approved";
    default:
      return "Declined";
  }
};

export const getTransactionRefunded = (refunded: any) => {
  switch (refunded) {
    case 0:
      return "Not Refund";
    case 1:
      return "Refunded";
    default:
      return "Not Refund";
  }
};

export const getAklDate = (date: any) => {
  return moment(date).tz("Pacific/Auckland").format("DD/MM/YYYY");
};

export const getUserRole = (role: number) => {
  switch (role) {
    case 1:
      return "Admin User";
    case 2:
      return "Normal User";
    default:
      return "Others";
  }
};

export const randanColors = [
  "#d34236",
  "#6cb4a8",
  "#eba456",
  "#799c54",
  "#ed9641",
  "#bfc3c3",
  "#f9c611",
  "#d7bfcc",
];

export const imageBaseUrl = 'https://gols.s3.ap-southeast-2.amazonaws.com/'