export const authTokenKey = 'AUTHTOKENKEY'
export const userKey = 'USERKEY'

export const getStorage = (key: any) => JSON.parse(localStorage.getItem(key)!)

export const setStorage = (key: any, value: any) => localStorage.setItem(key, JSON.stringify(value))

export const removeStorage = (key: any) => localStorage.removeItem(key)

export const authHeader = { token: getStorage(authTokenKey) }

export const token = getStorage(authTokenKey)
