import { useState, useEffect, useCallback, useRef} from "react"
import { Editor } from '@tinymce/tinymce-react'
import { services } from "../../services/services"
import apiService  from "../../services/api-services"
import { authHeader } from "../../constants/auth"
import { Video, FormType, ResourceDialogProps,ResourceVideo } from "../../interface/index"
import VideoForm from './VideoForm'
import ConfirmPopup from "../../components/confirm-dialog/confirm-popup"
import CircularProgress from '@mui/material/CircularProgress';
import './ResourceDialogStyles.css'
import * as MUI from "../../MUI"
import { getStorage, userKey } from "../../constants/auth"
import TextButton from '../../components/button/TextButton'
import Notification from "../../components/notification/notification"
import { useLocation } from "react-router-dom"

const parse = (input: string) => input ? input : ''

const formParse = (input: any) => {
  let tagArr: number[] = []
  input.resource_tags.map((tag: any) => tagArr.push(tag.id))
  let output: FormType = {
    title: parse(input.title),
    description: parse(input.description),
    slug: parse(input.slug),
    content: parse(input.content),
    external_link: parse(input.external_link),
    is_active: input.is_active,
    user_id: input.user_id,
    tags: tagArr.length === 0 ? "" : tagArr.join(",")
  }
  return output
}

const defaultForm: FormType = {
  title: "",
  description: "",
  slug: "",
  content: "",
  external_link: "",
  is_active: 1,
  user_id: 0,
  tags: ""
}

const ResourceDialog = ({ dialogOpen, toggleDialog, tagList, setData, dataEdit, setDataEdit, setDialogOpen, isNewCard, toggleNew, setIsNewCard }: ResourceDialogProps) => {
  const [formData, setFormData] = useState<FormType>(defaultForm)
  const [alertDisplay, setAlertDisplay] = useState(false)
  const [initVideoList, setInitVideoList] = useState<Video[]>([])
  const [videoListRaw, setVideoListRaw] = useState<ResourceVideo[]>([])
  const [videoList, setVideoList] = useState<Video[]>([])
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [initFormData, setInitFormData] = useState<FormType>(defaultForm)
  const [videoEdit, setVideoEdit] = useState<boolean | null>(null)
  const [openDirty, setOpenDirty] = useState<boolean>(false)
  const [progressDisplay, setProgressDisplay] = useState<boolean>(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })
  const { pathname } = useLocation()

  if(dialogOpen){
    setTimeout(() => {
      setProgressDisplay(false)
    }, 1500)
  }

  const getResourceVideos = async (id: number) => {
    await apiService.getResources(id).then(res => setVideoListRaw(res[0].resource_videos))
  }
  
  const parseVideos = useCallback((videoListRaw: any[]): any[] => {
    let temp: any[] = []
    for (let video of videoListRaw) {
      temp.push({
        id: video.id,
        title: video.title,
        description: video.description,
        link_url: video.link_url,
      })
    }
    return temp
  }, [])

  /* onInit */
  useEffect(() => {
    if (!dataEdit) return
    getResourceVideos(dataEdit.id)
  }, [dataEdit])
  
  /* Set Dialog Modec & Init Form */
  useEffect(() =>
    dataEdit ? 
      (setFormData(formParse(dataEdit)),
      setInitFormData(formParse(dataEdit)))
    : 
      setFormData(defaultForm),
    [dataEdit]
  )
  
  /* Process Videos */
  useEffect(() =>
    dataEdit
      ? (setVideoList(parseVideos(videoListRaw)),
        setInitVideoList(parseVideos(videoListRaw)))
      : setVideoList([]),
    [dataEdit, parseVideos, videoListRaw]
  )

  /* Form Dirty Status */
  useEffect(() => {
    JSON.stringify(formData) === JSON.stringify(initFormData) &&
    JSON.stringify(videoList) === JSON.stringify(initVideoList)
      ? setIsDirty(false)
      : setIsDirty(true)
  }, [formData, initFormData, initVideoList, videoList])

  /* New card state */
  useEffect(() => {
    isNewCard && setInitFormData(defaultForm) 
      setInitVideoList([])
  }, [isNewCard, toggleNew])

  /* Dialog Control by Cancel bottom */
  const onClose = () => {
    if(isDirty){
      setOpenDirty(true)
    } else {
      setIsNewCard(false)
      setDataEdit(null)
      toggleDialog()
    }
  }

  const toArray = (str: string) => str ? str.split(',') : []

  const toTagsString = (arr: string[]) => arr.length === 0 ? '' : arr.join(',')

  const removeItemFromArray = (item: string, arr: string[]) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === item) {
        arr.splice(i, 1)
      }
    }
  }

  const handleCheckboxChange = (tag_id: string) => {
    const newTags = toArray(formData.tags)
    newTags.includes(tag_id) ? removeItemFromArray(tag_id, newTags) : newTags.push(tag_id)
    setFormData({ ...formData, tags: toTagsString(newTags) })
  }

  const handleVideoEdit = (e: boolean) => setVideoEdit(e)

  const validateVideoForm = (): boolean => {
    for (let video of videoList) {
      if (
        video.title.replace(/ /g, "") === "" ||
        video.link_url.replace(/ /g, "") === ""
      ) {
        return true
      }
    }
    return false
  }

  const videoBulkProcess = (id: number) => {
    if(videoEdit) {
      const newVideos = videoList.filter((data:any) => !data.id).map((data: any) => (!data.order && {...data, order: 0}))
      const oldVideos = videoList.filter((data:any) => data.id)
      if (newVideos.length) {
        apiService.addResourceVideo(
          newVideos,
          id
        )
      }
      apiService.updateResourceVedio(
        oldVideos
      )
    }
  }

  const refreshResourcesList = async () => {
    if (pathname.split('/').length > 2) {
      await apiService.getResources(dataEdit.id).then((res) => setData(res[0]))
    } else {
      await apiService.getAllResources().then((res) => setData(res))
    }
  }
  
  const createResource = () => {
    setFormData(
      { ...formData, 
        user_id: getStorage(userKey).id, 
        content: editorRef.current && editorRef.current.getContent() 
      }
    )
    apiService.addResource(formData)
      .then(res => {
        videoBulkProcess(res.id)
        refreshResourcesList()
        setFormData(defaultForm)
        setVideoList([])
        setNotify({
          isOpen: true,
          message: "Resource created successfully.",
          type: "success",
        })
        toggleDialog()
      })
      .catch(error => setNotify({
        isOpen: true,
        message: "Resource created failed.",
        type: "error",
      }))
  }

  const updateResource = () => {
    apiService.updateResource(
      {
        ...formData,
        user_id: getStorage(userKey).id,
        content: editorRef.current && editorRef.current.getContent()
      }, 
      dataEdit.id)
      .then(res => {
        videoBulkProcess(dataEdit.id)
        refreshResourcesList()
        setDataEdit(null)
        setVideoList([])
        setNotify({
          isOpen: true,
          message: "Resource updated successfully.",
          type: "success",
        })
        toggleDialog()
      })
      .catch(error => setNotify({
        isOpen: true,
        message: "Resource updated failed.",
        type: "error",
      }))
  }

  const handleFormSubmit = () => {
    if (
      formData.title.replace(/ /g, "") === "" ||
      formData.tags.replace(/ /g, "") === "" ||
      validateVideoForm()
    ) {
      setAlertDisplay(true)
    } else {
      setAlertDisplay(false)
      if (isDirty) {
        dataEdit ? updateResource() : createResource()
      }
      setDialogOpen(false)
      setInitFormData(defaultForm)
    }
  }

  /* Rich Text Editor (TinyMCE) */
  const editorRef:any = useRef(null)

  /* ESC key press close method */
  const handleClose = (event:any, reason:any) => {
    if (reason === 'backdropClick') {
      setDialogOpen(true)
      return
    }else if(isDirty) {
      setOpenDirty(true)
    } else {
      setIsNewCard(false)
      setDataEdit(null)
      toggleDialog()
    }
  }

  return (
    <>
    <MUI.Dialog 
      open={dialogOpen} 
      maxWidth={`xl`} 
      fullWidth 
      onClose={handleClose}
      disableEscapeKeyDown = {false}
    >
      <MUI.Collapse in={alertDisplay} className='d-block' style={{minHeight: '40px'}}>
        <MUI.Alert severity="warning">
          <MUI.AlertTitle>Missing required fields</MUI.AlertTitle>
        </MUI.Alert>
      </MUI.Collapse>

      <MUI.Grid container spacing={{ xs: 2, md: 3 }}>
        <MUI.Grid item sm={12} md={true}>
          <MUI.DialogContent >
            <MUI.DialogTitle>Resource Details</MUI.DialogTitle>

            <MUI.TextField 
              value={formData.title} 
              variant="outlined" 
              margin="normal" 
              label="Title" 
              fullWidth 
              autoFocus
              onChange={e => setFormData({ ...formData, title: e.target.value })}
            />
            <div className="resource-required">*required</div>

            <MUI.TextField
              value={formData.description}
              margin="normal"
              label="Description"
              variant="outlined"
              type="text"
              fullWidth multiline
              minRows={3}
              maxRows={5}
              onChange={e => setFormData({ ...formData, description: e.target.value })}
            />

            <div style={{height:300, display: progressDisplay?'flex':'none',justifyContent: 'center',alignItems: 'center'}}>
              <CircularProgress />
            </div>
            <div style={{display: progressDisplay?'none':'block'}}>
            <Editor
              apiKey='poo1h7i6pzm2252c7ljs5s5xcc9ggkskyvkuplkhfvcwl1vq'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={formData.content}
              init={{
                height: 300,
                menubar: false,
                plugins: [                 
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "wordcount",
              ],
                toolbar:                   
                "undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image",
                paste_data_images: true,   
                content_style: "body { font-family:Roboto,sans-serif; font-size:16px }",
                images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
                  let formData = new FormData();
                  let file = blobInfo.blob();
                  formData.append("file",file);
                  const promise = apiService.uploadFile(formData);
                  promise.then(response => {
                    resolve('https://gols.s3.ap-southeast-2.amazonaws.com' + response.url)
                  }).catch(error => {
                    console.error(error);
                  });   
              })
            }}
              onChange={e => setFormData({ ...formData, content: e.target.getContent() })}
            />
            </div>

            <MUI.TextField
              value={formData.external_link}
              margin="normal"
              label="External Link"
              fullWidth
              variant="outlined"
              type="url"
              onChange={e => setFormData(
                { ...formData, external_link: e.target.value }
              )
              }
            />

            <div>Resource Tags</div>
            <div className="resource-required">*required</div>
            <MUI.FormGroup className={"d-flex flex-row"}>
              {tagList.map((tag: any) => (
                <MUI.FormControlLabel
                  key={tag.id}
                  control={
                    <MUI.Checkbox
                      checked={toArray(formData.tags).includes(tag.id.toString())}
                      onChange={() => handleCheckboxChange(tag.id.toString())}
                    />
                  }
                  label={tag.title}
                />
              ))}
            </MUI.FormGroup>
          </MUI.DialogContent>
        </MUI.Grid>
        
        {
          <MUI.Grid item sm={12} md={6}>
            <MUI.DialogContent >
            <VideoForm
                videoList={videoList}
                setVideoList={setVideoList}
                dialogOpen={dialogOpen}
                setAlertDisplay={setAlertDisplay}
                setVideoEdit={handleVideoEdit}
                setInitVideoList={setInitVideoList}
              />
            </MUI.DialogContent>
          </MUI.Grid>
        }
      </MUI.Grid>

      <MUI.DialogActions>
        {!!openDirty ? (
          <div className="dirty-container">
            <ConfirmPopup
              info={{
                message: "Your changes are not saved. Discard all changes?",
                positiveBtn: "Ok",
                negativeBtn: "Back",
              }}
              severity="warning"
              open={openDirty}
              setOpen={setOpenDirty}
              onConfirm={() => {
                setDataEdit(null)
                setFormData(defaultForm)
                setInitFormData(defaultForm)
                setIsNewCard(false)
                toggleDialog()
              }}
              sx={{ position: "inherit", width: "100%"}}
            />
          </div>
        ) : (
          <>
            <TextButton onClick={() => onClose()} text={"Cancel"} />
            <TextButton onClick={() => handleFormSubmit()} text={"Submit"} />
          </>
        )}
      </MUI.DialogActions>
    </MUI.Dialog>
    <Notification notify={notify} setNotify={setNotify} />
    </>
    
  )
}
export default ResourceDialog
