import { BehaviorSubject } from "rxjs";

export type ApiPayload = ErrorPayload | FlagPayload;

export type ErrorPayload = 
  | { error : boolean}
  | { errorMsg : string };

export type FlagPayload =
  | { loading: boolean }
  | { creating: boolean }
  | { created: boolean }
  | { updating: boolean }
  | { updated: boolean }
  | { removing: boolean }
  | { removed: boolean };

export type ApiState = FlagState & ErrorState;

export type ErrorState = {
  error: boolean;
  errorMsg: string;
};

export type FlagState = {
  loading: boolean;
  creating: boolean;
  created: boolean;
  updating: boolean;
  updated: boolean;
  removing: boolean;
  removed: boolean;
};

const initialState = {
  loading: false,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  removing: false,
  removed: false,
  error: false,
  errorMsg: "",
};

const apiSubject = new BehaviorSubject<ApiState>(initialState);

export const apiInfoObservable = () => {
  const loading = (flag: boolean) => {
    setNextState({ loading: flag });
  };

  const creating = (flag: boolean) => {
    setNextState({ creating: flag });
  };

  const created = (flag: boolean) => {
    setNextState({ created: flag });
  };

  const updating = (flag: boolean) => {
    setNextState({ updating: flag });
  };

  const updated = (flag: boolean) => {
    setNextState({ updated: flag });
  };

  const removing = (flag: boolean) => {
    setNextState({ removing: flag });
  };

  const removed = (flag: boolean) => {
    setNextState({ removed: flag });
  };
  
  const error = (flag: boolean) => {
    setNextState({ error: flag });
  };
  
  const errorMsg = (message: string) => {
    setNextState({ errorMsg: message });
  };

  const setNextState = (payload: ApiPayload) => {
    const state = apiSubject.getValue();
    apiSubject.next({ ...state, ...payload });
  };

  const getObservable = () => {
    return apiSubject;
  };

  return {
    loading,
    creating,
    created,
    updating,
    updated,
    removing,
    removed,
    error,
    errorMsg,
    getObservable,
  };
};
