import React, {useEffect, useState, useContext, useCallback} from 'react'
import { ICourseTopicResources, IRemoveCourseTopicResource, ResourcesResponse, Tag } from '../../../../interface'
import { CourseTopicContext } from '../context/CourseTopicContext'
import { useSearchParams } from 'react-router-dom'
import apiService from '../../../../services/api-services'
import ManageTopicResourcesDialog from '../../../../dialogs/resource-dialog/ManageTopicResourcesDialog'
import * as MUI from '../../../../MUI'

export interface topicResourcesResponse extends ResourcesResponse {
  selected: boolean
}

interface AdminTopicResourcesProps {
  // method: 'manage resources'
}

const AdminTopicResources = (props: AdminTopicResourcesProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { apiTopic, topicResources, setTopicResources } = useContext(CourseTopicContext)
  const [apiResources, setApiResources] = useState<ResourcesResponse[]>([])
  const [selectedResources, setSelectedResources] = useState<ResourcesResponse[]>([])
  const [initResources, setInitResources] = useState<topicResourcesResponse[]>([])
  const [removeCourseTopicResource, setRemoveCourseTopicResource] = useState<IRemoveCourseTopicResource>()

  const [tagList, setTagList] = useState<Tag[]>([])
  // const [resourceDialogOpen, setResourceDialogOpen] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const removeQueryParams = () => {
      searchParams.delete('tags')
      setSearchParams(searchParams)
  }

  const compareTwoArrays = () => {
    let hasDifferentItem = false
    const addCourseTopicResources: ICourseTopicResources = { courseTopicId: 0, resourceIds: [] }
    if (apiTopic) {
      const topicResourcesIds: Array<number> = []
      const selectedResourcesIds: Array<number> = []
      topicResources?.map((value) => {
        topicResourcesIds.push(value.id)
      })

      selectedResources.map((value) => {
        selectedResourcesIds.push(value.id)
        addCourseTopicResources.courseTopicId = apiTopic[0].id
        addCourseTopicResources.resourceIds = selectedResourcesIds
      })

      for (let i = 0; i <= topicResourcesIds.length; i++) {
        if (topicResourcesIds[i] !== selectedResourcesIds[i]) hasDifferentItem = true
      }
    }
    return { hasDifferentItem, addCourseTopicResources }
  }

  
  const handleClickOpen = () => {
    setDialogOpen(true)
    removeQueryParams()
  }

  const handleClose = () => {
    const { hasDifferentItem } = compareTwoArrays()

    if (hasDifferentItem || !!removeCourseTopicResource) {
      let previousTopicResourcesIds: ICourseTopicResources = { 
        courseTopicId: 0, 
        resourceIds: [] 
      }
      if(apiTopic) {
        topicResources?.map(value => {
          previousTopicResourcesIds.courseTopicId = apiTopic[0].id
          previousTopicResourcesIds.resourceIds.push(value.id)
        })
      }
      apiService
        .addCourseTopicResources(previousTopicResourcesIds)
        .then((res) => {
          console.log('call post api')
          topicResources && 
          setTopicResources(topicResources)
        })
        .catch((err) => console.log(err))
        console.log('previousTopicResourcesIds', previousTopicResourcesIds)
    }
    
    setDialogOpen(false)
    removeQueryParams()
    const timer = setTimeout(() => {
      setSelectedResources([])
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }

  const fetchAllResources = () => {
    apiService.getAllResources().then(resources => {
      setApiResources(resources)
      if(!!topicResources?.length) {
        setSelectedResources(topicResources)
      }
      handleClickOpen()
    })
  }

  const removeResource = (id: number): void => {
    topicResources?.map(value => {
      value.id === id && value.pivot && 
      setRemoveCourseTopicResource({
        courseTopicId: value.pivot.course_topic_id,
        resourceId: value.id
      })
    })
  }

  const onClickAssignResources = (id: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newResources = initResources.map((resource) => {
      if (resource.id === id) {
        removeResource(resource.id)
        return { ...resource, selected: e.target.checked }
      }
      return resource
    })

    setInitResources(newResources)
    const filteredResources = newResources.filter((value) => value.selected).map(value=>{
      const { selected, ...rest} = value
      return rest
    })
    setSelectedResources(filteredResources)
  }

  const handleSubmit = () => {
    const {hasDifferentItem, addCourseTopicResources} = compareTwoArrays()
    console.log('submit-hasDifferentItem', hasDifferentItem, !!removeCourseTopicResource)
    if (hasDifferentItem || !removeCourseTopicResource) {
      apiService.addCourseTopicResources(addCourseTopicResources).then((res) => {
        console.log('call submit resource api')
        setTopicResources(selectedResources)
      }).catch(err => console.log(err))
    }
    setDialogOpen(false)
    removeQueryParams()
  }

  const filter = useCallback((checkArray: topicResourcesResponse[], checkTag: string | null): topicResourcesResponse[] => {
    if (checkTag) {
      const checkTagArray = checkTag.split('_')
      if(checkTagArray.length === 1) {
        const filteredArray: topicResourcesResponse[] = []
        checkArray.map(item => {
          item.resource_tags?.map(tag => {
            if(checkTagArray[0] === tag.title) {
              filteredArray.push(item)
            }
          })
        })
        return filteredArray
      } else {
        const lastTag: string = checkTagArray[checkTagArray.length-1]
        checkTagArray.pop()
        checkTag = checkTagArray.join('_')
        return filter(filter(checkArray, checkTag), lastTag)
      }
    } else {
      return checkArray
    }
  }, [])
  
  useEffect(() => {
    if(dialogOpen) {
      const modiResources = apiResources.map((resource) => {
        return {
          ...resource,
          selected: !!selectedResources.find((element) => resource.id === element.id)
        }
      })
      setInitResources(modiResources)
    }
  }, [dialogOpen])
  
  useEffect(() => {
    //Remove resource
    if (removeCourseTopicResource) {
      apiService.removeCourseTopicResource(removeCourseTopicResource).then((res) => {
        if (res) console.log('api-removeCourseTopicResource', removeCourseTopicResource)
        setRemoveCourseTopicResource(undefined)
      })
    }
  }, [removeCourseTopicResource])

  useEffect(() => {
      apiService.getConfig().then(res => setTagList(res.tags))
  }, [])

  return (
    <div>
      <MUI.IconButton disableRipple onClick={fetchAllResources}>
        <MUI.EditIcon />
        <div style={{ paddingLeft: '10px', fontSize: '16px', color: 'black' }}>
          Manage Resourses
        </div>
      </MUI.IconButton>
      <ManageTopicResourcesDialog
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        allResources={filter(initResources, searchParams.get('tags'))}
        // allResources={initResources}
        selectedResources={selectedResources}
        handleOnClick={onClickAssignResources}
        handleSubmit={handleSubmit}
        tagList={tagList}
        setSearchParams={setSearchParams}
      />
    </div>
  )
}

export default AdminTopicResources