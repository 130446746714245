import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { getStorage, userKey } from '../../constants/auth'
import { getUserRole } from '../../constants/constants'

export interface userRoleAuthProps {
  children: React.ReactNode
  fallback?: JSX.Element | string 
  pathName: string
  element: 
    | 'progress' 
    | 'button' 
    | 'add course' 
    | 'admin dashboard' 
    | 'learner dashboard'
    | 'display courses'
    | 'display resources'
    // | 'display learner courses'
}

// export type pathName = Pick<userRoleAuthProps, 'pathName'>
// export const userRole = () => getUserRole(getStorage(userKey).role)

const UserRoleAuth = (props: userRoleAuthProps) => {
  const { children, pathName, element } = props
  const {userRole} = useContext(AppContext)

  if (userRole() === 'Admin User' || userRole() === 'Normal User') {
    switch (pathName) {
      case '/Dashboard':
        if (element === 'admin dashboard') return <>{children}</>
        if (element === 'progress') return <>{children}</>
        if (element === 'button') return <>{children}</>
        if (element === 'add course') return <>{children}</>
        if (element === 'display courses') return <>{children}</>
        if (element === 'display resources') return <>{children}</>
        break
      default:
        break
    }
  }

  if(userRole() === 'Others') {
    switch (pathName) {
      case '/Courses' : 
        if(element === 'progress') return <>{children}</>
        if (element === 'display courses') return <>{children}</>
        if (element === 'display resources') return <>{children}</>
        break
      case '/Dashboard' : 
        if(element === 'learner dashboard') return <>{children}</>
        break
    }
  }
      
  return null
}

export default UserRoleAuth
