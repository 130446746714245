import React, { useState, useEffect } from 'react'

type delayedProps = {
  children: JSX.Element
  waitBeforeShow?: number
}

export const delayToDo = (
  callback: ()=> void,
  timeout: number
) => {
  const timer = setTimeout(() => {
    callback()
  }, timeout)

  return () => {
    clearTimeout(timer)
  }
}

const Delayed = (props: delayedProps) => {
  const { children, waitBeforeShow = 50} = props

  const [ isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, waitBeforeShow)
  
    return () => {
      clearTimeout(timer)
    }
  }, [waitBeforeShow])
  
  return isShown? children : null
}

export default Delayed