import { IconButton, ListItem, TextField } from "@mui/material"
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

const EditableRow = (props:any) => {
  const { setEditTagId, tags, handleEditTagChange, editTagData, handleEditTagSubmit } = props
  return (
    <div>
      <ListItem>
        <form>
          <TextField
            id='standard-basic'
            variant='standard'
            name='title'
            sx={{ paddingRight: '30px' }}
            onChange={handleEditTagChange}
            defaultValue={editTagData.title}
          />
          <input type='color' />
          <IconButton type='submit' onClick={handleEditTagSubmit}>
            <CheckOutlinedIcon color='primary' />
          </IconButton>
          <IconButton type='button' onClick={() => setEditTagId(null)}>
            <ClearOutlinedIcon color='primary' />
          </IconButton>
        </form>
      </ListItem>
    </div>
  )
}
export default EditableRow