import { Routes, Route } from "react-router-dom"
import ResourceDetail from "../resource-detail-page/ResourceDetail"
import Resources from "./Resources"
import "./ResourcesPageStyles.css"

const ResourcesPage = () => {
  return (
    <div id="Resource-Page">
      <Routes>
        <Route path="/" element={<Resources />} />
        <Route path="/:id" element={<ResourceDetail />} />
      </Routes>
    </div>
  )
}

export default ResourcesPage
