import { useState, useEffect, useCallback } from 'react'
import Card from './ResourceCard'
import usePagination from '../../components/pagination/usePagination'
import useSearchService from '../../services/useSearchService'
import CommonSkeleton from '../../components/skeleton/CommonSkeleton'
import Grid from '@mui/material/Grid'
import * as MUI from '../../MUI'
import apiService from '../../services/api-services'

type ResourcesProps = {
  data: any
  setData: Function
  searchParams: any
  setSearchParams: Function
  isCardEdit: boolean
  toggleDialog: Function
  setDataEdit: Function
}

const ResourcesList = ({
  data,
  setData,
  searchParams,
  setSearchParams,
  isCardEdit,
  toggleDialog,
  setDataEdit
}: ResourcesProps) => {
  // set the data to display
  const [filteredData, setFilteredData] = useState([])

  // search state using useSearchService
  const [searchBarParams, setSearchBarParams] = useState(useSearchService.initialParams.param)
  useEffect(() => {
    useSearchService.subscribe(setSearchBarParams)
    useSearchService.clearParams()
  }, [])

  //Pagination
  // current page
  const [page, setPage] = useState(1)
  // items in pre page
  const PER_PAGE = 16
  const count = Math.ceil(filteredData.length / PER_PAGE)
  // handling data
  const _DATA = usePagination(filteredData, PER_PAGE)
  const handleChange = (e: any, p: number) => {
    setPage(p)
    _DATA.jump(p)
  }

  //Filter
  // filter data by tags
  const filter = useCallback((checkArray: typeof data, checkTag: string): typeof data => {
    if (checkTag) {
      let checkTagArray = checkTag.split('_')
      if (checkTagArray.length === 1) {
        let filteredArray: typeof checkArray = []
        checkArray.forEach((item: any) => {
          for (let i = 0; i < item.resource_tags.length; i++) {
            if (checkTagArray[0] === item.resource_tags[i].title) {
              filteredArray.push(item)
              break
            }
          }
        })
        return filteredArray
      } else {
        let lastTag: string = ''
        lastTag = checkTagArray[checkTagArray.length - 1]
        checkTagArray.pop()
        checkTag = checkTagArray.join('_')
        return filter(filter(checkArray, checkTag), lastTag)
      }
    } else {
      return checkArray
    }
  }, [])

  // search key word in all data
  const search = useCallback((checkArray: typeof data, searchText: string) => {
    searchText = searchText.toLowerCase()
    let searchedArray: typeof checkArray = []
    for (let i = 0; i < checkArray.length; i++) {
      if (checkArray[i].title) {
        //if key word is not in title then search description
        if (checkArray[i].title.toLowerCase().includes(searchText)) {
          searchedArray.push(checkArray[i])
        } else {
          if (checkArray[i].description) {
            if (checkArray[i].description.toLowerCase().includes(searchText)) {
              searchedArray.push(checkArray[i])
            }
          }
        }
      }
    }
    return searchedArray
  }, [])

  useEffect(() => {
    setFilteredData(search(filter(data, searchParams.get('tags')), searchBarParams))
    setPage(1)
    _DATA.jump(1)
    if (!searchParams.get('tags')) {
      setSearchParams()
    }
  }, [data, searchParams, setSearchParams, filter, search, searchBarParams])

  //Card Delete
  const onDelete = (id: number): void => {
    setData(data.filter((resource: typeof data[0]) => resource.id !== id))
    apiService.deleteResource(id)
  }

  return (
    <>
      {!!filteredData.length ? (
        <>
          <Grid container className='resources-grid'>
            {_DATA.currentData().map((resource: any) => (
              <Card
                key={resource.id}
                data={resource}
                isCardEdit={isCardEdit}
                toggleDialog={toggleDialog}
                setDataEdit={setDataEdit}
                onDelete={onDelete}
              />
            ))}
          </Grid>

          <MUI.Pagination
            count={count}
            size='large'
            page={page}
            variant='text'
            shape='circular'
            onChange={handleChange}
            className='flex content-center mb-1r'
          />
        </>
      ) : (
        <div style={{ paddingTop: '10px' }}>
          {/* <CommonSkeleton /> */}
          <p className='m-2'>No Matching Results.</p>
        </div>
      )}
    </>
  )
}

export default ResourcesList
