import React, { useState, useContext, useEffect } from 'react'
import NewSpinner from '../../../../components/new-spinner/NewSpinner'
import { ICourseTopicTask } from '../../../../interface'
import * as MUI from '../../../../MUI'
import TopicComments from '../components/TopicComments'
import './../course-list/Course.css'
import AdminTaskCodeLink from '../components/AdminTaskCodeLink'
import { CourseTopicContext } from '../context/CourseTopicContext'
import { CourseContext } from '../context/CourseContext'
import { AppContext } from '../../../../context/AppContext'
import UserRoleAuth from '../../../../components/userrole-authentication/UserRoleAuth'
import TopicTaskMenu from '../components/TopicTaskMenu'
import { TaskTabValue } from '../../CoursesPage'
import HandleReviewTask from '../components/HandleReviewTask'
import AvTimerIcon from '@mui/icons-material/AvTimer';
import HandleTaskDeadline from '../components/HandleTaskDeadline'

interface CourseTopicTaskContentProps {
  data?: ICourseTopicTask[]
  learnerId?: number
  apiLearnerTimesheet:any
  setApiLearnerTimesheet:Function
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface TimesheetData {
  id:number;
  task_id: number;
  end: Date;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className='c-tab-panel'
      {...other}
    >
      {value === index && (
        <MUI.Box sx={{ p: 3 }}>
          <MUI.Typography component={'span'}>{children}</MUI.Typography>
        </MUI.Box>
      )}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const CourseTopicTaskContent = (props: CourseTopicTaskContentProps) => {
  const { data, learnerId, apiLearnerTimesheet, setApiLearnerTimesheet} = props

  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const { topicId, apiTopic } = useContext(CourseTopicContext)
  const { pathname } = useContext(CourseContext)
  const { userRole } = useContext(AppContext)

  const hideSpinner = () => {
    setLoading(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setLoading(true)
  }

  useEffect(() => {
        if(TaskTabValue != undefined){
          setValue(TaskTabValue)
          window.scrollTo(0, document.documentElement.scrollHeight)
        }
  }, [TaskTabValue])

  const filterTimesheetData = (taskId:number) => {
    const filterArray = apiLearnerTimesheet.filter((data: TimesheetData) => {
      return data.task_id === taskId;
    });
    const result = filterArray[0] as TimesheetData | undefined;
    if (result) {
      return(result.end)
    }
  }

  const filterTimesheetId = (taskId:number) => {
    const filterArray = apiLearnerTimesheet.filter((data: TimesheetData) => {
      return data.task_id === taskId;
    });
    const result = filterArray[0] as TimesheetData | undefined;
    if (result) {
      return(result.id)
    }
  }
  
  return (
    <>
      <MUI.Box sx={{ width: '100%' }}>
        <MUI.Paper elevation={2} sx={{ padding: '0 15px' }}>
          <MUI.Box
            sx={{
              height: '69px',
              flexGrow: 1,
              bgcolor: 'background.paper',
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex'
            }}
          >
            <MUI.Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
              className='c-topic-task-tabs-root'
              aria-label='tasks-tabs'
              sx={{
                width: '100%',
              }}
            >
              {data?.sort((a,b)=>a.task_code-b.task_code).map((task, index) => {
                return (
                  <MUI.Tab
                    component='div'
                    key={index}
                    sx={{ paddingLeft: 0 }}
                    label={
                      <div style={{ display: 'flex' }}>
                        <div style={{ padding: '15px 0px', whiteSpace: 'normal' }}>
                          {task.title}
                        </div>
                      </div>
                    }
                    {...a11yProps(index)}
                    // label={task.title} {...a11yProps(index)}
                    disableRipple
                    wrapped
                  />
                )
              })}
              {!data && (
                <MUI.Tab
                  component='div'
                  style={{ paddingLeft: 0 }}
                  label={
                      <div style={{ padding: '15px 0px', height: '45px' }}>
                        No Task
                      </div>
                  }
                  disableRipple
                />
              )}
            </MUI.Tabs>

            {
              <UserRoleAuth pathName={pathname} element='button'>
                {!data && !!apiTopic?.length && sessionStorage.getItem('Selected-Tab') === 'Courses' ? (
                  <TopicTaskMenu addTaskData={apiTopic[0]} />
                ) : (
                  data?.sort((a,b)=>a.task_code-b.task_code).map((task, index) => {
                    return (
                      value === index && (
                        <div key={index}>
                          {!!apiTopic?.length &&
                            sessionStorage.getItem('Selected-Tab') === 'Courses' && (
                              <TopicTaskMenu addTaskData={apiTopic[0]} data={task} />
                            )}
                        </div>
                      )
                    )
                  })
                )}
              </UserRoleAuth>
            }
          </MUI.Box>
          {data?.sort((a,b)=>a.task_code-b.task_code).map((task, index) => {
            return (
              <TabPanel key={index} value={value} index={index}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ minHeight: '40px' }}>
                    <MUI.DescriptionIcon sx={{ marginRight: '5px' }} />
                    Description: {task.description}
                  </div>
                  <div style={{ minHeight: '40px' }}>
                    <MUI.AssignmentTurnedInIcon sx={{ marginRight: '5px' }} />
                    Task Type: {task.task_type}
                  </div>
                  <div style={{ minHeight: '40px' }}>
                    <MUI.AccessTimeFilledIcon sx={{ marginRight: '5px' }} />
                    Estimate Hours: {task.estimate_hours}
                  </div>
                  {(sessionStorage.getItem('Tab') === 'Progress' || userRole() === 'Others') &&
                  <div style={{ minHeight: '40px' }}>
                    <MUI.AssignmentIcon sx={{ marginRight: '5px' }} />
                    Task Status:{' '}
                    {!!task.task_learner?.completion_stage
                      ? task.task_learner?.completion_stage
                      : 'unsubmit'}
                  </div>
                  }
                  {task.task_learner?.completion_stage && 
                  <div style={{ minHeight: '40px' }}>
                    <MUI.AccountCircleIcon sx={{ marginRight: '5px' }} />
                    Tutor Review: {
                      task.task_learner?.tutor_review ? 
                      task.task_learner.tutor_review.charAt(0).toUpperCase() + task.task_learner.tutor_review.slice(1) :
                      'Not yet review'
                    }
                  </div>
                  }

                  {/* Task Due */}
                  {
                  <div style={{ minHeight: '40px' }}>
                    <AvTimerIcon sx={{ marginRight: '5px' }} />
                    Task Due: {filterTimesheetData(task.id)?filterTimesheetData(task.id):'Not set'}
                  </div>
                  }

                  {userRole() === 'Others' &&
                    (task.task_learner?.git_url ? ( 
                      <AdminTaskCodeLink 
                        method='remove codelink' 
                        data={task} 
                        disable={task.task_learner?.tutor_review?.length ? true : false} 
                      />
                    ) : (
                      <AdminTaskCodeLink method='upload codelink' data={task} />
                    ))}

                  {/* The Review Button only exist when student submitted a task */}
                  {(userRole() === 'Admin User' || userRole() === 'Normal User') &&
                    task.task_learner?.completion_stage && <HandleReviewTask data={task} 
                    learnerId={learnerId!} due={filterTimesheetData(task.id)} 
                    timesheetId={filterTimesheetId(task.id)}
                    apiLearnerTimesheet={apiLearnerTimesheet} 
                    setApiLearnerTimesheet={setApiLearnerTimesheet}/>}

                  {/* The Deadline Button exist when student unsubmitted a task */}
                  {(userRole() === 'Admin User' || userRole() === 'Normal User') &&
                    !task.task_learner?.completion_stage && <HandleTaskDeadline data={task} 
                    learnerId={learnerId!} due={filterTimesheetData(task.id)} 
                    timesheetId={filterTimesheetId(task.id)}
                    apiLearnerTimesheet={apiLearnerTimesheet}  
                    setApiLearnerTimesheet={setApiLearnerTimesheet}/>}

                  {(userRole() === 'Admin User' || userRole() === 'Normal User') &&
                    // sessionStorage.getItem('Tab') === 'Progress') &&
                    task.task_learner?.tutor_review &&
                    task.task_learner?.git_url && (
                      <IframeComponent
                        loading={loading}
                        url={task.task_learner.git_url}
                        onLoad={hideSpinner}
                      />
                    )}
                  {
                    userRole() === 'Others' && task.task_learner?.git_url && (
                      <IframeComponent
                        loading={loading}
                        url={task.task_learner.git_url}
                        onLoad={hideSpinner}
                      />
                    )
                    // (
                    //   <div style={{ marginTop: '15px' }}>
                    //     <div
                    //       className={`c-topic-task-spinner ${
                    //         loading ? '' : 'c-topic-task-spinner-hidden'
                    //       }`}
                    //     >
                    //       <NewSpinner />
                    //     </div>
                    //     <iframe
                    //       src={task.task_learner.git_url}
                    //       className='d-block mx-auto c-task-code-box'
                    //       title='Task code preview'
                    //       onLoad={hideSpinner}
                    //     ></iframe>
                    //   </div>
                    // )
                  }
                  {JSON.parse(localStorage.getItem('USERKEY')!).role != 3 && !learnerId ? (
                    ''
                  ) : (
                    <TopicComments taskId={task.id} learnerId={learnerId} />
                  )}
                </div>
              </TabPanel>
            )
          })}
        </MUI.Paper>
      </MUI.Box>
    </>
  )
}

export default CourseTopicTaskContent

interface IframeComponentProps {
  loading: boolean
  url: string
  onLoad: ()=>void
}

const IframeComponent = (props: IframeComponentProps) => {
  const {loading, url, onLoad} = props

  return (
    <div style={{ marginTop: '15px' }}>
      <div className={`c-topic-task-spinner ${loading ? '' : 'c-topic-task-spinner-hidden'}`}>
        <NewSpinner />
      </div>
      <iframe
        src={url}
        className='d-block mx-auto c-task-code-box'
        title='Task code preview'
        onLoad={onLoad}
      ></iframe>
    </div>
  )
}
