import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import Filter from './Filter'
import * as MUI from '../../MUI'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '../../components/button/IconButton'
import DoneIcon from '@mui/icons-material/Done'
import SearchIcon from '@mui/icons-material/Search'
import SearchBar from '../../components/search-bar/SearchBar'

const ResourcesControls = ({
  data,
  setData,
  tagList,
  setSearchParams,
  setDataEdit,
  setDialogOpen,
  dialogOpen,
  setIsNewCard,
  toggleNew,
  setCardEdit,
  isCardEdit
}: any) => {
  const { userRole } = useContext(AppContext)
  const toggleDialog = () => setDialogOpen(!dialogOpen)
  const toggleCardEdit = () => setCardEdit(!isCardEdit)

  const newResourceDialog = () => {
    toggleDialog()
    setDataEdit(null)
    setIsNewCard(true)
    toggleNew()
  }

  const [searchOpen, setSearchOpen] = useState(false)
  const handleSearchDrawerToggle = () => {
    setSearchOpen((prevState) => !prevState)
  }

  return (
    <Grid container>
      {/* Serach by Tags */}
      <Grid item xs={12} sm={6} md={6}>
        <Box className='d-flex align-items-center'>
          <Filter
            data={data}
            setData={setData}
            tagList={tagList}
            setSearchParams={setSearchParams}
            needSort={true}
          />
        </Box>
      </Grid>
      {(userRole() === 'Admin User' || userRole() === 'Normal User') && (
        <Grid item xs={12} sm={6} md={6} className='manage-resources'>
          {isCardEdit ? (
            <IconButton
              text='Done'
              onClick={toggleCardEdit}
              color='success'
              icon={<DoneIcon />}
              className='resource-button'
            />
          ) : (
            <IconButton
              text='Edit'
              onClick={toggleCardEdit}
              color='warning'
              icon={<MUI.SettingsIcon />}
              className='resource-button'
            />
          )}

          <IconButton
            text='New'
            onClick={newResourceDialog}
            color='info'
            icon={<MUI.AddIcon />}
            className='resource-button'
          />

          <IconButton
            text='Search'
            onClick={handleSearchDrawerToggle}
            color='success'
            icon={<SearchIcon />}
            className='resource-button'
            sx={{ display: { xs: 'block', sm: 'none' } }}
          />

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SearchBar />
          </Box>
        </Grid>
      )}

      {userRole() === 'Others' && (
        <Grid item xs={12} sm={6} md={6} className='manage-resources'>
          <IconButton
            text='Search'
            onClick={handleSearchDrawerToggle}
            color='success'
            icon={<SearchIcon />}
            className='resource-button'
            sx={{ display: { xs: 'block', sm: 'none' } }}
          />

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SearchBar />
          </Box>
        </Grid>
      )}

      <MUI.Collapse
        in={searchOpen}
        timeout='auto'
        unmountOnExit
        sx={{ width: '100%', display: { xs: 'block', sm: 'none' } }}
      >
        <Box sx={{ width: '90%', ml: 3, mt: 2 }}>
          <SearchBar />
        </Box>
      </MUI.Collapse>
    </Grid>
  )
}

export default ResourcesControls
