import useSearchService from "../../services/useSearchService"
import { useState, useRef } from "react"

interface SearchBarProps {
  text?: string
  id?: string
}

const SearchBar = (props: SearchBarProps) => {
  const { text, id } = props

  const [value, setValue] = useState<string>("")
  const searchBarRef = useRef<HTMLInputElement>()

  const onChange = (e: string) => {
    setValue(e)
    // console.log(searchBarRef.current?.placeholder)
    if(id === 'search-learner' || id === 'search-user'){
      useSearchService.searchLearner(e)
      useSearchService.subscribeLearner(e)
    } else {
      useSearchService.search(e)
      useSearchService.subscribe(e)
    }
  }

  return (
    <div id='search-bar'>
      <svg className='search-icon' aria-hidden='true' viewBox='0 0 24 24'>
        <g>
          <path d='M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z'></path>
        </g>
      </svg>
      <input
        ref={(ref) => {
          if(ref){
            searchBarRef.current = ref
          }
        }}
        id={id}
        className='search-input'
        placeholder={text ? `Search ${text}` : 'Search'}
        type='search'
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
export default SearchBar
