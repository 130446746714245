import React, {useState, useEffect, useContext} from 'react'
import { AppContext } from '../../context/AppContext'
import Badge from '@mui/material/Badge'
import Pusher from 'pusher-js'
import apiService from '../../services/api-services'
import NotificationMenu from './components/notification-menu'
import * as MUI from '../../MUI'

const REACT_APP_PUSHER_APP_KEY = '123456'
const REACT_APP_PUSHER_APP_HOST = 'https://app.spiritx.co.nz/'
const REACT_APP_PUSHER_APP_PORT = 6001
const REACT_APP_PUSHER_APP_CLUSTER = 'mt1'

const RealtimeNotification = () => {
  const {userRole} = useContext(AppContext)
  const [notify, setNotify] = useState({
    user_id: 0,
    notification_status: '',
  })
  const [notifications, setNotifications] = useState<any[]>([])
  const [numOfNotifications, setNumOfNotifications] = useState<number>(0)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClickNotificationBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    // console.log('click Noti...')
  }

  useEffect(() => {
    const userDetails: any = JSON.parse(localStorage.getItem('USERKEY')!)
    // console.log(JSON.parse(userDetails).id)
    // Pusher.logToConsole = true

    const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_APP_CLUSTER,
      wsHost: REACT_APP_PUSHER_APP_HOST,
      wsPort: REACT_APP_PUSHER_APP_PORT,
      enabledTransports: ['ws', 'wss'],
      forceTLS: false,
      disableStats: true
    })

    const channel = pusher.subscribe(`notifications.${userDetails.id}`)
    channel.bind('new-notification', (data: any) => {
      // console.log(data.user_id, data.getNotify)
      setNotify({ user_id: data.user_id, notification_status: data.getNotify })
      // alert(JSON.stringify(data))
    })
  }, [])

  const getNotifications = () => {
    if (userRole() === 'Admin User' || userRole() === 'Normal User') {
      apiService
        .getTutorNotifications()
        .then((res) => {
          if (res) {
            console.log('Call Tutor Notification API', res)
            setNotifications(res)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else if(userRole() === 'Others') {
      apiService.getLearnerNotifications().then(res => {
        if(res) {
          console.log('Call Learner Notification API', res)
          setNotifications(res)
        }
      })
    }
  }

  useEffect(() => {
    console.log('notify', notify)
    if (notify.notification_status !== '') {
      setNumOfNotifications((preValue) => {
        console.log('preValue', preValue)
        if(preValue >= numOfNotifications){
          return preValue
        }
        return preValue += 1
      })
    }
    
    getNotifications()

  },[notify])

  const handleCountNotifications = (notifications:any) => {
    // let num = 0
    if(userRole() === 'Admin User' || userRole() === 'Normal User') {
     const num = notifications.reduce((accValue: number, currValue: any) => {
        if (currValue.tutor_notification_status === 'notified') {
          accValue += 1
        }
  
        return accValue
      }, 0)

      setNumOfNotifications(num)
    } else if(userRole() === 'Others') {
     const num = notifications.reduce((accValue: number, currValue: any) => {
        if (currValue.learner_notification_status === 'notified') {
          accValue += 1
        }

        return accValue
      }, 0)
      setNumOfNotifications(num)
    }
  }

  useEffect(() => {
    handleCountNotifications(notifications)
  }, [notifications])


  return (
    <>
      <MUI.IconButton
        onClick={handleClickNotificationBtn} 
      >
        <MUI.Tooltip title={`You have ${numOfNotifications} notification${numOfNotifications > 1 ? 's': ''}`}>
          <Badge
            badgeContent={numOfNotifications}
            color='primary'
            invisible={(notify.notification_status === '') && (numOfNotifications === 0) ? true : false}
          >
            <MUI.NotificationsIcon />
          </Badge>
        </MUI.Tooltip>
      </MUI.IconButton>
      <NotificationMenu anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} notificationItems={notifications} setNotifications={setNotifications} numOfNotifications={numOfNotifications} />
    </>
  )
}

export default RealtimeNotification