import { useState, useEffect } from "react"
import { useObservable } from "../../../../util/useObservable"
import { collapseObservable } from "../../../../observables/collapseAll"
import { ICourseTopicTask } from "../../../../interface"
import CourseTopicTaskContent from "../course-list-content/CourseTopicTaskContent"
import * as MUI from "../../../../MUI"

interface CourseTopicTaskProps {
  data?: ICourseTopicTask[]
  learnerId?: number
  apiLearnerTimesheet?:any
  setApiLearnerTimesheet?:any
}

const CourseTopicTask = (props: CourseTopicTaskProps) => {
  const { data, learnerId, apiLearnerTimesheet, setApiLearnerTimesheet } = props

  const [open, setOpen] = useState(false)
  const collapseState = useObservable(collapseObservable().getObservable())

  const handleClick = () => setOpen(!open)

  useEffect(() => {
    setOpen(!collapseState.collapseAll)
  }, [collapseState])
  
  return (
    // <>
    //   <MUI.List component="div" disablePadding className="c-topic-task-wrapper">
    //     {/* Task Item */}
    //     <MUI.ListItemButton
    //       onClick={handleClick}
    //       className="c-list-topic-task-button"
    //     >
    //       {open ? <MUI.ExpandLess /> : <MUI.ExpandMore />}
    //       <MUI.ListItemText primary="Topic Task" />
    //     </MUI.ListItemButton>

    //     <MUI.Collapse in={open} timeout="auto">
    //       {/* Course topic learner content */}
    //       <div className={open ? "c-content-wrapper" : "c-content-wrapper-hidden"}>
    //         <CourseTopicTaskContent data={data} />
    //       </div>
    //     </MUI.Collapse>
    //   </MUI.List>
    // </>
    <MUI.List component="div" disablePadding className="c-topic-task-wrapper">
      <div className="c-content-wrapper">
        <CourseTopicTaskContent data={data} learnerId={learnerId} 
        apiLearnerTimesheet={apiLearnerTimesheet} setApiLearnerTimesheet={setApiLearnerTimesheet}/>
      </div>
    </MUI.List>
  )
}

export default CourseTopicTask
