import * as MUI from '../../MUI'
import { useEffect, useState } from "react"
import UserDialog from './UserDialog'
import { User } from '../../interface'
import { IUserFieldProps } from '../../interface'
import apiService from '../../services/api-services'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Tooltip from '@mui/material/Tooltip';

interface newUser extends User {
  password: string
}

const CreateNewUser = () => {
  const newUserInit: newUser = {
    city: "",
    country: "NZ",
    created_at: "",
    email: "",
    first_name: "",
    forgot_password_link: "",
    id: 0,
    is_active: 0,
    last_name: "",
    role: 0,
    street_address: "",
    updated_at: "",
    phone: 0,
    password: ""
  }

  const [newUser, setNewUser] = useState<newUser>(newUserInit)

  const [newUserFirstName, setNewUserFirstName] = useState('')
  const [newUserLastName, setNewUserLastName] = useState('')
  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserPassword, setNewUserPassword] = useState('') 
  const [newUserRole, setNewUserRole] = useState('3')
  const [newUserIsActive, setUserIsActive] = useState('1')
  const [newUserAvatar,setNewUserAvatar] = useState()

  const [passwordLength, setPasswordLength] = useState(0)
  const [passwordCaps, setPasswordCaps] = useState(0)
  const [passwordNum, setPasswordNum] = useState(0)

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const [tutorID,setTutorID] = useState<number>()
  const [tutorList,setTutorList] = useState<User[]>()
  const [users, setUsers] = useState<User[]>([])

  const [apiLearners, setApiLearners] = useState<User[]>([])

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })

  const [callSnackBar, setCallSnackBar] = useState(false)

  const [createNewUserDialogOpen, setCreateNewUserDialogOpen] = useState(false)
  const [editState, setEditState] = useState(false)

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('USERKEY')!).role === 1) {
      apiService.getAllUsers().then((res: any) => {
        setUsers(res)
      })
    }
  }, [])

  useEffect(() => {
    const tutorList = users.filter((data: { role: number }) => {
      if(data.role == 2) {
        return data
      } 
    })
    console.log(tutorList)
    setTutorList(tutorList)
  }, [users])

  let userDialogForm: IUserFieldProps[] = [
    {
      error: firstNameError,
      helperText: 'First Name length is ' + newUserFirstName.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'first_name',
      label: 'First Name',
      type: 'string',
      fullWidth: true,
      value: newUserFirstName,
      select: false,
      onChange: (e: string) => (handleSetFirstName(e)),
      required: true
    },
    {
      error: lastNameError,
      helperText: 'Last Name length is ' + newUserLastName.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'last_name',
      label: 'Last Name',
      type: 'string',
      fullWidth: true,
      value: newUserLastName,
      select: false,
      onChange: (e: string) => (handleSetLastName(e)),
      required: true
    },
    {
      error: emailError,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'email',
      label: 'Email',
      type: 'string',
      fullWidth: true,
      value: newUserEmail,
      select: false,
      onChange: (e: string) => (handleSetEmail(e)),
      required: true
    },
    {
      error: passwordError,
      helperText:
        'Min password length: ' +
        passwordLength +
        '/8. ' +
        'Min capital letters: ' +
        passwordCaps +
        '/2. ' +
        'Min numbers: ' +
        passwordNum +
        '/2.',
      disabled: false,
      margin: 'dense',
      id: 'password',
      label: 'Password',
      type: 'password',
      fullWidth: true,
      value: newUserPassword,
      select: false,
      onChange: (e: string) => (handleSetPassword(e)),
      required: true
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'role',
      label: 'Role',
      type: 'number',
      fullWidth: true,
      value: newUserRole,
      select: true,
      onChange: (e: string) => (setNewUserRole(e)),
      required: true
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'is_active',
      label: 'Account Status',
      type: 'number',
      fullWidth: true,
      value: newUserIsActive,
      select: true,
      onChange: (e: string) => (setUserIsActive(e)),
      required: true
    }
  ]

  let studentDialogForm: IUserFieldProps[] = [
    {
      error: firstNameError,
      helperText: 'First Name length is ' + newUserFirstName.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'first_name',
      label: 'First Name',
      type: 'string',
      fullWidth: true,
      value: newUserFirstName,
      select: false,
      onChange: (e: string) => (handleSetFirstName(e)),
      required: true
    },
    {
      error: lastNameError,
      helperText: 'Last Name length is ' + newUserLastName.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'last_name',
      label: 'Last Name',
      type: 'string',
      fullWidth: true,
      value: newUserLastName,
      select: false,
      onChange: (e: string) => (handleSetLastName(e)),
      required: true
    },
    {
      error: emailError,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'email',
      label: 'Email',
      type: 'string',
      fullWidth: true,
      value: newUserEmail,
      select: false,
      onChange: (e: string) => (handleSetEmail(e)),
      required: true
    },
    {
      error: passwordError,
      helperText:
        'Min password length: ' +
        passwordLength +
        '/8. ' +
        'Min capital letters: ' +
        passwordCaps +
        '/2. ' +
        'Min numbers: ' +
        passwordNum +
        '/2.',
      disabled: false,
      margin: 'dense',
      id: 'password',
      label: 'Password',
      type: 'password',
      fullWidth: true,
      value: newUserPassword,
      select: false,
      onChange: (e: string) => (handleSetPassword(e)),
      required: true
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'role',
      label: 'Role',
      type: 'number',
      fullWidth: true,
      value: newUserRole,
      select: true,
      onChange: (e: string) => (setNewUserRole(e)),
      required: true
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'tutor',
      label: 'Assigned Tutor',
      type: 'number',
      fullWidth: true,
      value: tutorID,
      select: true,
      onChange: (e: number) => (setTutorID(e)),
      required: true
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'is_active',
      label: 'Account Status',
      type: 'number',
      fullWidth: true,
      value: newUserIsActive,
      select: true,
      onChange: (e: string) => (setUserIsActive(e)),
      required: true
    }
  ]

  const handleSetFirstName = (e: string) => {
    setNewUserFirstName(e)
  }

  const handleSetLastName = (e: string) => {
    setNewUserLastName(e)
  }

  const handleSetEmail = (e: string) => {
    setNewUserEmail(e)
  }

  const handleSetPassword = (e: string) => {
    setNewUserPassword(e)
    setPasswordLength(e.length)
    setPasswordCaps((e.match(/[A-Z]/g) || []).length)
    setPasswordNum((e.match(/[0-9]/g) || []).length)
  }

  const newUserFormData = () => {

    formatData()
      //FORM DATA 
      newUser.first_name = newUserFirstName
      newUser.last_name = newUserLastName
      newUser.email = newUserEmail
      newUser.password = newUserPassword
      newUser.role = parseInt(newUserRole)
      newUser.is_active = parseInt(newUserIsActive)
      if(tutorID){
        newUser.tutor_id = tutorID
      }
      
      apiService.createNewUser(newUser)
      handleCreateNewUserDialogClose()
  }

  //formatData to FirstLetter Uppercase and the rest lowercases
  const formatData = () => {

    let first_name = newUserFirstName.toLowerCase()
    first_name = first_name.charAt(0).toUpperCase() + first_name.slice(1)

    let last_name = newUserLastName.toLocaleLowerCase()
    last_name = last_name.charAt(0).toUpperCase() + last_name.slice(1)

    let new_user_email = newUserEmail.toLocaleLowerCase()
    new_user_email = new_user_email.charAt(0).toUpperCase() + new_user_email.slice(1)

    
    setNewUserFirstName(first_name)
    setNewUserLastName(last_name)
    setNewUserEmail(new_user_email)
  }

  const userDataValidation = () => {
    const firstNameValid = firstNameValidation()
    const lastNameValid = lastNameValidation()
    const emailValid = emailValidation()
    const passwordValid = passwordValidation()
    if (
      firstNameValid === false ||
      lastNameValid === false ||
      passwordValid === false
    ) {
      return false
    } else {
      return true
    }
  }

  const firstNameValidation = () => {
    if (newUserFirstName.length > 10 || newUserFirstName.length === 0) {
      setFirstNameError(true)
      return false
    } else {
      setFirstNameError(false)
      return true
    }
  }

  const lastNameValidation = () => {
    if (newUserLastName.length > 10 || newUserLastName.length === 0) {
      setLastNameError(true)
      return false
    } else {
      setLastNameError(false)
      return true
    }
  }

    //Email Validation
    const emailValidation = () => {
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const emailValidationResult: boolean = expression.test(newUserEmail)
      if(emailValidationResult === false || newUserEmail.length === 0 ) {
        setEmailError(true)
        return false
      } else {
        setEmailError(false)
        return true
      }
    }

  const passwordValidation = () => {
    if (newUserPassword != '') {
      if (
        newUserPassword.length < 8 ||
        (newUserPassword.match(/[A-Z]/g) || []).length < 2 ||
        (newUserPassword.match(/[0-9]/g) || []).length < 2
      ) {
        setPasswordError(true)
        return false
      } else {
        setPasswordError(false)
        return true
      }
    } else {
      setPasswordError(true)
      return false
    }
  }


  const handleCreateNewUserDialogOpen = () => {
    apiService.getAllLearners().then((res) => {setApiLearners(res)})
    setEditState(true)
    setCreateNewUserDialogOpen(true)
  }

  const handleCreateNewUserDialogClose = () => {
    setNewUserFirstName('')
    setNewUserLastName('')
    setNewUserEmail('')
    setNewUserPassword('')
    setNewUserRole('3')
    setUserIsActive('1')
    setPasswordLength(0)
    setPasswordCaps(0)
    setPasswordNum(0)
    setFirstNameError(false)
    setLastNameError(false)
    setEmailError(false)
    setPasswordError(false)

    setEditState(false)
    setCreateNewUserDialogOpen(false)
  }

  const handleCancel = () => {
    handleCreateNewUserDialogClose()
  }

  const handleSave = () => {
    if (userDataValidation() === true) {
    newUserFormData()
    }
  }

  return (
    <>
      <Tooltip title="Create New User">
      <MUI.IconButton
        onClick={handleCreateNewUserDialogOpen}
        disableRipple
      >
        <PersonAddAltOutlinedIcon sx={{ marginRight: '5px', color:'black' }} fontSize='medium' />
      </MUI.IconButton>
      </Tooltip>
      {newUserRole == '3' ? 
            <UserDialog
            isOpen={createNewUserDialogOpen}
            handleClose={handleCancel}
            handleCancel={handleCancel}
            handleSave={handleSave}
            label={'Create New User'}
            notifyIsOpen={notify.isOpen}
            notifyMessage={notify.message}
            notifyType={notify.type}
            callSnackBar={callSnackBar}
            children={studentDialogForm}
            avatarChangeFunction={false}
            avatar={newUserAvatar!}
            setAvatar={setNewUserAvatar}
            tutorList={tutorList}
          /> :
          <UserDialog
          isOpen={createNewUserDialogOpen}
          handleClose={handleCancel}
          handleCancel={handleCancel}
          handleSave={handleSave}
          label={'Create New User'}
          notifyIsOpen={notify.isOpen}
          notifyMessage={notify.message}
          notifyType={notify.type}
          callSnackBar={callSnackBar}
          children={userDialogForm}
          avatarChangeFunction={false}
          avatar={newUserAvatar!}
          setAvatar={setNewUserAvatar}
          tutorList={tutorList}
        /> 
    }

    </>
  )
}

export default CreateNewUser