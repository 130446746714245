// import axios from "axios"
import { useEffect, useState } from "react"
import { TextField, Button } from "@mui/material"
// import { apiUrl } from "../../environment"
import { authTokenKey, setStorage, userKey } from "../../constants/auth"
import apiService from "../../services/api-services"
import Notification from "../../components/notification/notification"
import IconButton from "../../components/button/IconButton"

const LoginPage = () => {
  const [user, setUser] = useState<any>()
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })

  useEffect(() => {document.title = "Login | GOLS"}, [])
  
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value })
  }

  const onLogin = (e: any) => {
  // const onLogin = async (e: any) => {
    e.preventDefault()
    if (!(user?.email && user?.password)) {
      setNotify({
        isOpen: true,
        message: "Please enter your email and password.",
        type: "error",
      })
    } else {
      apiService.loginUser(user)
      // await axios.post(`${apiUrl}/login`, user)
        .then((res: any) => {
          setStorage(authTokenKey, res.token?.token);
          // setStorage(authTokenKey, res.data.token?.token);
          setStorage(userKey, res.user);
          // setStorage(userKey, res.data.user);
          setNotify({
            isOpen: true,
            message: `Welcome! ${user.email}`,
            type: "success",
          })
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        })
        .catch(err => setNotify({
          isOpen: true,
          message: "Error! Please check your credentials",
          type: "error",
        }))
    }
  }

  return (
    <div className="w-100 h-80 py-30 flex content-center items-center flex-column" style={{ marginTop: 100}}>
      <h1>Login</h1>
      <form onSubmit={(e) => onLogin(e)}>
        <hr />
        <div>
          <TextField
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            onChange={handleInputChange}
            sx={{ m: 2, minWidth:{ xs: '', sm: 400 }}}
          />
        </div>
        <div>
          <TextField
            label="Password"
            name="password"
            variant="outlined"
            type="password"
            onChange={handleInputChange}
            sx={{ m: 2, minWidth:{ xs: '', sm: 400 }}}
          />
        </div>
        <div style={{textAlign:'center'}}>
          <IconButton type="submit" variant="outlined" text='Log In'/>
        </div>
      </form>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default LoginPage
