import {
  ICourseResponse,
  ICourseTopic,
  ICourseTopicTask,
} from "../../interface"

export interface ActiveValue extends Pick<ICourseResponse, "is_active"> {
  label: string
}
export interface TopicType extends Pick<ICourseTopic, "type"> {
  label: string
}

export interface TaskType extends Pick<ICourseTopicTask, "task_type"> {
  label: string
}

export const activeValue: ActiveValue[] = [
  {
    is_active: 0,
    label: "Inactive",
  },
  {
    is_active: 1,
    label: "Active",
  },
]

export const topicType: TopicType[] = [
  {
    type: "elective",
    label: "Elective",
  },
  {
    type: "compulsory",
    label: "Compulsory",
  },
  {
    type: "preprations",
    label: "Preparations",
  },
]

export const taskType: TaskType[] = [
  {
    task_type: "bug fix",
    label: "Bug fix",
  },
  {
    task_type: "new feature",
    label: "New feature",
  },
  {
    task_type: "analysis",
    label: "Analysis",
  },
  {
    task_type: "testing",
    label: "Testing",
  },
  {
    task_type: "refactor",
    label: "Refactor",
  },
  {
    task_type: "normal",
    label: "Normal",
  },
  {
    task_type: "milestone",
    label: "Milestone",
  },
]
