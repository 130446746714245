import { useRef } from "react"
import * as MUI from "../../MUI"
import { ITextFieldProps } from "../../interface"
import {
  activeValue,
  topicType,
  taskType,
  ActiveValue,
  TopicType,
  TaskType,
} from "./FormSelectType"
import TextButton from "../../components/button/TextButton"

interface FormDialogProps {
  handleClose: () => void
  handleSubmit: () => void
  children: ITextFieldProps[]
  isOpen: boolean
  error?: boolean
  method:
    | 'add topic'
    | 'edit topic'
    | 'delete topic'
    | 'add course'
    | 'edit course'
    | 'delete course'
    | 'add task'
    | 'edit task'
    | 'delete task'
    | 'add codelink'
}

export default function FormDialog(props: FormDialogProps) {
  const { handleClose, children, isOpen, handleSubmit, method } = props

  const textFieldsRefs = useRef<HTMLInputElement[]>([])

  return (
      <MUI.FormControl required>
        <MUI.Dialog open={isOpen} onClose={handleClose} onClick={(e)=>e.stopPropagation()} fullWidth>
          <MUI.DialogTitle>{method.toUpperCase()}</MUI.DialogTitle>
          <form onSubmit={(e)=>{e.preventDefault(); handleSubmit(); handleClose()}}>
          <MUI.DialogContent>
            <MUI.DialogContentText>{method}</MUI.DialogContentText>
            <hr />
            {children.map((field: ITextFieldProps, i: number) => {
              return (
                <div key={i}>
                  <MUI.TextField
                    // key={i}
                    inputRef={(ref) => {textFieldsRefs.current[i] = ref}}
                    className={field.className}
                    autoFocus={field.autoFocus}
                    margin={field.margin}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    fullWidth={field.fullWidth}
                    variant={field.variant}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(event.target.value)
                    }}
                    defaultValue={field.value}
                    select={field.select}
                    required={field.required}
                    inputProps={{
                      onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === 'Tab'){
                          event.preventDefault()
                          textFieldsRefs.current[i+1]?.focus()
                        } 
                      },
                      tabIndex: i
                    }}
                  >
                    {field.select &&
                      (method === 'add course' || method === 'edit course') &&
                      activeValue.map((active: ActiveValue) => {
                        return (
                          <MUI.MenuItem key={active.is_active} value={active.is_active}>
                            {active.label}
                          </MUI.MenuItem>
                        )
                      })}
                    {field.select &&
                      (method === 'add topic' || method === 'edit topic') &&
                      topicType.map((type: TopicType) => {
                        return (
                          <MUI.MenuItem key={type.type} value={type.type}>
                            {type.label}
                          </MUI.MenuItem>
                        )
                      })}
                    {field.select &&
                      (method === 'add task' || method === 'edit task') &&
                      taskType.map((type: TaskType) => {
                        return (
                          <MUI.MenuItem key={type.task_type} value={type.task_type}>
                            {type.label}
                          </MUI.MenuItem>
                        )
                      })}
                  </MUI.TextField>
                  <MUI.FormHelperText sx={{ color: '#dc3545' }}>
                    {field.id !== 'estimate_hours' && field.error
                      ? `Please input ${field.label}`
                      : ''}
                    {field.id === 'estimate_hours' && field.error ? `Invalid ${field.label}` : ''}
                  </MUI.FormHelperText>
                </div>
              )
            })}
          </MUI.DialogContent>
          <MUI.DialogActions>
            <TextButton onClick={handleClose} text='Cancel'/>
            <TextButton text='Submit' type="submit"/>
          </MUI.DialogActions>
          </form>
        </MUI.Dialog>
      </MUI.FormControl>  
  )
}
