import  { useState, useContext, useEffect } from 'react'
import { ICourseTopicTask } from '../../../../interface'
import './../course-list/Course.css'
import apiService from '../../../../services/api-services'
import { getStorage, userKey } from '../../../../constants/auth'
import IconButton from '../../../../components/button/IconButton'
import SetTaskDeadline from './SetTaskDeadline'

interface HandleTaskDeadlineProps{
  data: ICourseTopicTask
  learnerId: number
  due: Date | undefined
  timesheetId:number| undefined
  apiLearnerTimesheet:any
  setApiLearnerTimesheet: Function
}

export default function HandleTaskDeadline(props:HandleTaskDeadlineProps) {
  const { data, learnerId, due, timesheetId, apiLearnerTimesheet, setApiLearnerTimesheet } = props
  const [deadlineDialog, setDeadlineDialog] = useState(false)
  const userInfo = getStorage(userKey)

  const handleCancelDeadline = () => {
    setApiLearnerTimesheet(apiLearnerTimesheet.filter((data: { id: number | undefined }) => data.id !== timesheetId))
    apiService.deleteTimeSheet(timesheetId?.toString()!)
  }

  return (
    <>
    {!due ?         
      <span style={{ marginLeft: '5px' }}>
        <IconButton text='Set Deadline' size='medium' variant='contained' 
        onClick={()=>setDeadlineDialog(true)} />
      </span>
      :
      <span style={{ marginLeft: '5px' }}>
      <IconButton text='Cancel Deadline' size='medium' variant='contained' color='warning'
      onClick={()=>handleCancelDeadline()} />
    </span>
      }

      {deadlineDialog? 
      <SetTaskDeadline open={deadlineDialog} setOpen={setDeadlineDialog} data={data} 
      learnerId={learnerId} setApiLearnerTimesheet={setApiLearnerTimesheet}/> 
      : ''}
    </>
  );
}