import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { token } from "../constants/auth"
import { apiInfoObservable } from "../observables"
import errorHandle from "../util/errorHandle"

// const baseURL = "http://127.0.0.1:8000/api"
const baseURL = "https://app.spiritx.co.nz/api"

const apiState = apiInfoObservable()

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config = { ...config, baseURL }
  if (!config.url?.includes("login")) {
    return {
      ...config,
      headers: {
        ...config.headers,
        token,
      },
    }
  }
  // console.log(config)
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.log('response', response)
  return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.data.message || error.response?.status) {
    apiState.error(true)
    apiState.errorMsg(
      errorHandle(error.response?.data.message, error.response?.status)
    )
  }
  return Promise.reject(error)
}

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
