import React, { useEffect, useState } from 'react'
import TextButton from '../../components/button/TextButton'
import { ActiveValue, activeValue, RoleValue, roleValue } from '../../dialogs/user-dialog/UserManagementSelectType'
import { IUserFieldProps, User } from '../../interface'
import * as MUI from '../../MUI'
import apiService from '../../services/api-services'
import { Main, useStyles, adjustToAppHeight } from '../dashboard-page/DashBoard'
import Notification from "../../components/notification/notification"
import { imageBaseUrl } from '../../constants/constants'
import UserSearchBar from '../dashboard-page/dashboard-components/components/UserSearchBar'
import CreateNewUser from '../../dialogs/user-dialog/CreateNewUser'
import ImageCrop from '../../components/image-crop/image-crop'

const drawerWidth = 250
const transitionDuration = 600

const UsersPage = () => {
  const classes = useStyles()
  const [users, setUsers] = useState<User[]>([])
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [is_active, setIsActive] = useState(0)
  const [role, setRole] = useState(0)
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirmPassword] = useState("")
  const [user_avatar, setUserAvatar] = useState<any>('')
  const [password_length, setPasswordLength] = useState(0)
  const [password_caps, setPasswordCaps] = useState(0)
  const [password_num, setPasswordNum] = useState(0)
  const [first_name_error, setFirstNameError] = useState(false)
  const [last_name_error, setLastNameError] = useState(false)
  const [email_error, setEmailError] = useState(false)
  const [password_error, setPasswordError] = useState(false)
  const [confirm_password_error, setConfirmPasswordError] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [preview, setPreview] = useState<string>('')
  const [imageDialogOpen, setImageDialogOpen] = useState(false)
  const [adminUserId, setAdminUserId] = useState<number>()
  const [tutorID,setTutorID] = useState<number>();
  const [tutorList,setTutorList] = useState<User[]>()
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  })

  adjustToAppHeight()

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('USERKEY')!).role === 1) {
      apiService.getAllUsers().then((res: any) => {
        setUsers(res)
      })
    }
  }, [])

  useEffect(() => {
    const tutorList = users.filter((data) => {
      if(data.role == 2) {
        return data
      } 
    })
    console.log(tutorList)
    setTutorList(tutorList)
  }, [users])

  const toggleDrawer = () => {
    setDrawerOpen((d) => !d)
  }

  const changeUser = (user: User) => {
    setCurrentUser(user)
    setFirstName(user.first_name ? user.first_name : '')
    setLastName(user.last_name ? user.last_name : '')
    setEmail(user.email)
    setIsActive(user.is_active)
    setRole(user.role)
    setUserAvatar(user.user_avatar ? imageBaseUrl + user.user_avatar : '')
    setAdminUserId(user.id)
    setTutorID(user.tutor_id)
    setPassword('')
    setConfirmPassword('')
    setFirstNameError(false)
    setLastNameError(false)
    setEmailError(false)
    setPasswordError(false)
    setConfirmPasswordError(false)
  }

  let userDialogForm: IUserFieldProps[] = 
  [
    {
      error: first_name_error,
      helperText: 'First Name length is ' + first_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'first_name',
      label: 'First Name',
      type: 'string',
      fullWidth: true,
      value: first_name,
      select: false,
      onChange: (e: string) => (setFirstName(e)),
      required: false
    },
    {
      error: last_name_error,
      helperText: 'Last Name length is ' + last_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'lasts_name',
      label: 'Last Name',
      type: 'string',
      fullWidth: true,
      value: last_name,
      select: false,
      onChange: (e: string) => (setLastName(e)),
      required: false
    },
    {
      error: email_error,
      helperText: email_error ? 'Your email is not valid' : 'Valid email address',
      disabled: false,
      margin: 'dense',
      id: 'email',
      label: 'Email',
      type: 'string',
      fullWidth: true,
      value: email,
      select: false,
      onChange: (e: string) => (setEmail(e)),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'is_active',
      label: 'Account Status',
      type: 'string',
      fullWidth: true,
      value: is_active,
      select: true,
      onChange: (e: number) => (setIsActive(e)),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'role',
      label: 'Role',
      type: 'string',
      fullWidth: true,
      value: role,
      select: true,
      onChange: (e: number) => (setRole(e)),
      required: false
    },
    {
      error: password_error,
      helperText:
        'Min password length: ' +
        password_length +
        '/8. ' +
        'Min capital letters: ' +
        password_caps +
        '/2. ' +
        'Min numbers: ' +
        password_num +
        '/2.',
      disabled: false,
      margin: 'dense',
      id: 'password',
      label: 'New Password',
      type: 'password',
      fullWidth: true,
      value: password,
      select: false,
      onChange: (e: string) => (handleChangePassword(e)),
      required: false
    },
    {
      error: confirm_password_error,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'confirm_password',
      label: 'Confirm New Password',
      type: 'password',
      fullWidth: true,
      value: confirm_password,
      select: false,
      onChange: (e: string) => (setConfirmPassword(e)),
      required: false
    }
  ]

  let studentDialogForm: IUserFieldProps[] = 
  [
    {
      error: first_name_error,
      helperText: 'First Name length is ' + first_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'first_name',
      label: 'First Name',
      type: 'string',
      fullWidth: true,
      value: first_name,
      select: false,
      onChange: (e: string) => (setFirstName(e)),
      required: false
    },
    {
      error: last_name_error,
      helperText: 'Last Name length is ' + last_name.length + '/10',
      disabled: false,
      margin: 'dense',
      id: 'lasts_name',
      label: 'Last Name',
      type: 'string',
      fullWidth: true,
      value: last_name,
      select: false,
      onChange: (e: string) => (setLastName(e)),
      required: false
    },
    {
      error: email_error,
      helperText: email_error ? 'Your email is not valid' : 'Valid email address',
      disabled: false,
      margin: 'dense',
      id: 'email',
      label: 'Email',
      type: 'string',
      fullWidth: true,
      value: email,
      select: false,
      onChange: (e: string) => (setEmail(e)),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'is_active',
      label: 'Account Status',
      type: 'string',
      fullWidth: true,
      value: is_active,
      select: true,
      onChange: (e: number) => (setIsActive(e)),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'role',
      label: 'Role',
      type: 'string',
      fullWidth: true,
      value: role,
      select: true,
      onChange: (e: number) => (setRole(e)),
      required: false
    },
    {
      error: false,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'tutor',
      label: 'Assigned Tutor',
      type: 'string',
      fullWidth: true,
      value: tutorID,
      select: true,
      onChange: (e: number) => (setTutorID(e),console.log(e)),
      required: true
    },
    {
      error: password_error,
      helperText:
        'Min password length: ' +
        password_length +
        '/8. ' +
        'Min capital letters: ' +
        password_caps +
        '/2. ' +
        'Min numbers: ' +
        password_num +
        '/2.',
      disabled: false,
      margin: 'dense',
      id: 'password',
      label: 'New Password',
      type: 'password',
      fullWidth: true,
      value: password,
      select: false,
      onChange: (e: string) => (handleChangePassword(e)),
      required: false
    },
    {
      error: confirm_password_error,
      helperText: '',
      disabled: false,
      margin: 'dense',
      id: 'confirm_password',
      label: 'Confirm New Password',
      type: 'password',
      fullWidth: true,
      value: confirm_password,
      select: false,
      onChange: (e: string) => (setConfirmPassword(e)),
      required: false
    }
  ]

const handleChangePassword = (e: string) => {
  setPassword(e)
  setPasswordLength(e.length)
  setPasswordCaps((e.match(/[A-Z]/g) || []).length)
  setPasswordNum((e.match(/[0-9]/g) || []).length)
}

const handleSave = () => {
  if (userDataValidation() === true) {
    formData()
  }
}

const formData = () => {
  let formdata = new FormData();
  formdata.append('first_name', first_name);
  formdata.append('last_name', last_name);
  formdata.append('email', email);
  if (password !== '' && passwordValidation() === true) formdata.append('password', password);
  formdata.append('is_active', is_active.toString());
  formdata.append('role', role.toString());
  if(tutorID){
    formdata.append('tutor_id',tutorID.toString())
  }
  formdata.append('_method', 'PUT');
  if (currentUser !== null) {
    apiService.updateUser(currentUser.id, formdata).then(res => {
      if (res) {
        setNotify({
          isOpen: true,
          message: 'Success! Changes to the user have been saved.',
          type: 'success',
        })
      }
      apiService.getAllUsers().then((res: any) => {
        setUsers(res)
      })
    }).catch(err => {
      console.log('Fail! Changes to the user have not been saved.', err)
      setNotify({
        isOpen: true,
        message: 'Fail! Changes to the user have not been saved.',
        type: 'error',
      })
    }) 
  }
}

const userDataValidation = () => {
  const first_name_valid = firstNameValidation()
  const last_name_valid = lastNameValidation()
  const email_valid = emailValidation()
  const password_valid = passwordValidation()
  const confirm_password_valid = confirmPasswordValidation()
  return first_name_valid && last_name_valid && email_valid && password_valid && confirm_password_valid
}

const firstNameValidation = () => {
  const firstNameValid = first_name.length > 10
  setFirstNameError(firstNameValid)
  return !firstNameValid
}

const lastNameValidation = () => {
  const lastNameValid = last_name.length > 10
  setLastNameError(lastNameValid)
  return !lastNameValid
}

const emailValidation = () => {
    if (email !== "")
    {
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      // const expression: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      const emailValidationResult: boolean = expression.test(email)
      setEmailError(!emailValidationResult)
      return emailValidationResult
    } else {
      return false
    }
}

const passwordValidation = () => {
  if (password != '') {
    if (
      password.length < 8 ||
      (password.match(/[A-Z]/g) || []).length < 2 ||
      (password.match(/[0-9]/g) || []).length < 2
    ) {
      setPasswordError(true)
      return false
    } else {
      setPasswordError(false)
      return true
    }
  } else {
    return true
  }
}

const confirmPasswordValidation = () => {
  const confirmPasswordValid = confirm_password != password
  setConfirmPasswordError(confirmPasswordValid)
  return !confirmPasswordValid
}

const handleShowImage = (event: any) => {
  const file = event.target?.files[0]
  if (file) {
    const imgUrl = URL.createObjectURL(file)
    setPreview(imgUrl)
  }
}

  const drawer = (
    <>
      <MUI.Typography className={classes.listTitle}>
        Users
        <CreateNewUser/>
      </MUI.Typography>
      <MUI.List>
        <UserSearchBar searchArray={users} setFilteredArray={setFilteredUsers} text='User' />
        {/* <MUI.Autocomplete
          className={classes.selectLearnerField}
          disablePortal
          id='select-learner-autocomplete'
          options={users}
          getOptionLabel={(option) => option.email}
          value={null}
          renderOption={(props, option) => (
            <MUI.Box component='li' {...props} key={option.id}>
              {option.email}
            </MUI.Box>
          )}
          renderInput={(params) => (
            <MUI.TextField
              {...params}
              label='Select users'
              variant='filled'
              placeholder='Enter User Email'
            />
          )}
          onChange={(event, newValue) => {
            newValue && changeUser(newValue)
          }}
        /> */}
      </MUI.List>
      <MUI.List>
        {filteredUsers.map((user) => {
          return (
            <MUI.ListItem
              id='drawer-item'
              key={user.id}
              button
              disablePadding
              onClick={() => {
                // do sth here.
                changeUser(user)
              }}
              className={currentUser && currentUser['id'] === user.id ? classes.active : undefined}
            >
              <MUI.ListItemButton disableGutters>
                <MUI.ListItemAvatar>
                  <MUI.Avatar
                    className={classes.avatar}
                    src={user.user_avatar ? imageBaseUrl + user.user_avatar : ''}
                  />
                </MUI.ListItemAvatar>
                <MUI.ListItemText
                  // primary={
                  //   <div
                  //     style={{
                  //       maxWidth: '230px',
                  //       whiteSpace: 'nowrap',
                  //       overflow: 'hidden',
                  //       textOverflow: 'ellipsis'
                  //     }}
                  //   >
                  //     {user.email}
                  //   </div>
                  // }
                  primary={
                    <div
                      style={{
                        maxWidth: '230px',
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <span style={{ fontWeight: '600' }}>
                        {user.first_name} {user.last_name}
                      </span>
                      <span
                        style={{
                          width: '180px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '12px',
                          color: 'rgb(0,0,0,.55)'
                        }}
                      >
                        {user.email}
                      </span>
                    </div>
                  }
                />
              </MUI.ListItemButton>
            </MUI.ListItem>
          )
        })}
      </MUI.List>
    </>
  )

  return (
    <>
      <MUI.Box id='admin-dashboard'>
        {/* Drawer */}
        <MUI.Box
          component='nav'
          sx={{
            width: { md: drawerWidth },
            position: 'fixed'
          }}
        >
          <MUI.Drawer
            className={classes.mobileDrawerPaper}
            variant='temporary'
            anchor='left'
            open={drawerOpen}
            onClose={toggleDrawer}
            classes={{ paper: classes.mobileDrawerPaper }}
            PaperProps={{ elevation: 15 }}
            ModalProps={{
              keepMounted: true
            }}
            transitionDuration={{
              enter: transitionDuration,
              exit: transitionDuration
            }}
            sx={{
              display: {
                xs: 'block',
                md: 'none'
              }
            }}
          >
            {drawer}
          </MUI.Drawer>
          <MUI.Drawer
            classes={{ paper: classes.drawerPaper }}
            variant='persistent'
            sx={{
              display: { xs: 'none', md: 'block' }
            }}
            open={true}
          >
            {drawer}
          </MUI.Drawer>
        </MUI.Box>
        <Main sx={{ marginLeft: { xs: '0px', md: '280px' } }} open={!drawerOpen}>
          <MUI.Box
            sx={{
              display: {
                xs: 'block',
                md: 'none'
              },
              marginTop: '100px',
              order: 2
            }}
          >
            <MUI.Fab
              onClick={toggleDrawer}
              size='small'
              sx={{
                position: 'fixed',
                right: '5px',
                bottom: '25px',
              }}
            >
              <MUI.PeopleIcon />
            </MUI.Fab>
          </MUI.Box>
          {!currentUser ? (
            <div
              style={{
                paddingTop: 100,
                fontWeight: 500,
                order: 1
              }}
            >
              <MUI.Typography variant='h3'>Select user to view profile</MUI.Typography>
            </div>
          ) : (
            <div style={{ flexGrow: 1 }}>
              {/* <DashboardUserCard learnerId={{ id: currentId }} /> */}
              <MUI.Stack direction="row" justifyContent={"center"} sx={{paddingTop: '20px'}}>
                <MUI.DialogTitle>Edit User Account</MUI.DialogTitle>
              </MUI.Stack>

              <hr />
                
                <MUI.DialogContent>
                  {role == 3?
                  studentDialogForm.map((field: IUserFieldProps, i: number) => {
                    return (
                      <div key={i}>
                        <form autoComplete='off'>
                          <MUI.TextField
                            error={field.error}
                            helperText={field.helperText}
                            disabled={field.disabled}
                            margin = {field.margin}
                            label = {field.label}
                            type = {field.type}
                            value = {field.type === 'file' ? '' : field.value}
                            fullWidth = {field.fullWidth}
                            select = {field.select}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              field.onChange(field.type === 'file' ? (event.target?.files && event.target?.files[0]) : event.target.value)
                              event.target?.files && event.target?.files[0] && handleShowImage(event)
                            }}
                            onBlur={userDataValidation}
                            required={field.required}
                          >
                          {field.select && field.id === 'is_active' && activeValue.map((active: ActiveValue) => {
                            return (
                              <MUI.MenuItem
                                key={active.is_active}
                                value={active.is_active}
                              >
                                {active.label}
                              </MUI.MenuItem>
                            )
                          })}
                          {field.select && field.id === 'role' && roleValue.map((role: RoleValue) => {
                            return (
                              <MUI.MenuItem
                                key={role.role}
                                value={role.role}
                              >
                                {role.label}
                              </MUI.MenuItem>
                            )
                          })}
                          {field.select && field.id === 'tutor' && tutorList!.map((tutor) => {
                            return (
                              <MUI.MenuItem
                                key={tutor.id}
                                value={tutor.id}
                              >
                                {tutor.first_name + ' ' + tutor.last_name}
                              </MUI.MenuItem>
                            )
                          })}
                          </MUI.TextField>
                        </form>
                      </div>
                    )
                  }):
                  userDialogForm.map((field: IUserFieldProps, i: number) => {
                    return (
                      <div key={i}>
                        <form autoComplete='off'>
                          <MUI.TextField
                            error={field.error}
                            helperText={field.helperText}
                            disabled={field.disabled}
                            margin = {field.margin}
                            label = {field.label}
                            type = {field.type}
                            value = {field.type === 'file' ? '' : field.value}
                            fullWidth = {field.fullWidth}
                            select = {field.select}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              field.onChange(field.type === 'file' ? (event.target?.files && event.target?.files[0]) : event.target.value)
                              event.target?.files && event.target?.files[0] && handleShowImage(event)
                            }}
                            onBlur={userDataValidation}
                            required={field.required}
                          >
                          {field.select && field.id === 'is_active' && activeValue.map((active: ActiveValue) => {
                            return (
                              <MUI.MenuItem
                                key={active.is_active}
                                value={active.is_active}
                              >
                                {active.label}
                              </MUI.MenuItem>
                            )
                          })}
                          {field.select && field.id === 'role' && roleValue.map((role: RoleValue) => {
                            return (
                              <MUI.MenuItem
                                key={role.role}
                                value={role.role}
                              >
                                {role.label}
                              </MUI.MenuItem>
                            )
                          })}
                          {field.select && field.id === 'tutor' && tutorList!.map((tutor) => {
                            return (
                              <MUI.MenuItem
                                key={tutor.id}
                                value={tutor.id}
                              >
                                {tutor.first_name + ' ' + tutor.last_name}
                              </MUI.MenuItem>
                            )
                          })}
                          </MUI.TextField>
                        </form>
                      </div>
                    )
                  })} 
                  {preview && <MUI.Paper sx={{width: '300px', margin: 'auto', padding: '10px'}}>
                    <img style={{width: '100%', height: 'auto'}} src={preview} alt="preview"/>
                  </MUI.Paper>}
                </MUI.DialogContent>
              <MUI.Stack className="footer-stack" direction="row" spacing={2} justifyContent={"center"} style={{marginBottom:'10px'}}>
                <TextButton onClick={() => setImageDialogOpen(true)} text='Avatar'/> 
                <TextButton onClick={() => handleSave()} text='Save'/>
              </MUI.Stack>
              <Notification notify={notify} setNotify={setNotify} />
            </div>
          )}
        </Main>
        <ImageCrop 
      dialogOpen={imageDialogOpen} 
      setDialogOpen={setImageDialogOpen}
      avatar={user_avatar}
      setAvatar={setUserAvatar}
      adminUserId={adminUserId}/>
      </MUI.Box>
    </>
  )
}

export default UsersPage
