import * as React from 'react'
import { default as c } from 'classnames'
import { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './fold.css'

export interface FoldProps {
    className?: string
    maxHeight: number
    children: any
    expandEnable:boolean
}

export const Fold = (props: FoldProps) => {
    const prefixCls = 'brick-mobile-fold'
    const {
        maxHeight,
        children,
        className,
        expandEnable
    } = props
    const contentRef = React.createRef<HTMLDivElement>()
    const contentWrapperRef = React.createRef<HTMLDivElement>();
    const [contentHeight, setContentHeight] = useState(0);
    const [contentWrapperHeight, setContentWrapperHeight] = useState(0);
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        freshHeight();
    })
    const freshHeight = () => {
        const newContentHeignt = contentRef.current!.getBoundingClientRect().height;
        const newContentWrapperHeight = contentWrapperRef.current!.getBoundingClientRect().height;
        if (newContentHeignt !== contentHeight || newContentWrapperHeight !== contentWrapperHeight) {
            setContentHeight(newContentHeignt)
            setContentWrapperHeight(newContentWrapperHeight)
        }
    }

    const getClassName = () => {
        return c(
            prefixCls,
            className
        )
    }
    const isOverFlow = () => contentHeight > maxHeight;

    const showUnPack = () => {
        return !showAll
            && isOverFlow()
            && contentWrapperHeight < contentHeight;
    }

    const showPack = () => {
        return showAll && contentWrapperHeight > maxHeight;
    }
    
    return (
        <span className={`${getClassName()} comps-ellipsis`}>
            <span
                ref={contentWrapperRef}
                className="comps-content-wrapper"
                style={{ maxHeight: showAll ? undefined : `${maxHeight}px` }}
            >
                <span
                    ref={contentRef}
                    className="comps-content"
                    dangerouslySetInnerHTML={{__html:children}}
                >
                </span>
            </span>
            {
                showUnPack() && !expandEnable &&
                <span>
                    ...
                </span>
            }
            {
                showUnPack() && expandEnable &&
                <span
                    className="comps-ellipsis-unpack"
                    onClick={() => {
                        setShowAll(true)
                    }}
                >
                    <ExpandMoreIcon/> More
                </span>
            }
            {
                showPack() &&
                <span
                    onClick={() => {
                        setShowAll(false)
                    }}
                    className="comps-ellipsis-pack"
                >
                    <ExpandLessIcon/> Fold
                </span>
            }
        </span>
    )
}