import { Alert, Snackbar } from "@mui/material";

const Notification = (props: any) => {
  const { notify, setNotify } = props;

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({ ...notify, isOpen: false });
  };

  return (
    <Snackbar
    sx={{height: "100%"}}  
    className='mt-3'
      open={notify.isOpen}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}>
      <Alert severity={notify.type} onClose={() => setNotify({
        ...notify,
        isOpen: false,
        })}>
        {notify.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
