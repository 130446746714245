import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import TextButton from "../button/TextButton";

interface ConfirmPopup {
  info: {
    message: string
    positiveBtn: string
    negativeBtn: string
  }
  open: boolean
  severity: AlertColor | undefined
  setOpen: Function
  onConfirm: Function
  sx: object
}

// Example can be found in VideoForm
// message show the confirmation statement
// onConfirm control what to do next if confirmed
// sx can override style
function ConfirmPopup(props: ConfirmPopup): JSX.Element {
  const { info, severity, open, setOpen, onConfirm, sx } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} onClose={handleClose} sx={sx}>
        <MuiAlert
          severity={severity}
          action={
            <div>
              <TextButton onClick={handleClose} text={info.negativeBtn}/>
              <TextButton onClick={handleConfirm} text={info.positiveBtn}/>
            </div>
          }
          sx={{width:"inherit"}}
        >
          {info.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ConfirmPopup;
