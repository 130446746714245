import React from 'react'
import { RemoveQueryParams } from '../components/RemoveQueryParams'
export interface dashboardTabSwitchProps {
  children: React.ReactNode
  fallback?: JSX.Element | string
  value: number
  element: 'Overview' | 'Progress' | 'Users' | 'Courses' | 'Configs'
}

const DashboardTabSwitch = (props:dashboardTabSwitchProps) => {
  const { children, value, element} = props

  switch (value) {
    case 0:
      if (element === 'Overview') {
        sessionStorage.removeItem('Selected-Tab')
        sessionStorage.setItem('Tab', 'Overview')
        RemoveQueryParams('id')
        return <>{children}</>
      }
      break
    case 1:
      if (element === 'Progress') {
        sessionStorage.removeItem('Selected-Tab')
        sessionStorage.setItem('Tab', 'Progress')
        return <>{children}</>
      }
      break
    case 2:
      if (element === 'Users') {
        sessionStorage.removeItem('Selected-Tab')
        sessionStorage.setItem('Tab', 'Users')
        RemoveQueryParams('id')
        return <>{children}</>
      }
      break
    case 3:
      if (element === 'Courses') {
        sessionStorage.setItem('Selected-Tab', 'Courses')
        sessionStorage.setItem('Tab', 'Courses')
        RemoveQueryParams('id')
        return <>{children}</>
      }
      break
    case 4:
      if (element === 'Configs') {
        sessionStorage.removeItem('Selected-Tab')
        sessionStorage.setItem('Tab', 'Configs')
        RemoveQueryParams('id')
        return <>{children}</>
      }
      break
    default:
      break
  }

    return null
}

export default DashboardTabSwitch