import { useState, useEffect, useContext } from "react"
import { useObservable } from "../../../../util/useObservable"
import { collapseObservable } from "../../../../observables/collapseAll"
import { useTheme } from '@mui/material/styles'
import { useMediaQuery} from '@mui/material'
import { delayToDo } from "../components/Delayed"
import { CourseTopicContext } from "../context/CourseTopicContext"
import { AppContext } from '../../../../context/AppContext'
import { CourseContext } from "../context/CourseContext"
import { DashboardContext } from "../../../dashboard-page/dashboard-components/context/DashboardContext"
import { ICourseTopic, ICourseTopicResponse, ICourseTopicTask, ResourcesResponse } from "../../../../interface"
import UserRoleAuth from "../../../../components/userrole-authentication/UserRoleAuth"
import apiService from "../../../../services/api-services"
// import Spinner from "../../../../components/spinner/Spinner"
import NewSpinner from "../../../../components/new-spinner/NewSpinner"
import CourseTopicTask from "./CourseTopicTask"
import CourseTopicVideo from "./CourseTopicVideo"
// import AdminCourseTopic from "../components/AdminCourseTopic"
// import AdminCourseTopicTask from "../components/AdminCourseTopicTask"
import TopicMenu from "../components/TopicMenu"
import TopicResourcesList from "../components/TopicResourcesList"
import * as MUI from "../../../../MUI"
import { useSearchParams } from "react-router-dom"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getStorage, userKey } from '../../../../constants/auth'

interface CourseTopicProps {
  topicId: number
  topicTitle: string
  isCourseOpen: boolean
  courseTopics: ICourseTopic[]
  setCourseTopics: (arg: ICourseTopic[]) => void
  currentTopicId:number
  showProgressBar: boolean
  setShowProgressBar: React.Dispatch<React.SetStateAction<boolean>>
  topicOpen: Array<any>
  setTopicOpen: React.Dispatch<any>
}

interface reduceObjProps {
  courseId: number
  numOfTasks: number
  numOfCompletedTasks: number
}

const CourseTopic = (props: CourseTopicProps) => {
  const { topicId, topicTitle, isCourseOpen, setCourseTopics, courseTopics,currentTopicId, showProgressBar, setShowProgressBar, topicOpen, setTopicOpen } = props
  const { userRole } = useContext(AppContext)
  const { pathname, learnerId, submitCourseTopicId, setNotify, searchCourseTopicId } = useContext(CourseContext)
  const { assignCourseState, courseCheckbox} = useContext(DashboardContext)
  const [apiLearnerTimesheet, setApiLearnerTimesheet] = useState([]);
  const [open, setOpen] = useState<boolean>(false)
  const [isTopicLoading, setIsTopicLoading] = useState<boolean>(false)
  const [apiTopic, setApiTopic] = useState<ICourseTopicResponse[]>()
  const [tasks, setTasks] = useState<ICourseTopicTask[]>()
  const collapseState = useObservable(collapseObservable().getObservable())
  const [topicResources, setTopicResources] = useState<ResourcesResponse[]>()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const userInfo = getStorage(userKey)

  const value = { apiTopic, setApiTopic, topicId, tasks, setIsTopicLoading, courseTopics, setCourseTopics, setTasks, topicResources, setTopicResources }

  const [searchParams, setSearchParams] = useSearchParams()
  const [reduceObj, setReduceObj] = useState<reduceObjProps>({
    courseId: 0,
    numOfTasks: 0,
    numOfCompletedTasks: 0,
  })
  
  useEffect(() => {
    const mfData = courseTopics.reduce<reduceObjProps>(
      (accObj, curObj) => {
        accObj.courseId = curObj.course_id

        if(curObj.course_topic_tasks) {
          accObj.numOfTasks += curObj.course_topic_tasks.length
        }

        curObj.course_topic_tasks?.forEach(task => {
          if(task.task_learner?.tutor_review === 'review complete') {
            accObj.numOfCompletedTasks += 1
          }
        })

        return accObj
    },{
    courseId: 0,
    numOfTasks: 0,
    numOfCompletedTasks: 0
  })

    if(mfData) setReduceObj(mfData)
  }, [])

  const handleClick = () => {
    setOpen(!open)
    searchParams.delete('CourseId')
    searchParams.delete('TopicId')
    setSearchParams(searchParams)
  }
  
  const fetchApiTopic = () => {
    switch (pathname) {
      case '/Courses':
        if(userRole() === 'Others') {
          console.log('call learner view topic data api')
          apiService.getLearnerViewCourseTopics(topicId).then((res) => {
            setApiTopic(res)
            setIsTopicLoading(false)
          }).catch(err => {
            if(err)
            setNotify({
              isOpen: true,
              message: 'Time out, please refresh the page',
              type: 'error',
            })
          })
        } 
        // else {
        //   apiService.getCourseTopic(topicId).then((res) => {
        //     setApiTopic(res)
        //     setIsTopicLoading(false)
        //   })
        // }
        break
      case '/Dashboard':
        if(userRole() === 'Admin User' || userRole() === 'Normal User'){
          if (sessionStorage.getItem('Tab') === 'Courses') {
            apiService.getCourseTopic(topicId).then((res) => {
              setApiTopic(res)
              setIsTopicLoading(false)
            })
          } else if (sessionStorage.getItem('Tab') === 'Progress') {
            learnerId &&
              apiService.getLearnerCourseTopics(learnerId, topicId).then((res) => {
                setApiTopic(res)
                setIsTopicLoading(false)
              })
          }
        }
        break        
      default:
        break
    } 
  } 

  const fetchApiTimesheet = () => {
    const userId: number = learnerId ? learnerId : userInfo.id;
    apiService.getStudentTimeSheet(userId).then((res) => {
      setApiLearnerTimesheet(res);
    });
  }

  useEffect(() => {
    if(open){
      setIsTopicLoading(true)
      fetchApiTimesheet()
      fetchApiTopic()
    }

    setTopicOpen(
      topicOpen.map((value: any) => {
        if (value.topic_id === topicId) {
          return {
            topic_id: topicId,
            topic_open: open
          }
        }
        return value
      })
    )
  },[open])

  useEffect(() => {
    const foundTopicOpen = topicOpen.find((value) => value.topic_open)
    if (!!foundTopicOpen) {
      setShowProgressBar(false)
    } else {
      setShowProgressBar(true)
    }
  }, [topicOpen])
  
  useEffect(() => {
    if(open) {
      if (apiTopic) {
        // console.log(apiTopic)
        setTasks(apiTopic[0].course_topic_tasks)
        setTopicResources(apiTopic[0].course_topic_resources)
      }
    }
  }, [open, apiTopic])

  useEffect(() => {
    setOpen(!collapseState.collapseAll)
  }, [collapseState])

  useEffect(() => {
    if(submitCourseTopicId.topicId !== 0 && submitCourseTopicId.topicId === topicId) {
      delayToDo(()=>{setOpen(true)}, 500)
    }
  }, [submitCourseTopicId])

  useEffect(() => {
    if(searchCourseTopicId.topicId !== 0 && searchCourseTopicId.topicId === topicId) {
      delayToDo(()=>{setOpen(true)}, 500)
    }
  }, [searchCourseTopicId])

  useEffect(() => {
    const sessionTopicId = sessionStorage.getItem('courseTopicIds')
    const scrollToPosition = sessionStorage.getItem('scrollPosition')
    if(!!sessionTopicId) {
      delayToDo(() => {
        JSON.parse(sessionTopicId).topicId === topicId && setOpen(true)
        sessionStorage.removeItem('courseTopicIds')
      }, 100)
    }

    if (!!scrollToPosition) 
      delayToDo(() => {
        window.scrollTo({
          top: Number(scrollToPosition),
          left: 0,
          behavior: 'smooth'
        })
        sessionStorage.removeItem('scrollPosition')
      }, 1000)
  },[])


  useEffect(() => {
    
    if(topicId == currentTopicId){
      setOpen(true)
    }
  },[currentTopicId])


  return (
    <>
      <CourseTopicContext.Provider value={value}>
        <MUI.List component='div' disablePadding className='c-topic-wrapper'>
          {/* Topic Item */}
          <div style={{ display: 'flex' }}>
            {/* {assignCourseState && courseCheckbox} */}
            <MUI.ListItem
              secondaryAction={
                <div className='c-page-header-button-wrapper'>
                  {apiTopic?.length && (
                    // (userRole() === 'Admin User' || userRole() === 'Normal User') &&
                    // open && <TopicMenu data={apiTopic[0]} />
                    <UserRoleAuth pathName={pathname} element='button'>
                      {open && sessionStorage.getItem('Selected-Tab') === 'Courses' && (
                        <TopicMenu data={apiTopic[0]} />
                      )}
                    </UserRoleAuth>
                  )}
                </div>
              }
              disablePadding={true}
            >
              <MUI.ListItemButton onClick={handleClick} className='c-list-topic-button'>
                {open ? <MUI.ExpandLess /> : <MUI.ExpandMore />}
                <MUI.ListItemText
                  primary={topicTitle}
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'break-spaces'
                    }
                  }}
                />
                {reduceObj.numOfCompletedTasks == reduceObj.numOfTasks ?  
                <span style={{color:'green'}}><CheckCircleIcon /></span> : ''}
              </MUI.ListItemButton>
            </MUI.ListItem>
          </div>

          {/* Topic Content Panel*/}
          <MUI.Collapse in={open} timeout='auto'>
            {isTopicLoading ? (
              <div className='c-topic-spinner'>
                {/* <Spinner /> */}
                <NewSpinner />
              </div>
            ) : (
              !!apiTopic?.length && (
                <MUI.Box sx={{ width: '100%' }}>
                  <MUI.Box
                    sx={matchesMD ? { display: 'flex', flexWrap: 'wrap' } : { display: 'flex' }}
                  >
                    <MUI.Paper
                      elevation={2}
                      sx={{
                        width: '100%',
                        maxHeight: '385px',
                        margin: '20px 5px 20px 0',
                        ...(matchesMD && { margin: '20px 0 20px 0' }),
                        overflowY: 'auto',
                        flexGrow: 1
                      }}
                    >
                      <div
                        className='topic-content'
                        style={{ margin: '20px 20px 20px 40px' }}
                        // style={assignCourseState ? {marginLeft: '80px'} : {marginLeft: '40px'}}
                      >
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {pathname === '/Dashboard' &&
                            apiTopic[0].course_topic_learner !== null && (
                              <p>{apiTopic[0].course_topic_learner.status}</p>
                            )}
                        </span>
                        <p>Lecture hours: {apiTopic[0].lecture_hours}</p>
                        <p>Suggested learning hours: {apiTopic[0].suggested_learning_hours}</p>
                        <p>Description: {apiTopic[0].description}</p>
                      </div>
                    </MUI.Paper>
                    {!!topicResources?.length && sessionStorage.getItem('Tab') !== 'Progress' && (
                      <UserRoleAuth pathName={pathname} element='display resources'>
                        {/* Resourses */}
                        <MUI.Paper
                          elevation={2}
                          sx={{
                            width: '100%',
                            maxHeight: '385px',
                            margin: '20px 0px 20px 5px',
                            ...(matchesMD && { margin: '20px 0 20px 0' }),
                            overflowY: 'auto',
                            flexGrow: 1
                          }}
                        >
                          <div style={{ margin: '10px 20px 10px 20px' }}>
                            <TopicResourcesList
                              topicResources={topicResources}
                              courseTopicIds={{
                                courseId: apiTopic[0].course_id,
                                topicId: topicId
                              }}
                            />
                          </div>
                        </MUI.Paper>
                      </UserRoleAuth>
                    )}
                  </MUI.Box>
                  {/*                   
                    {!!apiTopic?.length &&
                      !!apiTopic[0].course_topic_resource_videos.length && (
                        <CourseTopicVideo
                          data={apiTopic[0].course_topic_resource_videos}
                        />
                      )} 
                    */}

                  {/* Tasks List */}
                  {!!apiTopic?.length && !!tasks?.length ? (
                    // !!apiTopic[0].course_topic_tasks.length ? (
                    <CourseTopicTask data={tasks} learnerId={learnerId} 
                    apiLearnerTimesheet={apiLearnerTimesheet} 
                    setApiLearnerTimesheet={setApiLearnerTimesheet}/>
                  ) : (
                    // <CourseTopicTask data={apiTopic[0].course_topic_tasks} />
                    <CourseTopicTask />
                  )}
                </MUI.Box>
              )
            )}
          </MUI.Collapse>
        </MUI.List>
      </CourseTopicContext.Provider>
    </>
  )
}
export default CourseTopic
