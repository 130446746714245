import { IconButton, TableCell, TextField } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ListItemText from '@mui/material/ListItemText'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { ITagResponse } from '../../../interface'
import { useState } from 'react'
import { nanoid } from 'nanoid'
import apiService from '../../../services/api-services'

type AddConfigProps = {
  tags:ITagResponse[]
  setTags:Function
  setOnAddRow:Function
}

type FormData = {
  [key: string]: any;
}



const AddConfig = (props: AddConfigProps) => {
  const {tags,setTags, setOnAddRow} = props
  const [addFormData, setAddFormData] = useState<FormData>({
    title:''
  })

  const handleAddFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const fieldName = e.target.name
    const fieldValue = e.target.value
    const newFormData = { ...addFormData }
    newFormData[fieldName] = fieldValue
   

    setAddFormData(newFormData)
    console.log('fieldname', fieldName)
    console.log('newFormData', newFormData)
  }

  const handleAddFormSubmit = () => {
    console.log('onSubmit')
    // e.preventDefault()

  const addTag = {
    // id: nanoid(),
    title: addFormData.title
  }
  
    apiService.addTag(addTag).then((res) => {
      console.log('res', res)
      const newFormData = [res, ...tags]
      setTags(newFormData)
      setOnAddRow(false)
    }).catch(err => {console.log(err)})
  }

  return (
    <div>
      <ListItem>
        <form>
          <TextField
            id='standard-basic'
            // label='Enter a tag'
            variant='standard'
            name='title'
            placeholder='Enter a tag'
            onChange={handleAddFormChange}
            sx={{ paddingRight: '30px' }}
          />
          <input type='color' />
          <IconButton type='submit' onClick={(e) =>{e.preventDefault();handleAddFormSubmit()}}>
            <CheckOutlinedIcon color='primary' />
          </IconButton>
          <IconButton type='button' onClick={() => setOnAddRow()}>
            <ClearOutlinedIcon color='primary' />
          </IconButton>
        </form>
      </ListItem>
    </div>
  )
}
export default AddConfig
