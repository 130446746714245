import { BehaviorSubject } from "rxjs"

export type FlagPayload = { collapseAll: boolean }

export type FlagState = {
    collapseAll: boolean
}

const initialState = {
    collapseAll: true,
}

const collapseSubject = new BehaviorSubject<FlagPayload>(initialState)

export const collapseObservable = () => {
  const collapseAll = (flag: boolean) => {
    setNextState({ collapseAll: flag })
  }

  const setNextState = (payload: FlagPayload) => {
    const state = collapseSubject.getValue()
    collapseSubject.next({ ...state, ...payload })
  };

  const getObservable = () => {
    return collapseSubject
  }

  return {
    collapseAll,
    getObservable,
  }
}
