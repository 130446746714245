import { createContext } from 'react'
import { getUserRole } from '../constants/constants'
import { User } from '../interface'

export type AppContextProps = {
  userRole: () => 'Admin User' | 'Normal User' | 'Others'
  currentUser?: User | null
}

export const AppContext = createContext<AppContextProps>({
  userRole: () =>  {return getUserRole(3)},
  currentUser: null
})